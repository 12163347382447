import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import CTATextLink from "../../../components/dynamic/CTATextLink";
import Loader from "../../../components/global/Loader";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_PROPERTY_CALENDER } from "../../../features/api-wrapper/apiQueries";
import APIError from "../../ErrorHandling/APIError";
import OccuStyle from "./Occupancy.module.css";
import style from "./UpcomingEvents.module.css";
import moment from "moment";
import InnerHtml from "../../../components/dynamic/InnerHtml";
import DOMPurify from "dompurify";
import { Pagination } from "@mgmresorts/mgm-ui";
import { useSelector } from "react-redux";
import {  formatPaginationLabel } from "../../News/GivingVolunteering/VolunteerOpportunities";
import useMediaQuery from "../../../hooks/useMediaQuery";

const DEFAULT_PAGE_SIZE = 8;

const UpcomingEvents = (props) => {
  const { dictionary, links, dictionaryLables } = props.data;
  const { no_events_message, upcoming_events } = dictionaryLables || {};
  const titleHash =
    dictionary && dictionary[0].$ && dictionary[0].$.value["data-cslp"];
  const [filteredData, setFilteredData] = useState();
  const { loading, error, data } = useQuery(GET_PROPERTY_CALENDER, {
    client: apiMiddlewareClient,
  });

  const [visibleCards, setVisibleCards] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const isDesktop = useMediaQuery("screen and (min-width: 769px)"); //to update adhi

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const loadMoreCards = () => {
    setVisibleCards((prev) => prev + DEFAULT_PAGE_SIZE);
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    if (
      data &&
      data.filteredPropertyCalendar &&
      data.filteredPropertyCalendar.body &&
      data.filteredPropertyCalendar.body.length > 0
    ) {
      const myArray =
        data.filteredPropertyCalendar && data.filteredPropertyCalendar.body;
      const arrayLimit =
        myArray && myArray.length > 6
          ? myArray.length > 30
            ? myArray.slice(0, 30)
            : myArray
          : myArray;
      const upcomingDataArray = [];
      arrayLimit.filter((property) => {
        const eventData = JSON.stringify(property).split("|");
        const eveTime = eventData[3]?.indexOf("href")
          ? eventData[3]?.replace(/\\"/g, '"')
          : eventData[3];
        upcomingDataArray.push({
          eventName: eventData[2],
          eventMonth: eventData[0]?.replace(/"(<([^>]+)>)/gi, ""),
          eventLocation: eventData[1],
          eventTime: eveTime?.replace(/['"]+/g, ""),
        });
      });
      setFilteredData(upcomingDataArray);
    }
  }, [data]);

  const totalUpcomingData = filteredData && filteredData.length > 6;

  if (loading) return <Loader />;
  if (error)
    return (
      <div style={{ margin: "3rem 20px 20px 20px" }}>
        <APIError error={error} isRow={true} />
      </div>
    );

  return (
    <div className={style.features}>
      <div className="row">
        <div className={`col-12 ${OccuStyle.occupancyHeader}`}>
          <h3 className={style.upcomingHeader} data-cslp={titleHash}>
            {upcoming_events}
          </h3>
        </div>
      </div>
      <div className={`mt-3 ${totalUpcomingData && style.featuresContainer}`}>
        {filteredData && filteredData.length > 0 ? (
          <div className={`${style.upcomingEventsDetails}`}>
            {(isDesktop
              ? filteredData
              : filteredData.slice(0, visibleCards)
            ).map((data, index) => {
              return (
                <div key={index} className={style.deailsSection}>
                  <div className={style.eventContainer}>
                    {" "}
                    <div className={style.eventDay}>
                      {moment(data.eventMonth).format("MMM")}
                    </div>
                    <div className={style.eventDate}>
                      {" "}
                      {moment(data.eventMonth).format("DD")}
                    </div>
                  </div>
                  <div key={index} className={style.eventWrapper}>
                    <h4>{data.eventName}</h4>
                    <div>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data.eventMonth, {
                            ADD_ATTR: ["target"],
                          }),
                        }}
                      ></span>{" "}
                      &nbsp; | &nbsp;
                      <span
                        className="pl-1"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data.eventLocation, {
                            ADD_ATTR: ["target"],
                          }),
                        }}
                      ></span>{" "}
                      ,
                      <InnerHtml
                        description={data.eventTime}
                        style="pl-1 d-inline"
                      ></InnerHtml>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={style.noEvents}>{no_events_message}</div>
        )}
      </div>

      <>
        {!isDesktop &&
          filteredData?.length != 0 &&
          filteredData?.length > visibleCards && (
            <div
              className={`${style.paginationContainer} ${style.showMoreLabel}`}
            >
              <Pagination
                id="upcomingEvent_pagination"
                createPaginationLabel={(value, maxValue) => {
                  return formatPaginationLabel(globalProperties, value, maxValue);
                }}
                label={globalProperties?.load_more}
                onClick={() => {
                  loadMoreCards();
                }}
                mobile={!isDesktop}
                maxValue={filteredData?.length}
                value={visibleCards}
              />
            </div>
          )}
      </>

      {data && filteredData && filteredData.length > 0 && (
        <div className={style.wrapperMargin}>
          <CTATextLink
            size="small"
            style={OccuStyle.viewMore}
            iconRight="arrow-right"
            type="text_link"
            data={{
              label: links && links[0].title,
              url: data.filteredPropertyCalendar.leolink,
              openIn: "new_window",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
