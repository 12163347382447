import { Button } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import styles from "./CarouselArrows.module.css";

const CarouselArrows = (props) => {
  const { title, onClick, totalRecords, itemsPerSlide = 1 } = props;
  const [indexValue, setIndexValue] = useState(0);
  
  useEffect(() => {
    if (totalRecords) {
      setIndexValue(0);
    }
  }, [totalRecords]);

  return (
    <div className={styles.carouselContainer}>
      <Button
        icon="chevron-left"
        size="small"
        variant="icon-outlined"
        disabled={indexValue == 0}
        title={title?.prev}
        onClick={(e) => {
          e.preventDefault();
          onClick(e, "prev");
          setIndexValue(indexValue - itemsPerSlide);
        }}
      />
      <Button
        icon="chevron-right"
        size="small"
        variant="icon-outlined"
        // disabled={indexValue == totalRecords.length - parseInt(itemsPerSlide)}
        disabled={totalRecords.length <= indexValue + parseInt(itemsPerSlide)}
        title={title?.next}
        onClick={(e) => {
          e.preventDefault();
          onClick(e, "next");
          setIndexValue(indexValue + itemsPerSlide);
        }}
      />
    </div>
  );
};

export default CarouselArrows;
