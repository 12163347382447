import style from "./GlobalSearchBox.module.css";
import { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { searchGlobalResultActions } from "../../data/search-global-result-slice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { useLocation } from "react-router";

const GlobalSearchBox = (props) => {
  const placeholder = props.placeholder;
  const globalProprties = props.globalProperties;
  console.log('globalProprties:::',props);
  console.log('globalProprties:::',globalProprties);
  const [assignValue, setAssignValue] = useState();
  const [trendingSearchData, setTrendingSearchData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showClearBtn, setShowClearBtn] = useState(false);

  const [hideSuggestion, setHideSuggestion] = useState(true); //?

  const gqlData = useSelector((state) => state.gqlData);
  const employeeId = gqlData.profile ? gqlData.profile.employeeId : "";

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  // const { pathname } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const delay = 300;
  let timer;
  let searchAheadData;

  let token = sessionStorage.getItem("token");
  let apigee_token = sessionStorage.getItem("apigee_token");
  let lang = language === undefined || language === "EN" ? "en-us" : language;

  const headers = {
    Authorization: `Bearer ${apigee_token}`,
    jwt: token,
    //Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  };

  const onChangeMethod = (e) => {
    if (e.target.value == "") {
      setShowClearBtn(false); //assign should not be blank - to do ?
    }

    setAssignValue(e.target.value);
    setShowClearBtn(true);

    if (e.target.value.length >= 3) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getSearchAheadData(e);
      }, delay);
      setHideSuggestion(false);
    } else {
      setSuggestions([]);
    }
  };

  const getSearchAheadData = async (e) => {
    const baseURL = process.env.REACT_APP_BASE_URL;

    await axios
      .get(
        `${baseURL}api/lw-search-ahead?q=${encodeURIComponent(
          e.target.value
        )}&employeeId=${employeeId}&locale=${lang}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // debugger; // eslint-disable-line no-debugger
        try {
          let suggestName =
            lang === "en-us" ? "mgmSuggestor" : "mgmSuggestor_es";
          searchAheadData =
            response.data.suggest[suggestName][`${e.target.value}`]
              ?.suggestions;
          if (searchAheadData && searchAheadData.length > 0) {
            let result = [];
            searchAheadData.forEach((ele) => {
              let filterStr = ele.term;
              let dropdata = {
                value: filterStr,
              };
              result.push(dropdata);
            });
            setSuggestions(result);
          }
        } catch (e) {
          console.log(e);
        }

        // setError(null);
      })
      .catch((error) => {
        console.log(error);
        // setError({
        //   title: error.response?.data ? error.response?.data : error.message,
        //   message: error.message ? error.message : error.response?.data,
        //   status: error.response?.status ? error.response?.status : null,
        // });
      });
  };

  const handleClick = (e,sVal) => {
    dispatch(searchGlobalResultActions.setsearchClicked(false));
    console.log('sVal>>>>',sVal);
    //I dont think filter value is required here
    // let filterStr = e.currentTarget.innerHTML.replace(/(<([^>]+)>)/gi, "");
    // filterStr = filterStr.replace("&amp;", "&");
    navigate("/search", {
      //state: { trendingDataSelected: e.currentTarget.innerHTML },
      state: { trendingDataSelected: sVal },
    });

    //clear Btn
    setShowClearBtn(true); //assign should not be blank - to do ?

    // setSuggestions([]);
    // setIsSuggestionSelected(true);
  };

  //break into one more component
  // 1. simple input box and
  // 2. utilisizing the above input box.. functional
  // now plug and play
  useEffect(() => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const headers = {
      Authorization: `Bearer ${apigee_token}`,
      jwt: token,
      //Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
    };

    axios
      .get(`${baseURL}api/lw-search-trend?locale=${lang}`, {
        headers: headers,
      })
      .then((response) => {
        setTrendingSearchData(
          response?.data?.grouped?.query?.groups?.map((d) => d.groupValue)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const makeSelfClickCheck = (e) => {
    console.log(e.target.id);
    console.log(e.target);
    if (
      e.target.id &&
      (e.target.id == "mainHolder" || e.target.id == "wrapper")
    ) {
      dispatch(searchGlobalResultActions.setsearchClicked(false));
    }
  };

  return (
    <div className={style.bgOverlay}>
      <div
        className={style.helperBG}
        onClick={() => {
          dispatch(searchGlobalResultActions.setsearchClicked(false));
        }}
      ></div>
      <div
        id="mainHolder"
        className={style.mainHolder}
        onClick={makeSelfClickCheck}
      >
        <div id="wrapper" className={style.wrapper}>
          <div className={style.inputContainer}>
            <div className={style.inputInnerContainer}>
              <div className={style.inputOuter}>
                <input
                  type="text"
                  autoFocus
                  className={style.input}
                  value={assignValue}
                  placeholder={placeholder}
                  onChange={(e) => {
                    onChangeMethod(e);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // setSuggestions([]);
                      // doSearch();  -> navigate
                      navigate("/search", {
                        state: { trendingDataSelected: e.target.value }, //to do: trendingDataSelected rename to data
                      });
                      dispatch(
                        searchGlobalResultActions.setsearchClicked(false)
                      );
                    }
                  }}
                />
                <div
                  className={style.searchIconInside}
                  onClick={() => {
                    if (assignValue) {
                      navigate("/search", {
                        state: { trendingDataSelected: assignValue }, //to do: trendingDataSelected rename to data
                      });
                      dispatch(
                        searchGlobalResultActions.setsearchClicked(false)
                      );
                    }
                  }}
                ></div>
              </div>
              {/* {showClearBtn} */}
              {showClearBtn && (
                <div
                  className={style.clearIcon}
                  onClick={() => {
                    //reset all
                    // setfacetcountForMob(null);
                    setAssignValue("");
                    // setSearchClick(false);
                    // setshowfilter(false);
                    // setResultdata([]);
                    // setTotalNum(0);
                    // settopresult(null);
                    setSuggestions([]);

                    setShowClearBtn(false);
                    // setShowContentLoader(false);
                    // setPage(1);
                    // settotalPage(0);
                    // setShowLoadMore(false);
                    // setfilterData([]);
                  }}
                ></div>
              )}
            </div>
          </div>

          {/* Search Popup Box  */}
          { (<div className={style.searchPopUpBox}>
            {console.log(hideSuggestion)}
            {/* {suggestions.length > 0 && !hideSuggestion ? (
                <div className={styles.suggestionH}> */}

            {suggestions.length > 0 && language != 'tl' && language != 'zh-chs' && (
              <div className={style.suggestHeaderBox}>
                <ul>
                  {suggestions.map((obj, index) => {
                    return (
                      <li key={index} onClick={(e) => handleClick(e,obj.value)}>
                        {obj.value}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {<div className={style.trendingSearchContainer}>
              <h3
                style={{
                  fontSize: "20px",
                  marginBottom: "10px",
                }}
              >
                {globalProprties.trending_searches}
              </h3>

              <div className={style.itemHolder}>
                {trendingSearchData &&
                  trendingSearchData.map((d, i) => {
                    return (
                      <div
                        key={`trending_${i}`}
                        className={style.iconmGlass}
                        name={d}
                        onClick={() => {
                          dispatch(
                            searchGlobalResultActions.setsearchClicked(false)
                          );

                          navigate("/search", {
                            state: { trendingDataSelected: d },
                          });

                          // getSearchResultData("search", e.target.innerHTML);
                          // setSearchClick(true);
                        }}
                      >
                        {d}
                      </div>
                    );
                  })}
              </div>
            </div>}
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default GlobalSearchBox;
