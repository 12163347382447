import styles from "./LocationSpecificTimesheet.module.css";

import { useLazyQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_TIMESHEET_DETAILS } from "../../../features/api-wrapper/apiQueries";

import TimesheetDayRow from "./TimesheetDayRow";

import approvedEditIcon from "../../../assets/images/green-circle.svg";
import pendingEditIcon from "../../../assets/images/red-triangle.svg";
import transferredIcon from "../../../assets/images/t-transfer-icon.svg";
import useWindowDimensions from "../../../hooks/useScreenDetails";
import Loader from "../../../components/global/Loader";
import APIError from "../../ErrorHandling/APIError";
import { useEffect, useState } from "react";
import moment from "moment";

export const secondstoHourConvertor = (totalSec) => {
  const hours = totalSec / 3600;
  return hours.toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: 2,
  });
};

export const getRange = (startDate, endDate, type = "days") => {
  let fromDate = moment(new Date(startDate));
  let toDate = moment(new Date(endDate));
  let diff = toDate.diff(fromDate, type);
  let range = [];
  for (let i = 0; i <= diff; i++) {
    const timesheetObj = {
      timeSheetRow: [],
      timesheetDate: moment(startDate).add(i, type).format("YYYY-MM-DD"),
      hours: "",
      isPayCode: false,
      payCodeName: "",
      isNoTimeReported: true,
    };
    range.push(timesheetObj);
  }
  return range;
};

const LocationSpecificTimesheet = (props) => {
  const [totalHours, setTotalHours] = useState(0);
  const [isMobile] = useWindowDimensions();
  const [previousData, setPreviousData] = useState({
    payPeriodStartDate: "",
    payPeriodEndDate: "",
    currLocCode: "",
  });
  const [timesheetData, setTimeSheetData] = useState();
  const [getTimesheetData, { error, loading, data }] = useLazyQuery(
    GET_TIMESHEET_DETAILS,
    {
      fetchPolicy: "no-cache",
      client: apiMiddlewareClient,
    }
  );

  useEffect(() => {
    if (
      (props.payPeriodStartDate !== previousData.payPeriodStartDate &&
        props.payPeriodEndDate !== previousData.payPeriodEndDate) ||
      props.currLocCode !== previousData.currLocCode
    ) {
      getTimesheetData({
        variables: {
          startDate: props.payPeriodStartDate,
          endDate: props.payPeriodEndDate,
          locationId: props.currLocCode,
        },
      });
      setPreviousData({
        payPeriodStartDate: props.payPeriodStartDate,
        payPeriodEndDate: props.payPeriodEndDate,
        currLocCode: props.currLocCode,
      });
    }
  }, [props]);

  useEffect(() => {
    let hours = 0;
    setTotalHours(hours);
    if (data && data.timeSheet.timesheetDateWise) {
      const isFutureDate = moment(data.timeSheet.payPeriodEndDate) > moment();
      const endDate = isFutureDate
        ? moment.utc().format("YYYY-MM-DDT00:00:00").toString()
        : data.timeSheet.payPeriodEndDate;

      // create dates based on payperiod start-end date
      const dates = getRange(data.timeSheet.payPeriodStartDate, endDate);
      if (data.timeSheet.timesheetDateWise.length > 0) {
        // normalise timesheet data
        const formatDateTimeWise = data.timeSheet.timesheetDateWise.map(
          (timesheetD) => {
            if (timesheetD.hours) {
              hours =
                hours + parseFloat(secondstoHourConvertor(timesheetD.hours));
              setTotalHours(hours);
            }
            return {
              ...timesheetD,
              hours: secondstoHourConvertor(timesheetD.hours),
              isPayCode: false,
              payCodeName: "",
              isNoTimeReported: false,
            };
          }
        );
        // delete date in dates array if date exist in timesheet
        formatDateTimeWise.forEach((element) => {
          dates.forEach((el, idx) => {
            if (element.timesheetDate === el.timesheetDate) {
              delete dates[idx];
            }
          });
        });
        const dateWiseConcat = formatDateTimeWise.concat(dates);
        const sortedSingleData = dateWiseConcat.sort(function compare(a, b) {
          return new Date(a.timesheetDate) - new Date(b.timesheetDate);
        });

        // if paycodes exist
        if (data.timeSheet.payCodes.length > 0) {
          const formatPaycodes = data.timeSheet.payCodes.map((item) => {
            return {
              timeSheetRow: [],
              timesheetDate: moment(item.payCodeDateTime).format("YYYY-MM-DD"),
              hours: secondstoHourConvertor(item.payCodeAmountInSeconds),
              isPayCode: true,
              payCodeName: item.payCodeName,
              isNoTimeReported: false,
            };
          });
          const paycodeConcatedData = sortedSingleData.concat(formatPaycodes);
          const clubArray = (arr) => {
            return arr.reduce((acc, val) => {
              const index = acc.findIndex(
                (el) => el.timesheetDate === val.timesheetDate
              );
              if (index !== -1) {
                const key = Object.keys(val)[1];
                acc[index][key] = val[key];
                acc[index]["isPayCode"] = true;
                acc[index]["payCodeName"] =
                  val["payCodeName"] == "PAID TIME OFF"
                    ? "PTO"
                    : val["payCodeName"];
                // acc[index]["hours"] = val["hours"];
              } else {
                acc.push(val);
              }
              return acc;
            }, []);
          };

          const combinedData = clubArray(paycodeConcatedData).sort(
            function compare(a, b) {
              return new Date(a.timesheetDate) - new Date(b.timesheetDate);
            }
          );
          setTimeSheetData(combinedData);
          return;
        }
        setTimeSheetData(sortedSingleData);
      } else {
        const sortedData = dates.sort(function compare(a, b) {
          return new Date(a.timesheetDate) - new Date(b.timesheetDate);
        });
        setTimeSheetData(sortedData);
      }
    }
  }, [data]);

  const icons = {
    transferredIcon: transferredIcon,
    approvedEditIcon: approvedEditIcon,
    pendingEditIcon: pendingEditIcon,
  };

  const totalHoursText = props?.staticData?.dictionaryLables?.total_hours;
  const dateText = props?.staticData?.dictionaryLables?.date;
  const inText = props?.staticData?.dictionaryLables?.in;
  const outText = props?.staticData?.dictionaryLables?.out;
  const tipsText = props?.staticData?.dictionaryLables?.amount;
  const hoursText = props?.staticData?.dictionaryLables?.hours;
  // const transferredText = props?.staticData?.dictionaryLables?.transferred;
  // const pendingEditText = props?.staticData?.dictionaryLables?.pending_edit;
  // const approvedEditText = props?.staticData?.dictionaryLables?.approved_edit;
  // const dictionary = props?.staticData?.dictionary;
  // dictionary.map((obj) => {
  //   if (obj.name == "no_time_reported") {
  //     noTimeReported = obj.value;
  //   }
  // });
  // noTimeReported = noTimeReported ? noTimeReported : "No Time Reported";

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.totalHoursContainer}>
        <span className={styles.totalHoursNum}>{totalHours.toFixed(2)}</span>{" "}
        <span className={styles.totalHoursText}>{totalHoursText}</span>{" "}
      </div>
      <div className={styles.timesheetDetailsContainer}>
        {timesheetData &&
          timesheetData.length > 0 &&
          timesheetData.map((datawise, i) => {
            return (
              <TimesheetDayRow
                key={i}
                weekPay={datawise}
                payPeriodStartDate={data.timeSheet.payPeriodStartDate}
                payperiodEndDate={data.timeSheet.payPeriodEndDate}
                icons={icons}
                isApproved={data.timeSheet.isApproved}
                isPayCode={datawise.isPayCode}
                payCodeName={datawise.payCodeName}
                isNoTimeReported={datawise.isNoTimeReported}
                isDateApproved={datawise.isDateApproved}
                hours={datawise.hours}
                staticData={props?.staticData}
              />
            );
          })}
        <div className={styles.timesheetDetailsHeader}>
          <span className={isMobile ? styles.hideDate : styles.date}>
            {dateText}
          </span>
          <span className={isMobile ? styles.hideInOut : styles.in}>
            {inText}
          </span>
          <span className={isMobile ? styles.hideInOut : styles.out}>
            {outText}
          </span>
          <span className={styles.tips}>{tipsText}</span>
          {isMobile && <span className={styles.transfer}>transfer</span>}
          <span className={styles.hours}>{hoursText}</span>
        </div>
      </div>

      <div
        className={isMobile ? styles.iconsContainerM : styles.iconsContainer}
      >
        {/* <span>
          <img src={transferredIcon}></img> {transferredText}
        </span> */}
        {/* <span>
          <img src={pendingEditIcon}></img> {pendingEditText}
        </span>
        <span>
          <img src={approvedEditIcon}></img> {approvedEditText}
        </span> */}
      </div>
    </div>
  );
};

export default LocationSpecificTimesheet;
