import { useEffect, useRef, useState } from "react";

import styles from "./customSelectDropdown.module.css";
import { Icon } from "@mgmresorts/mgm-ui";

const CustomSelectDropDown = ({
  id,
  optionsList,
  onChange,
  selected,
  isType2,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const dropdownWrapperRef = useRef(null);
  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleClickOutside = (e) => {
    if (!dropdownWrapperRef.current.contains(e.target)) {
      setIsOptionsOpen(false);
    }
  };

  useEffect(() => {
    if (selected != null && selected !== "") {
      optionsList.map((item, index) => {
        if (item.indexOf(selected) !== -1) {
          setSelectedOption(index);
        }
      });
    }
  }, [selected]);

  useEffect(() => {
    if (isOptionsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOptionsOpen]);

  const selectedValue = (event, index) => {
    event.preventDefault();
    setSelectedOption(index);
    onChange(index, optionsList[index]);
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleListKeyDown = (e) => {
    switch (e.key) {
      case "Escape":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        onChange(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1,
          optionsList[
            selectedOption - 1 >= 0
              ? selectedOption - 1
              : optionsList.length - 1
          ]
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedOption(
          selectedOption === optionsList.length - 1 ? 0 : selectedOption + 1
        );
        onChange(
          selectedOption === optionsList.length - 1 ? 0 : selectedOption + 1,
          optionsList[
            selectedOption === optionsList.length - 1 ? 0 : selectedOption + 1
          ]
        );
        break;
      case "Tab":
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={
        isType2 ? styles.customDropdownType2 : styles.customDropdownType1
      }
      id={id}
      ref={dropdownWrapperRef}
    >
      {
        <div className={styles.container}>
          <button
            type="button"
            onClick={toggleOptions}
            className={styles.dropDownButton}
            aria-haspopup="listbox"
            aria-expanded={isOptionsOpen}
            tabIndex={0}
            onKeyDown={handleListKeyDown}
          >
            <div className={styles.textWrap}>{optionsList[selectedOption]}</div>
            <div className={styles.arrow}>
              <Icon
                name={"chevron-up"}
                size="small"
                className={
                  isOptionsOpen ? styles.arrowDown : styles.arrowDownActive
                }
              />
            </div>
          </button>
          {isOptionsOpen && (
            <ul
              role="listbox"
              className={styles.customList}
              aria-activedescendant={optionsList[selectedOption].option}
              tabIndex={-1}
            >
              {optionsList.map((data, index) => {
                return (
                  <li
                    className={styles.optionList}
                    key={index}
                    role="option"
                    tabIndex={0}
                    aria-selected={selectedOption === index}
                    onClick={(eve) => {
                      selectedValue(eve, index);
                    }}
                  >
                    {data}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      }
    </div>
  );
};

export default CustomSelectDropDown;
