import { useQuery } from "@apollo/client";
import { CenterModal } from "@mgmresorts/mgm-ui";
import moment from "moment";
import { useEffect, useState } from "react";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_BENEFITS } from "../../features/api-wrapper/apiQueries";
import ViewCurrentBenefitsDialogue from "./ViewCurrentBenefitsDialogue";
import APIError from "../../pages/ErrorHandling/APIError";
import styles from "./ViewCurrentBenefitsDialogue.module.css";

const ViewCurrentBenefitsModal = (props) => {
  const [dictionaryLabel, setDictionaryLabel] = useState();
  const [dictionaryLink, setDictionaryLink] = useState();

  const {
    error,
    loading,
    data: benefits,
  } = useQuery(GET_BENEFITS, {
    variables: { effectiveDate: moment(new Date()).format("YYYY-MM-DD") },
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
  });

  useEffect(() => {
    if (props.csData && props.csData[0]?.dictionary) {
      let dictionaryLables = {};
      const dictionary = props.csData[0].dictionary;
      if (Array.isArray(dictionary)) {
        for (let i = 0; i < dictionary.length; i++) {
          dictionaryLables[dictionary[i].name] =
            dictionary[i].value || dictionary[i].html_value;
        }
      }
      setDictionaryLabel(dictionaryLables);
    }

    if (props.csData && props.csData[0]?.links_dictionary) {
      let dictionaryLink = {};
      const data = props.csData[0].links_dictionary;
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          dictionaryLink[data[i].name] = data[i].link;
        }
      }
      setDictionaryLink(dictionaryLink);
    }
  }, [props]);

  return (
    <div className={styles.viewCurrentBenefitsContainer}>
      <CenterModal
        open={true}
        visibleScrollBar={false}
        title={
          benefits?.getBenefits?.upcomingBenefits &&
          benefits?.getBenefits?.upcomingBenefits.length > 0
            ? dictionaryLabel?.current_benefits_heading
            : dictionaryLabel?.not_enrolled_heading_hourly
        }
        size="large"
        overflow={false}
        onClose={() => {
          props.onClose();
          document.body.style.overflow = "visible";
        }}
      >
        {error ? (
          <>
            <APIError error={error} />
          </>
        ) : (
          !loading &&
          benefits && (
            <ViewCurrentBenefitsDialogue
              dictionaryLabel={dictionaryLabel}
              dictionaryLink={dictionaryLink}
              csData={props.csData}
              benefits={benefits}
              isOpenEnrollmentStarted={
                benefits?.getBenefits?.isOpenEnrollmentStarted
              }
              IsEnrollmentPlanSubmitted={
                benefits?.getBenefits?.IsEnrollmentPlanSubmitted
              }
              isOpenEnrollmentCompleted={
                benefits?.getBenefits?.isOpenEnrollmentCompleted
              }
              error={error}
              loading={loading}
              onClose={() => {
                props.onClose();
                document.body.style.overflow = "visible";
              }}
              {...benefits?.getBenefits}
            >
              {props.children}
            </ViewCurrentBenefitsDialogue>
          )
        )}
      </CenterModal>
    </div>
  );
};

export default ViewCurrentBenefitsModal;
