import { useLazyQuery } from "@apollo/client";
import { Pagination } from "@mgmresorts/mgm-ui";
import moment from "moment";
import "moment/locale/es";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../components/global/Loader";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_VOLUNTEERING_NEWS_EVENTS } from "../../../features/api-wrapper/apiQueries";
import ChooseCta from "../../../hooks/ChooseCta";
import APIError from "../../ErrorHandling/APIError";
import VolunteerCard from "./VolunteerCard";
import styles from "./VolunteerOpportunities.module.css";
import useMediaQuery from "../../../hooks/useMediaQuery";

const DEFAULT_PAGE_SIZE = 8;
export const EMPTY_STRING = "";

export const formatPaginationLabel = (
  globalProperties,
  noOfPage,
  totalRecords
) => {
  if (globalProperties && globalProperties?.pagination_label) {
    return globalProperties?.pagination_label
      .replace('<span translate="no">{variable-noofpage}</span>', noOfPage)
      .replace(
        '<span translate="no">{variable-totalnoofrecords}</span>',
        totalRecords
      );
  }
};

const VolunteerOpportunities = (props) => {
  const profile = useSelector((state) => state.gqlData?.profile);
  const { dictionaryLables, links_dictionary, dictionaryWithIconLables } =
    props.data;
  const { link, trailing_icon, open_in } = links_dictionary[0];
  const [visibleCards, setVisibleCards] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [volunteerData, setVolunteerData] = useState([]);
  const [latLongValue, setLatLongValue] = useState({
    latitude: null,
    longitude: null,
  });
  const isDesktop = useMediaQuery("screen and (min-width: 768px)");
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const [getVolunteerOpportunites, { loading, error, data }] = useLazyQuery(
    GET_VOLUNTEERING_NEWS_EVENTS,
    {
      fetchPolicy: "no-cache",
      client: apiMiddlewareClient,
    }
  );

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  const loadMoreCards = () => {
    setVisibleCards((prev) => {
      const count =
        parseInt(prev) + DEFAULT_PAGE_SIZE >
        data?.getVolunteeringNewEvents?.totalCount
          ? data?.getVolunteeringNewEvents?.totalCount
          : parseInt(prev) + DEFAULT_PAGE_SIZE;
      return count;
    });
    setPageNumber(pageNumber + 1);
  };

  if (language) {
    moment.locale(language);
  }

  useEffect(() => {
    if (
      profile &&
      pageNumber &&
      latLongValue.latitude &&
      latLongValue.longitude
    ) {
      getVolunteerOpportunites({
        variables: {
          volnteeringEventInput: {
            latitude: latLongValue.latitude,
            locationCityState: profile?.userLocation,
            longitude: latLongValue.longitude,
            page: pageNumber,
            pageSize: DEFAULT_PAGE_SIZE,
          },
        },
      });
    }
  }, [pageNumber, profile, latLongValue]);

  useEffect(() => {
    if (profile && profile.userLocation && window.google) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        { address: profile?.userLocation },
        function (results, status) {
          if (status == window.google.maps.GeocoderStatus.OK) {
            setLatLongValue({
              latitude: results[0].geometry.location.lat(),
              longitude: results[0].geometry.location.lng(),
            });
          } else {
            setLatLongValue({ latitude: 1, longitude: 1 });
            console.log(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    }
  }, [profile?.userLocation]);

  useEffect(() => {
    if (
      data &&
      data.getVolunteeringNewEvents &&
      data?.getVolunteeringNewEvents.events &&
      data?.getVolunteeringNewEvents.events.length > 0
    ) {
      const updatedData = volunteerData.concat(
        data?.getVolunteeringNewEvents.events
      );
      setVolunteerData(updatedData);
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="api-error">
        <APIError error={error} />
      </div>
    );

  // hide the component when length of new opportunities array is zero
  if (
    data &&
    data.getVolunteeringNewEvents &&
    data.getVolunteeringNewEvents.events &&
    data.getVolunteeringNewEvents.events.length == 0
  ) {
    return EMPTY_STRING;
  }
  
  return (
    <Fragment>
      <div className={styles.volunteerWrapper}>
        {volunteerData && volunteerData.length > 0 && <><div className={styles.toptextholder}>
          <div className={styles.subTextHolder}>
            <div className={styles.offerTitle}>{dictionaryLables?.Title}</div>
            {link && !isDesktop && (
              <div className={styles.volunteerLink}>
                <ChooseCta
                  data={{
                    label: link.title,
                    url: link.href,
                    openIn: open_in,
                    trailingIcon: trailing_icon?.icon,
                  }}
                  size="small"
                  type="text_link"
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.toptextholder}>
          <div className={styles.subTextHolder}>
            {data?.getVolunteeringNewEvents?.employeeLocation && (
              <div className={styles.locationNotes}>
                <div className={styles.pinLoc}>
                  <img
                    src={dictionaryWithIconLables?.pin_location_icon.url}
                    alt="location"
                  />
                </div>
                <div>
                  <span className={styles.locState}>
                    {profile?.userLocation}
                  </span>
                </div>
              </div>
            )}
            {link && isDesktop && (
              <div className={styles.volunteerLink}>
                <ChooseCta
                  data={{
                    label: link.title,
                    url: link.href,
                    openIn: open_in,
                    trailingIcon: trailing_icon?.icon,
                  }}
                  size="small"
                  type="text_link"
                />
              </div>
            )}
          </div>
        </div></>}

        <div className={styles.volunteerOpportunitiesContainer}>
          {volunteerData &&
            volunteerData.length > 0 &&
            volunteerData.map((opportunity, index) => {
              return (
                <VolunteerCard
                  {...opportunity}
                  key={index}
                  buttonText={dictionaryLables?.button_title}
                  iconList={dictionaryWithIconLables}
                  isSingleCard={false}
                />
              );
            })}
        </div>
        {data?.getVolunteeringNewEvents?.events?.length != 0 &&
          data?.getVolunteeringNewEvents?.totalCount > DEFAULT_PAGE_SIZE &&
          visibleCards && (
            <div className={styles.showMoreLabel}>
              <Pagination
                id="volunteer_pagination"
                createPaginationLabel={(value, maxValue) => {
                  return formatPaginationLabel(
                    globalProperties,
                    value,
                    maxValue
                  );
                }}
                label={globalProperties?.load_more}
                onClick={() => {
                  loadMoreCards();
                }}
                mobile={!isDesktop}
                maxValue={data?.getVolunteeringNewEvents?.totalCount}
                value={visibleCards}
              />
            </div>
          )}
      </div>
    </Fragment>
  );
};

export default VolunteerOpportunities;
