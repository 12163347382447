const checkPermission = () => {
  if (!("serviceWorker" in navigator)) {
    throw new Error("No support for service worker!");
  }

  if (!("Notification" in window)) {
    throw new Error("No support for notification API");
  }

  if (!("PushManager" in window)) {
    throw new Error("No support for Push API");
  }
};

// const urlBase64ToUint8Array = (base64String) => {
//   const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
//   const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

//   const rawData = atob(base64);
//   const outputArray = new Uint8Array(rawData.length);

//   for (let i = 0; i < rawData.length; ++i) {
//     outputArray[i] = rawData.charCodeAt(i);
//   }

//   return outputArray;
// };

const registerSW = async () => {
  console.log("service worker registration starts");
  const swUrl = `${process.env.PUBLIC_URL}/custom-service-worker.js`;
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.log("service worker - regitration status ", registration);
      // if (registration.active) {
      //   console.log(
      //     "service worker registered with scope:",
      //     registration.scope
      //   );
      //   const publicVapidKey =
      //     "BJg4j6faE6SnuCJYG-1090VUvY6fBKrWrIgRPA2us_PIiNNBFrHLW09T_OE7tISowjoMEHT8_TYi85bFTt7qTHY";
      //   registration.pushManager
      //     .subscribe({
      //       userVisibleOnly: true,
      //       applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      //     })
      //     .then(async (subscription) => {
      //       console.log(
      //         "service worker - push subscription done",
      //         subscription
      //       );
      //       console.log("subscription ", subscription);
      //       const response = await fetch(
      //         `${process.env.REACT_APP_BASE_URL}api/save-subscription`,
      //         {
      //           method: "post",
      //           headers: {
      //             "Content-type": "application/json",
      //             JWT: sessionStorage.getItem("token"),
      //             Authorization: `Bearer ${sessionStorage.getItem(
      //               "apigee_token"
      //             )}`,
      //           },
      //           body: JSON.stringify(subscription),
      //         }
      //       );
      //       console.log("response", response ? response.json() : "");
      //     })
      //     .catch((err) => {
      //       console.log("error in subscribing to push", err);
      //     });
      // }
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
};

const requestNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  if (permission !== "granted") {
    console.log("Notification permission not granted");
  }
  console.log("service worker -  got permission for notfication", permission);
  // console.log(" window.registration", window.registration);
};

const serviceWorkerComp = async () => {
  // if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  console.log("service worker worker initialization starts");

  if ("serviceWorker" in navigator) {
    console.log("service worker is available in browser");
    checkPermission();
    console.log(
      "service worker - notification status ",
      Notification.permission
    );
    if (Notification.permission == "default") {
      console.log("service worker - getting permission for notifcation");
      await requestNotificationPermission();
      await registerSW();
    }
  }

  // if ("serviceWorker" in navigator) {
  //   window.addEventListener("load", async () => {
  //     checkPermission();
  //     if (Notification.permission == "default") {
  //       setTimeout(async () => {
  //         if (window.confirm("Turn on notifications?")) {
  //           await requestNotificationPermission();
  //           await registerSW();
  //         }
  //       },3000);
  //     }
  //   });
  // }
};

export default serviceWorkerComp;

const channel = new BroadcastChannel("sw-token");

//listen to messages
channel.onmessage = async (event) => {
  if (event) {
    console.log("broadcase channel received", event);
    const subscription = event.data.subscription;
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}api/save-subscription`,
      {
        method: "post",
        headers: {
          "Content-type": "application/json",
          JWT: sessionStorage.getItem("token"),
          Authorization: `Bearer ${sessionStorage.getItem("apigee_token")}`,
        },
        body: JSON.stringify(subscription),
      }
    );

    console.log("response", response ? response.json() : "");
  }

};

