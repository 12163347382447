import CTATextLink from "../../components/dynamic/CTATextLink";
import { convertDateFormat } from "../../utils/convertDateFormat";
import styles from "./LeaveOfAbsenceFMLA.module.css";

const LeaveOfAbsenceFMLA = (props) => {
    let dictionaryLables = {};
    let linkDictionaryLables = {};
    let data = {};
    if (props?.data?.data) {
        dictionaryLables = props.data.data.dictionaryLables || {};
        linkDictionaryLables = props.data.data.linkDictionaryLables || {};
    }
    if (props?.apiData) {
        data = props.apiData.data;
    }
    const {
        fmla_title: FMLATitle = '',
        as_of: FMLAasOfText = '',
        bottom_left_text: FMLABottomLeftText = '',
        total_hours: FMLATotalHoursText = '',
      } = dictionaryLables;
    const { view_request } = linkDictionaryLables;

    return (
        <>
            <div className={styles.fmlaCardContainer}>
                <div className={styles.FMLAMain}>
                    <div className={styles.FMLALeftText}>
                        <div className={styles.FMLATitle}>{FMLATitle}</div>
                        <div className={styles.FMLADate}>{FMLAasOfText} {convertDateFormat(data?.asOfDate)}</div>
                    </div>
                    <div className={styles.FMLARightText}>
                        <div className={styles.FMLAHours}>{data?.balance}</div>
                        <div className={styles.FMLAHoursText}>{FMLATotalHoursText}</div>
                    </div>
                </div>
                <div className={styles.FMLABorder}></div>
                <div className={styles.FMLAMain}>
                    <div className={styles.FMLAHoursText}>{FMLABottomLeftText}
                    </div>
                    <CTATextLink
                        size="small"
                        type="text_link"
                        data={{
                            label: view_request?.link?.title,
                            url: view_request?.link?.href,
                            openIn: view_request?.open_in,
                        }}
                    />
                </div>
            </div>

        </>
    );
};

export default LeaveOfAbsenceFMLA;
