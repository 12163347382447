const windowOpen = (url) => {
  
  if (navigator.userAgent.indexOf("Mac OS X") != -1) {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    
  } else {
    window.open(url, "_blank");
  }
};

export { windowOpen };
