import "./App.css";
import "./utils/live-preview.js";
import "@contentstack/live-preview-utils/dist/main.css";

import { useEffect, useState, useRef } from "react";
import { BrowserRouter, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { DesignTokensProvider, GlobalStyles, Icon } from "@mgmresorts/mgm-ui";
import { jwtDecode } from "jwt-decode";
import { authActions } from "./data/authentication-slice";

import Header from "./components/global/Header";
import Footer from "./components/global/Footer";
import Routers from "./routes/Routers";
import MainNavigation from "./components/global/MainNavigation";

import useWindowDimensions from "./hooks/useScreenDetails";
import PageBanner from "./components/global/PageBanner";
import ScrollToTop from "./components/global/ScrollToTop";
import { getLocalStoreValue } from "./utils/localStore.js";
import { appInsights } from "./appInsights.js";
import AgentSearchHeader from "./pages/agentsearch/AgentSearchHeader";
import ProfileAgentsearch from "./pages/agentsearch/ProfileAgentsearch";
import { setLocalStoreValue } from "./utils/localStore";

import GraphQLComponent from "./components/global/GraphQLComponent";
import { useTracking } from "./utils/customanalytics/TrackingProvider.js";
import ContentstackLivePreview from "@contentstack/live-preview-utils";
// import AskDigitalAssistant from "./components/global/AskDigitalAssistant.js";
//import { searchGlobalResultActions } from "./data/search-global-result-slice";
import SessionTimeout from "./components/global/SessionTimeout";
// import PilotFeedback from "./components/global/PilotFeedback.js";
import GlobalFlyoutMenu from './components/global/GlobalFlyout.js';
import GlobalFlyoutMenuMobile from './components/global/GlobalFlyoutMobile.js';
import Stock from "./components/common/stock/stock.js";
import preval from "preval.macro";
import useAzureAppConfig from "./hooks/useAzureAppConfig.js";
import SiteMaintananceModal from "./components/global/ui/SiteMaintananceModal.js";

ContentstackLivePreview.init({
  enable: true,
  ssr: false,
});

// APP INSIGHTS
appInsights.loadAppInsights();

function App() {
  const { trackEvent } = useTracking();
  const componentRef = useRef(null);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const stockState = useSelector((state) => state.dom.stockCompState);
  const [isMobile] = useWindowDimensions();
  const [SearchResultPage, setSearchResultPage] = useState(false);
  const [agentsearch, setAgentSearch] = useState(false);
  const [search, setSearch] = useState(false);
  //process.env.REACT_APP_OKTA_LOGIN
  const [isSwitchUser, setSwitchUser] = useState(false);
  const [clockedout, setclockedout] = useState(false);
  const isElectron = getLocalStoreValue("electron") == "electron";
  const [leadershipMsg, setLeadershipMsg] = useState();
  /*const searchClicked = useSelector(
    (state) => state.searchglobalresult.searchClicked
  );*/
  const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
  const showSiteMaintanance = useAzureAppConfig("IsSiteUnderMaintainence");
  dispatch(authActions.setIsPageUnderMaintananace(showSiteMaintanance));
  const isWorkDaydown = useSelector((state) => state.auth.workdayApiState);
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  const gqlData = useSelector((state) => state.gqlData);
  const payRateGroup = gqlData?.profile?.payRateGroup;
  let token = getLocalStoreValue("token");
  let user = "";
  let userRoles = "";
  if (token) {
    user = jwtDecode(token ? token : "");
    userRoles = user?.roles?.join(",");
  }

  const pageTitle = useSelector((state) => state.dom.pageAnalyticsTitle);

  const profile = gqlData?.profile;
  const profileInfo = {
    userid: profile?.employeeId,
    employeeType: profile?.payRateGroup,
    businessLocation: profile?.primaryLocation,
    role: profile?.businessTitle,
    employeeRole: userRoles,
    locale: profile?.preferredLanguage,
    parentPageTitle: pageTitle,
  };
  useEffect(() => {
    function handleClickMain(event) {
      if (componentRef.current && componentRef.current.contains(event.target)) {
        trackEvent("Click Event", {
          componentName: `${window.location.pathname}`,
          event: event,
          "event-target": event.target.outerHTML,
          "parent-target": event.target.parentElement.outerHTML,
          ...profileInfo,
        });
      }
    }

    window.addEventListener("click", handleClickMain);

    return () => {
      window.removeEventListener("click", handleClickMain);
    };
  }, [pageTitle]);
  useEffect(() => {
    console.log(getLocalStoreValue("MGMisAuthenticated") === "true");
    console.log(getLocalStoreValue("MGMisAuthenticated") === true);
    setLocalStoreValue("preview", "");
    if (getLocalStoreValue("MGMisAuthenticated") === "true") {
      dispatch(authActions.setIsAuthendicated(true));
    }
  }, []);

  let defineSwitchUserUI = function (val) {
    setSwitchUser(val);
  };

  const goBacktoSearchresults = () => {
    setLocalStoreValue("preview", "");
  };

  return (
    <>
    <span hidden>Build time: {dateTimeStamp}</span>
      <SessionTimeout />
      <BrowserRouter>
        <GlobalStyles />
        <DesignTokensProvider>
          {!isAuthenticated ? (
            <Routers
              SwitchUserUI={defineSwitchUserUI}
              leadershipMsg={setLeadershipMsg}
            />
          ) : (
            <>
              <>
                {(showSiteMaintanance || isWorkDaydown) && language && getLocalStoreValue('maintenance')!='true'?<SiteMaintananceModal/>:<></>}
                <GraphQLComponent />
                {!isElectron && !isSwitchUser && <Header pageType="header" />}
              </>

              {/*searchClicked && (
                <div
                  className="blackOverlay"
                  onClick={() => {
                    dispatch(searchGlobalResultActions.setsearchClicked(false));
                  }}
                ></div>
                )*/}

              {isElectron &&
                !agentsearch &&
                getLocalStoreValue("preview") == "preview" && (
                  <>
                    <AgentSearchHeader></AgentSearchHeader>
                    <ProfileAgentsearch></ProfileAgentsearch>
                  </>
                )}
              {/*agentsearch && <div className="h-container">
            <div className="eTitle">Search My MGM</div>
            </div>*/}
              <main
                className={`${clockedout ? "main_clockout" : ""}`}
                ref={componentRef}
              >
                <section
                  className={`container ${
                    clockedout ? "container_clockout" : ""
                  } ${
                    getLocalStoreValue("preview") == "preview"
                      ? "containerElectron"
                      : ""
                  }
        
                `}
                >
                  {!isMobile &&
                    !SearchResultPage &&
                    !search &&
                    !isSwitchUser &&
                    !clockedout && (
                      <section className="nav-container">
                        {isElectron && (
                          <Link to="/agentsearchlanding">
                            <div
                              className="previewBackBtnHolder"
                              onClick={goBacktoSearchresults}
                            >
                              <Icon name="chevron-left" size="small" />
                              <span>Back to results</span>
                            </div>
                          </Link>
                        )}
                        {!isElectron && <><MainNavigation /></>}
                      </section>
                    )}
                  <section
                    className={`page-container ${
                      leadershipMsg ? "leadershipmsg" : ""
                    } ${clockedout ? "page_container_clockout" : ""} ${
                      isElectron || search ? "page-container-agentsearch" : ""
                    }`}
                  >
                    
                    <ScrollToTop />
                    {!SearchResultPage &&
                      !search &&
                      !isSwitchUser &&
                      !clockedout && <PageBanner />}
                    <Routers
                      SearchResultPage={setSearchResultPage}
                      agentsearch={setAgentSearch}
                      search={setSearch}
                      SwitchUserUI={defineSwitchUserUI}
                      clockedout={setclockedout}
                      leadershipMsg={setLeadershipMsg}
                    />
                  </section>
                </section>
              </main>
              {!isElectron && stockState && payRateGroup && payRateGroup != "Hourly" &&(<div className="stockDesktop">
                
                          <Stock></Stock> 
                          </div>)}
              {!isElectron && !agentsearch && !isSwitchUser && (
                <Footer pageType="footer" />
              )}
              {/* {isAuthenticated && !isElectron && !clockedout && (
                <div className="pilotFeedback">
                  <PilotFeedback />
                </div>
              )} */}
              {isAuthenticated && !isElectron && !clockedout && !isMobile && (
                <div className="flyoutMenu">
                  <GlobalFlyoutMenu />
                </div>
              )}
               {isAuthenticated && !isElectron && !clockedout && isMobile && (
                <div className="flyoutMenuMobile">
                  <GlobalFlyoutMenuMobile />
                </div>
              )}
            </>
          )}
        </DesignTokensProvider>

        {/* commenting since it is out of scope */}
        {/* {isAuthenticated && !isElectron && !clockedout && (
          <div className="askAssistant">
            <AskDigitalAssistant />
          </div>
        )} */}
      </BrowserRouter>
    </>
  );
}

export default App;
