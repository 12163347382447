import { useEffect, useState } from "react";
import InnerHtml from "../../../components/dynamic/InnerHtml";
import MatrixWrapper from "./MatrixWrapper";
import styles from "./VolunteeringMatrixFallback.module.css";
import ErrorHanding from "../../ErrorHandling/ErrorHanding";

const EMPTY_STRING = "";

const VolunteeringMatrixFallback = (props) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [getStartedLink, setGetStartedLink] = useState();
  const error = props?.data?.error||props?.data?.graphQLErrors?props?.data:null;

  useEffect(() => {
    if (props?.data?.data?.links_dictionary) {
      const getStartedLink = props?.data?.data?.links_dictionary?.filter(
        (entry) => {
          if (entry.name == "get_started_link") {
            return entry;
          }
        }
      );
      console.log('getStartedLink >>>',getStartedLink);
      setGetStartedLink(getStartedLink);
    }
    if (props?.data?.data?.dictionary) {
      const title = props?.data?.data?.dictionary?.filter((entry) => {
        if (entry.name == "title") {
          return entry;
        }
      });
      setTitle(title);

      const description = props?.data?.data?.dictionary?.filter((entry) => {
        if (entry.name == "content") {
          return entry;
        }
      });
      setDescription(description);
    }
  }, [props?.data]);

  return (
    <>
      {props?.data?.getEmployeeVolunteerMetrics?.status == "404" && (
        error?<><ErrorHanding error={error} /></>:<div className={`${styles.fallbackBg}`}>
          <div className={styles.title}>
            {title ? title[0]?.value : EMPTY_STRING}
          </div>
          <div className={styles.subtitle}>
            <InnerHtml
              description={
                description ? description[0]?.html_value : EMPTY_STRING
              }
            />
          </div>

          <div className={styles.cta}>
            <InnerHtml
              description={`<a href='${
                getStartedLink ? getStartedLink[0]?.link?.href : EMPTY_STRING
              }' target='${
                getStartedLink
                  ? getStartedLink[0]?.open_in == "new_window"
                    ? "_blank"
                    : "_blank"
                  : "_self"
              }'>${
                getStartedLink ? getStartedLink[0]?.link?.title : EMPTY_STRING
              }</a>`}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MatrixWrapper(VolunteeringMatrixFallback);
