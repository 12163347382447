import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import "moment/locale/es";
import { useEffect, useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import { useSelector } from "react-redux";
import CarouselArrows from "../../../../src/components/dynamic/CarouselArrows";
import Loader from "../../../components/global/Loader";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_MY_UPCOMING_EVENTS } from "../../../features/api-wrapper/apiQueries";
import useWindowDimensions from "../../../hooks/useScreenDetails";
import APIError from "../../ErrorHandling/APIError";
import VolunteerCard from "../GivingVolunteering/VolunteerCard";
import styles from "./VolunteerUpcomingEvents.module.css";

const EMPTY_STRING = "";

const responsive = {
  0: { items: 1 },
};

const VolunteerUpcomingEvents = (props) => {
  const carousel = useRef(null);
  const [isMobile] = useWindowDimensions(500);
  const { dictionaryLables, dictionaryWithIconLables } = props.data;
  const profile = useSelector((state) => state.gqlData?.profile);
  const [getVolunteerUpcomingEvents, { loading, error, data }] = useLazyQuery(
    GET_MY_UPCOMING_EVENTS,
    {
      fetchPolicy: "no-cache",
      client: apiMiddlewareClient,
    }
  );
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  useEffect(() => {
    if (profile) {
      getVolunteerUpcomingEvents({
        variables: {
          locationCityState: profile?.userLocation,
        },
      });
    }
  }, [profile]);

  if (language) {
    moment.locale(language);
  }

  if (loading) return <Loader />;
  if (error)
    return (
      <div className="api-error">
        <APIError error={error} />
      </div>
    );

  // hide the component when length of upcoming event is zero
  if (data && data.getUpcomingEvents && data.getUpcomingEvents.length == 0) {
    return EMPTY_STRING;
  }

  return (
    <>
      {!isMobile && (
        <div className={styles.heading}>
          <div className={styles.title}>{dictionaryLables.title}</div>

          <div className={styles.carousalArrow}>
            {!isMobile &&
              data?.getUpcomingEvents &&
              data?.getUpcomingEvents?.length > 1 && (
                <CarouselArrows
                  title={{
                    prev: globalProperties?.previous,
                    next: globalProperties?.next,
                  }}
                  onClick={(e, state) => {
                    state == "prev"
                      ? carousel?.current?.slidePrev(e)
                      : carousel?.current?.slideNext(e);
                  }}
                  totalRecords={data?.getUpcomingEvents}
                />
              )}
          </div>
        </div>
      )}
      <div className={styles.heading}>
        {data?.getUpcomingEvents?.length && isMobile && (
          <div className={styles.title}>{dictionaryLables.title}</div>
        )}
      </div>
      {data?.getUpcomingEvents && data?.getUpcomingEvents.length > 0 && (
        <div>
          <AliceCarousel
            key="carousel"
            responsive={responsive}
            keyboardNavigation={true}
            ref={carousel}
            infinite={false}
            controlsStrategy="responsive"
            mouseTracking
            buttonsDisabled={true}
            centerMode={false}
            dotsDisabled={isMobile ? false : true}
            items={
              data?.getUpcomingEvents &&
              data?.getUpcomingEvents?.length > 0 &&
              data?.getUpcomingEvents.map((events, index) => {
                return (
                  <div className={styles.upcomingEventsContainer} key={index}>
                    <VolunteerCard
                      {...events}
                      key={index}
                      buttonText={dictionaryLables?.view_details}
                      iconList={dictionaryWithIconLables}
                      isSingleCard={true}
                    />
                  </div>
                );
              })
            }
          />
        </div>
      )}
    </>
  );
};

export default VolunteerUpcomingEvents;
