import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Icon, TextInput } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setLicencePlate,
  setPersonalInformation,
} from "../../data/gqlDataReducer";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_PROFILE_DETAILS } from "../../features/api-wrapper/apiQueries";
import "../../styles/profile.css";
import CustomSelectDropDown from "../common/customSelectDropDown/customSelectDropdown";
import useWindowDimensions from "../../hooks/useScreenDetails";
import { US_CITIES } from "../../Const";

const UpdateLicensePlateDetails = gql`
  mutation UpdateLicensePlateDetails(
    $updatedLicensePlateDetails: [LicencePlateInput]
    $unchangedLicensePlateDetails: [LicencePlateInput]
  ) {
    updateLicensePlateDetails(
      updatedLicensePlateDetails: $updatedLicensePlateDetails
      unchangedLicensePlateDetails: $unchangedLicensePlateDetails
    ) {
      id
      state
      plateNo
    }
  }
`;

const updateProfileDetails = gql`
  mutation updateProfileDetails(
    $updatedProfile: UpdateProfileInput
    $unchangedProfile: UpdateProfileInput
  ) {
    updateProfileDetails(
      updatedProfile: $updatedProfile
      unchangedProfile: $unchangedProfile
    ) {
      data {
        nameContactID
        otherProfileContactID
        updatedPreferredFirstName
        updatedPreferredLastName
        updatedPrimaryEmailAddress
        updatedSecondaryEmailAddress
        updatedPhoneNumber
        updatedAddressLine1
        updatedAddressLine2
        updatedPostalCode
        updatedCity
        updatedState
      }
      errorMessage
      errorType
      status
    }
  }
`;

const emailValidation = (email) => {
  const regEx =
    // /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}(.[a-zA-Z{2,8}])?/g;
    /^(?!.*[@]{2,})(?!.*[.]{2,})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;

  if (regEx.test(email)) {
    return true;
  } else {
    return false;
  }
};
const secondaryemailValidation = (email) => {
  const regEx =
    // /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}(.[a-zA-Z{2,8}])?/g;
    /^(?!.*[@]{2,})(?!.*[.]{2,})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;

  if (regEx.test(email) || email == "") {
    return true;
  } else return false;
};

let newObj = {
  id: null,
  licenseId: 0,
  plateNo: "",
  state: US_CITIES[0].name,
  zIndex: 0,
  isLicenseError: { isPlateNo: false, isState: false, message: "" },
  __typename: "LicencePlate",
};

const LicensePlate = (props) => {
  const { editProfile, apiData } = props;
  let fieldErrors = props.fieldErrors;
  const [submitClicked, setSubmitClicked] = useState(false);
  const dispatch = useDispatch();
  const [isMobile] = useWindowDimensions();
  const { data: pData } = useQuery(GET_PROFILE_DETAILS, {
    client: apiMiddlewareClient,
  });
  const [validatioErr, setValidationErr] = useState("");
  const email_address_error =
    props.profData[2].data.dictionaryLables?.email_address_error;
  const postal_code_error = "The postal code must be 5 digits(eg:-12345)";
  const phone_number_error =
    props.profData[2].data.dictionaryLables?.phone_number_error;
  const workday_error = props.profData[2].data.dictionaryLables?.workday_error;
  const duplicate_record_error =
    props.profData[2].data.dictionaryLables?.duplicate_record_found;
  const plateNo = props?.profData[2]?.data?.dictionaryLables?.plate_number;
  const state = props?.profData[2]?.data?.dictionaryLables?.state;
  const fieldBlankError =
    props.profData[2].data.dictionaryLables?.field_cannot_be_blank;
  const poboxError =
    props.profData[2].data.dictionaryLables?.no_special_characters;
  const licensePlateError =
    props.profData[2].data.dictionaryLables?.enter_vehicle_plate_number;
  const postalInvalidError =
    props.profData[2].data.dictionaryLables?.invalid_postal_code_for_state;
  const numberInvalid =
    props.profData[2].data.dictionaryLables?.number_invalid_for_region;
  const cityInvalid = props.profData[2].data.dictionaryLables?.enter_valid_city;
  const prefNameError =
    props.profData[2].data.dictionaryLables?.preferred_name_cannot_be_empty;
  const fieldReqd = props.profData[2].data.dictionaryLables?.field_required;
  const emailError =
    props.profData[2].data.dictionaryLables?.email_already_used;
  const addLicense = props.profData[2].data.dictionaryLables?.add_license_plate;
  const lastNameEmpty =
    props.profData[2].data.dictionaryLables?.validation_last_name_empty;
  const nameSpecialChar =
    props.profData[2].data.dictionaryLables?.validation_special_characters;
  const trashIconAltText =
    props.profData[3]?.data?.dictionaryLables?.remove_license_plate_hover_text;

  const invalidCharactersRegex = /[éäâàëèìöáíöóúñ@$%¢&*+=~?©ÄÃ:-;³¼]/;
  // const invalidReg2=/[i@$%¢&*+=~?©ÄÃ:-!;]/
  const invalidReg2 = /[[\]\\!]/;
  const forbiddenPattern =
    /(pmb|po.box|p.o.box|p.o. box|p. o. box|p.o box|po box|po #|p o box|p.o box|postal box|pobox|pobox#)/;
  const [updatedLicensePlate, setUpdatedLicensePlate] = useState([]);
  const licensePlateBackup = apiData.personalInformation.licencePlate;
  const licensePlate = apiData.personalInformation.licencePlate;

  const [mutateFunction, { data: updateLicensePlateInfo, loading, error }] =
    useMutation(UpdateLicensePlateDetails, { client: apiMiddlewareClient });
  console.log("updateLicensePlateInfoooo", updateLicensePlateInfo);
  const [
    mutateProfileFunction,
    { data: updateProfileInfo, error: updateProfileError },
  ] = useMutation(updateProfileDetails, { client: apiMiddlewareClient });

  const EditprofileDetail = {
    updatedProfile: {
      phoneNumber: editProfile.phone.replace(/[^a-zA-Z0-9]/g, ""),
      primaryEmailAddress: editProfile.email,
      preferredFirstName: editProfile.preffName,
      preferredLastName: editProfile.prefflastName,
      secondaryEmailAddress: editProfile.secEmail,
      addressLine1: props.address.addrLine1,
      addressLine2: props.address.addrLine2,
      city: props.address.city,
      state: props.address.state,
      postalCode: props.address.postalCode,
    },
    unchangedProfile: {
      phoneNumber: apiData.personalInformation.homePhone
        .replace("+1", "")
        .replace(/[^a-zA-Z0-9]/g, ""),
      primaryEmailAddress: apiData.personalInformation.personalEmail,
      preferredFirstName: apiData.personalInformation.preferredFirstName,
      preferredLastName: apiData.personalInformation.preferredLastName,
      secondaryEmailAddress: apiData.personalInformation.secondaryEmail,
      addressLine1: apiData.personalInformation.addressLine1,
      addressLine2: apiData.personalInformation.addressLine2,
      city: apiData.personalInformation.city,
      state: apiData.personalInformation.state,
      postalCode: apiData.personalInformation.postalCode,
    },
  };

  useEffect(() => {
    if (licensePlate.length == 0) {
      return;
    }
    const filteredArray = licensePlate.map((data, index) => {
      return {
        ...data,
        state: US_CITIES.filter((obj) => {
          return obj.shortName == data.state || obj.name == data.state;
        })[0].name,
        licenseId: Math.floor(Math.random() * 100 + index),
        isOpen: false,
        zIndex: 0,
        isLicenseError: {
          isPlateNo: false,
          isState: false,
          message: licensePlateError,
        },
      };
    });
    setUpdatedLicensePlate(filteredArray);
  }, []);

  useEffect(() => {
    if (updateProfileInfo) {
      if (
        updateProfileInfo.updateProfileDetails &&
        updateProfileInfo.updateProfileDetails.errorMessage?.some((sentence) =>
          sentence.includes("The number is not valid")
        )
      ) {
        fieldErrors = {
          ...fieldErrors,
          phoneNumber: { message: numberInvalid, isError: true },
        };
        props.fieldError(fieldErrors);
      } else if (
        updateProfileInfo.updateProfileDetails &&
        updateProfileInfo.updateProfileDetails.errorMessage?.some((sentence) =>
          sentence.includes("not a valid postal code")
        )
      ) {
        fieldErrors = {
          ...fieldErrors,
          postalCode: { message: postalInvalidError, isError: true },
        };
        props.fieldError(fieldErrors);
      } else if (
        updateProfileInfo.updateProfileDetails &&
        updateProfileInfo.updateProfileDetails.errorMessage?.some((sentence) =>
          sentence.includes("City")
        )
      ) {
        fieldErrors = {
          ...fieldErrors,
          city: { message: cityInvalid, isError: true },
        };
        props.fieldError(fieldErrors);
      } else if (
        updateProfileInfo.updateProfileDetails &&
        updateProfileInfo.updateProfileDetails.errorMessage &&
        updateProfileInfo.updateProfileDetails.errorMessage[0]
      ) {
        setValidationErr(
          updateProfileInfo.updateProfileDetails.errorMessage[0]
        );
      } else {
        const {
          phoneNumber,
          primaryEmailAddress,
          secondaryEmailAddress,
          preferredFirstName,
          preferredLastName,
          addressLine1,
          addressLine2,
          city,
          state,
          postalCode,
        } = EditprofileDetail.updatedProfile;
        const personalInformation = {
          ...pData.personalInformation,
          homePhone: phoneNumber,
          personalEmail: primaryEmailAddress,
          secondaryEmail: secondaryEmailAddress,
          preferredFirstName: preferredFirstName,
          preferredLastName: preferredLastName,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          city: city,
          state: state,
          postalCode: postalCode,
        };
        dispatch(setPersonalInformation({ ...personalInformation }));

        const licenseState = [];

        const licenseErrCheck = updatedLicensePlate.map((val) => {
          if (val.plateNo == "" && val.state != "") {
            return {
              ...val,
              isLicenseError: {
                isPlateNo: true,
                isState: false,
                message: licensePlateError,
              },
            };
          } else if (val.plateNo !== "" && val.state == "") {
            return {
              ...val,
              isLicenseError: { isPlateNo: false, isState: true, message: "" },
            };
          }
          return val;
        });
        setUpdatedLicensePlate(licenseErrCheck);
        updatedLicensePlate.forEach((val) => {
          US_CITIES.forEach((city) => {
            if (val.state == city.name) {
              licenseState.push({
                id: val.id,
                plateNo: val.plateNo,
                state: city.shortName,
              });
              console.log("licenseState entered here", licenseState);
            }
          });
        });
        const isLPErrorExist = licenseErrCheck.some(function ({
          isLicenseError,
        }) {
          return isLicenseError.isState || isLicenseError.isPlateNo;
        });

        if (
          !isLPErrorExist &&
          userLocation != "" &&
          (userLocation?.toLowerCase() == "las vegas, nv" ||
            userLocation?.toLowerCase() == "vegas corporate")
        ) {
          mutateFunction({
            variables: {
              updatedLicensePlateDetails: licenseState,
              unchangedLicensePlateDetails: licensePlateBackup.map((obj) => {
                return { id: obj.id, state: obj.state, plateNo: obj.plateNo };
              }),
            },
          })
            .then((data) => {
              dispatch(setLicencePlate(data.data.updateLicensePlateDetails));
              props.onClose();
              props.handleSnackbar(true);
            })
            .catch((errors) => console.log("promise rejected", errors));

          console.log(
            "updatedLicensePlateDetails",
            licenseState,
            licensePlateBackup
          );
        }

        if (
          !isLPErrorExist &&
          userLocation != "" &&
          (userLocation?.toLowerCase() != "las vegas, nv" ||
            userLocation?.toLowerCase() != "vegas corporate")
        ) {
          props.onClose();
          props.handleSnackbar(true);
        }
      }
    }
  }, [updateProfileInfo]);

  // useEffect(() => {
  //   console.log(
  //     "test",
  //     updateLicensePlateInfo &&
  //       userLocation != "" &&
  //       (userLocation?.toLowerCase() == "las vegas, nv" ||
  //         userLocation?.toLowerCase() == "vegas corporate")
  //   );
  //   if (
  //     updateLicensePlateInfo &&
  //     userLocation != "" &&
  //     (userLocation?.toLowerCase() == "las vegas, nv" ||
  //       userLocation?.toLowerCase() == "vegas corporate")
  //   ) {
  //     console.log("am i called inside?");
  //     dispatch(
  //       setLicencePlate(updateLicensePlateInfo.updateLicensePlateDetails)
  //     );
  //     console.log(
  //       "updateLicensePlateInfo - dispatch here",
  //       updateLicensePlateInfo
  //     );
  //     props.onClose();
  //     props.handleSnackbar(true);
  //   }
  // }, [updateLicensePlateInfo]);

  const getSelection = (value, licenseId, isDropdown = false) => {
    props.handleLpEdit(true);
    setSubmitClicked(false);
    const sRecord = updatedLicensePlate.filter((rec) => {
      return rec.licenseId !== licenseId;
    });
    const caseValues = sRecord.map((v) => {
      return v.plateNo.toLowerCase();
    });
    const remainingRecord = updatedLicensePlate.map((item) => {
      if (item.licenseId == licenseId) {
        if (isDropdown) {
          return {
            ...item,
            state: value,
            isOpen: false,
            isLicenseError: {
              ...item.isLicenseError,
              isState: false,
              message: "",
            },
          };
        } else {
          const formattedValue = value.replace(/[^a-zA-Z0-9]/g, "");
          return {
            ...item,
            plateNo: formattedValue,
            isOpen: false,
            isLicenseError: {
              ...item.isLicenseError,
              isPlateNo:
                value == ""
                  ? true
                  : caseValues.includes(formattedValue.toLowerCase()),
              message: value == "" ? licensePlateError : duplicate_record_error,
            },
          };
        }
      }
      return item;
    });

    setUpdatedLicensePlate(remainingRecord);
  };

  const removeLicensePlate = (licenseId) => {
    props.handleLpEdit(true);
    const remainingRecord = updatedLicensePlate.filter((item) => {
      return item.licenseId !== licenseId;
    });
    setUpdatedLicensePlate(remainingRecord);
  };

  const handleAddMore = () => {
    props.handleLpEdit(true);
    const firstObj = {
      ...newObj,
      licenseId: Math.floor(Math.random() * 100 + 1),
    };
    setUpdatedLicensePlate((updatedLicensePlate) => [
      ...updatedLicensePlate,
      firstObj,
    ]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleRadio(true);
    props.setIsModalOpen(false);
    setSubmitClicked(true);
    const { updatedProfile } = EditprofileDetail;
    const workPrimaryEmail = props.workEmail
      ? props.workEmail.filter(
          (item) =>
            item.toLowerCase().trim() ===
            updatedProfile.primaryEmailAddress.toLowerCase().trim()
        )
      : [];
    const worksecondaryEmail = props.workEmail
      ? props.workEmail.filter(
          (item) =>
            item.toLowerCase().trim() ===
            updatedProfile.secondaryEmailAddress.toLowerCase().trim()
        )
      : [];

    if (
      updatedProfile.preferredFirstName.length == "" ||
      /^\s*$/.test(updatedProfile.preferredFirstName)
    ) {
      fieldErrors = {
        ...fieldErrors,
        preferredFirstName: {
          message: prefNameError,
          isError: true,
        },
      };
    } else if (!/^[a-zA-Z\-' ]+$/.test(updatedProfile.preferredFirstName)) {
      fieldErrors = {
        ...fieldErrors,
        preferredFirstName: {
          message: nameSpecialChar,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        preferredFirstName: { message: "", isError: false },
      };
    }
    if (
      updatedProfile.preferredLastName.length == "" ||
      /^\s*$/.test(updatedProfile.preferredLastName)
    ) {
      fieldErrors = {
        ...fieldErrors,
        preferredLastName: {
          message: lastNameEmpty,
          isError: true,
        },
      };
    } else if (!/^[a-zA-Z\-' ]+$/.test(updatedProfile.preferredLastName)) {
      fieldErrors = {
        ...fieldErrors,
        preferredLastName: {
          message: nameSpecialChar,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        preferredLastName: { message: "", isError: false },
      };
    }
    if (
      updatedProfile.addressLine1.length == "" ||
      /^\s*$/.test(updatedProfile.addressLine1)
    ) {
      fieldErrors = {
        ...fieldErrors,
        addressLine1: {
          message: fieldReqd,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        addressLine1: { message: "", isError: false },
      };
    }
    const addrresult = invalidCharactersRegex.test(updatedProfile.addressLine2);
    const addrresult2 = invalidReg2.test(updatedProfile.addressLine2);
    const invalidPattern = forbiddenPattern.test(
      updatedProfile.addressLine2.toLowerCase()
    );
    const cityresult = invalidCharactersRegex.test(updatedProfile.city);
    const cityresult2 = invalidReg2.test(updatedProfile.city);
    const cityinvalidPattern = forbiddenPattern.test(
      updatedProfile.city.toLowerCase()
    );
    const line1result = invalidCharactersRegex.test(
      updatedProfile.addressLine1
    );
    const line2result = invalidReg2.test(updatedProfile.addressLine1);
    const line1InvalidPattern = forbiddenPattern.test(
      updatedProfile.addressLine1.toLowerCase()
    );
    if (cityresult || cityresult2 || cityinvalidPattern) {
      fieldErrors = {
        ...fieldErrors,
        city: {
          message: poboxError,
          isError: true,
        },
      };
    } else if (updatedProfile.city == "" || /^\s*$/.test(updatedProfile.city)) {
      fieldErrors = {
        ...fieldErrors,
        city: {
          message: fieldBlankError,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        city: { message: "", isError: false },
      };
    }
    if (
      (addrresult || addrresult2 || invalidPattern) &&
      props.valueSelected == props.yesText
    ) {
      fieldErrors = {
        ...fieldErrors,
        addressLine2: {
          message: poboxError,
          isError: true,
        },
      };
    } else if (
      props.valueSelected == props.yesText &&
      (updatedProfile.addressLine2.length == "" ||
        /^\s*$/.test(updatedProfile.addressLine2))
    ) {
      fieldErrors = {
        ...fieldErrors,
        addressLine2: {
          message: fieldBlankError,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        addressLine2: { message: "", isError: false },
      };
    }
    if (line1result || line2result || line1InvalidPattern) {
      fieldErrors = {
        ...fieldErrors,
        addressLine1: {
          message: poboxError,
          isError: true,
        },
      };
    } else if (
      updatedProfile.addressLine1 == "" ||
      /^\s*$/.test(updatedProfile.addressLine1)
    ) {
      fieldErrors = {
        ...fieldErrors,
        addressLine1: {
          message: fieldBlankError,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        addressLine1: { message: "", isError: false },
      };
    }
    if (props.valueSelected == "") {
      fieldErrors = {
        ...fieldErrors,
        radioSelected: {
          message: "radio selected is wrong",
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        radioSelected: {
          message: " ",
          isError: false,
        },
      };
    }

    if (updatedProfile.phoneNumber.length !== 10) {
      fieldErrors = {
        ...fieldErrors,
        phoneNumber: {
          message: phone_number_error,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        phoneNumber: { message: "", isError: false },
      };
    }
    if (
      updatedProfile.primaryEmailAddress == "" ||
      /^\s*$/.test(updatedProfile.primaryEmailAddress) ||
      !emailValidation(updatedProfile.primaryEmailAddress) ||
      workPrimaryEmail.length > 0
    ) {
      fieldErrors = {
        ...fieldErrors,
        primaryEmailAddress: {
          message:
            workPrimaryEmail.length > 0 ? workday_error : email_address_error,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        primaryEmailAddress: { message: "", isError: false },
      };
    }
    // console.log("postal code from api",updateProfileInfo?.updateProfileDetails?.errorMessage?.includes('is not a valid postal code for'));
    if (
      updatedProfile.postalCode.length !== 5 ||
      /^\s*$/.test(updatedProfile.postalCode)
    ) {
      fieldErrors = {
        ...fieldErrors,
        postalCode: { message: postal_code_error, isError: true },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        postalCode: { message: "", isError: false },
      };
    }

    if (
      !secondaryemailValidation(updatedProfile.secondaryEmailAddress) &&
      !/^\s*$/.test(updatedProfile.secondaryEmailAddress)
    ) {
      fieldErrors = {
        ...fieldErrors,
        secondaryEmailAddress: {
          message: email_address_error,
          isError: true,
        },
      };
    } else if (
      (updatedProfile.secondaryEmailAddress.toLowerCase().trim() ==
        updatedProfile.primaryEmailAddress.toLowerCase().trim() &&
        !/^\s*$/.test(updatedProfile.primaryEmailAddress)) ||
      worksecondaryEmail.length > 0
    ) {
      fieldErrors = {
        ...fieldErrors,
        secondaryEmailAddress: {
          message: worksecondaryEmail.length > 0 ? workday_error : emailError,
          isError: true,
        },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        secondaryEmailAddress: { message: "", isError: false },
      };
    }
    if (props.inputSelected && props.placeSelected) {
      props.handleSubmitClick(true);
      fieldErrors = {
        ...fieldErrors,
        addressNotSelected: { message: "", isError: true },
      };
    } else {
      fieldErrors = {
        ...fieldErrors,
        addressNotSelected: { message: "", isError: false },
      };
    }

    if (
      fieldErrors.preferredFirstName.isError ||
      fieldErrors.preferredLastName.isError ||
      fieldErrors.phoneNumber.isError ||
      fieldErrors.primaryEmailAddress.isError ||
      fieldErrors.postalCode.isError ||
      fieldErrors.city.isError ||
      fieldErrors.secondaryEmailAddress.isError ||
      fieldErrors.addressLine1.isError ||
      fieldErrors.addressLine2.isError ||
      fieldErrors.radioSelected.isError ||
      fieldErrors.addressNotSelected.isError
    ) {
      return props.fieldError(fieldErrors);
    } else {
      props.fieldError({
        preferredFirstName: { message: "", isError: false },
        preferredLastName: { message: "", isError: false },
        phoneNumber: {
          message: "",
          isError: false,
        },
        primaryEmailAddress: { message: "", isError: false },
        secondaryEmailAddress: { message: "", isError: false },
        postalCode: { message: "", isError: false },
        addressLine1: { message: "", isError: false },
        addressLine2: { message: "", isError: false },
        city: { message: "", isError: false },
        radioSelected: { message: "", isError: false },
        addressNotSelected: { message: "", isError: false },
      });

      mutateProfileFunction({
        variables: EditprofileDetail,
      });
    }
  };

  const getOptionList = () => {
    return US_CITIES.map((item) => {
      return item.name;
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  if (updateProfileError) return <p>Error : {updateProfileError.message}</p>;
  const userLocation = props.apiData?.profile?.userLocation;
  return (
    <>
      {updatedLicensePlate.map((data) => {
        return (
          <>
            {userLocation != "" &&
            isMobile &&
            (userLocation?.toLowerCase() == "las vegas, nv" ||
              userLocation?.toLowerCase() == "vegas corporate") ? (
              <>
                <div key={data.licenseId} className="licensePlate">
                  <div className="textbox-edit-new">
                    <label className="google-text">{state}</label>
                    <CustomSelectDropDown
                      error={data.isLicenseError.isState}
                      hint={data.isLicenseError.isState ? "state error" : ""}
                      key={data.licenseId}
                      id={data.licenseId}
                      optionsList={getOptionList()}
                      onChange={(value, text) =>
                        getSelection(text, data.licenseId, true)
                      }
                      selected={data.state}
                      isType2={true}
                      posit
                    />
                    {data.isLicenseError.isPlateNo ? (
                      submitClicked ? (
                        <div style={{ visibility: "hidden" }}>
                          {licensePlateError}
                        </div>
                      ) : (
                        <div style={{ visibility: "hidden" }}>
                          {duplicate_record_error}
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="platenum-trash-mobile">
                    <div className="textbox-edit-new-platenumber">
                      <label
                        className={
                          data.isLicenseError.isPlateNo
                            ? "google-text-error"
                            : "google-text"
                        }
                      >
                        {plateNo}
                      </label>
                      <TextInput
                        error={data.isLicenseError.isPlateNo}
                        maxLength="8"
                        hint={
                          data.isLicenseError.isPlateNo
                            ? data.isLicenseError.message
                            : ""
                        }
                        onChange={(eve) => {
                          getSelection(eve.target.value, data.licenseId);
                        }}
                        // label={plateNo}
                        value={data.plateNo}
                      ></TextInput>
                    </div>

                    <div className="deleteButton" title={trashIconAltText}>
                      <Icon
                        name="trash-can"
                        size="small"
                        onClick={() => {
                          removeLicensePlate(data.licenseId);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div key={data.licenseId} className="licensePlate flexBox">
                  <div className="textbox-edit-new">
                    <label className="google-text">{state}</label>

                    <CustomSelectDropDown
                      error={data.isLicenseError.isState}
                      hint={data.isLicenseError.isState ? "state error" : ""}
                      key={data.licenseId}
                      id={data.licenseId}
                      optionsList={getOptionList()}
                      onChange={(value, text) =>
                        getSelection(text, data.licenseId, true)
                      }
                      selected={data.state}
                      isType2={true}
                      posit
                    />
                    {data.isLicenseError.isPlateNo ? (
                      submitClicked ? (
                        <div style={{ visibility: "hidden" }}>
                          {licensePlateError}
                        </div>
                      ) : (
                        <div style={{ visibility: "hidden" }}>
                          {duplicate_record_error}
                        </div>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="textbox-edit-new-platenumber">
                    <label
                      className={
                        data.isLicenseError.isPlateNo
                          ? "google-text-error"
                          : "google-text"
                      }
                    >
                      {plateNo}
                    </label>
                    <TextInput
                      error={data.isLicenseError.isPlateNo}
                      maxLength="8"
                      hint={
                        data.isLicenseError.isPlateNo
                          ? data.isLicenseError.message
                          : ""
                      }
                      onChange={(eve) => {
                        getSelection(eve.target.value, data.licenseId);
                      }}
                      // label={plateNo}
                      value={data.plateNo}
                    ></TextInput>
                  </div>
                  <div className="deleteButton" title={trashIconAltText}>
                    <Icon
                      name="trash-can"
                      size="small"
                      onClick={() => {
                        removeLicensePlate(data.licenseId);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        );
      })}
      {userLocation != "" &&
      (userLocation?.toLowerCase() == "las vegas, nv" ||
        userLocation?.toLowerCase() == "vegas corporate") ? (
        <>
          {updatedLicensePlate.length < 3 && (
            <button onClick={handleAddMore} className="add-more-license">
              <a className="addLicenseLink"> {addLicense}</a>
            </button>
          )}
        </>
      ) : (
        ""
      )}
      {validatioErr != "" && (
        <p className="google-text-error" style={{ marginTop: "16px" }}>
          {validatioErr}
        </p>
      )}
      <div className="saveButton">
        <Button
          className="profileSaveButton"
          label={props.profData[3]?.data?.links[0]?.title}
          size="small"
          variant="primary"
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};
export default LicensePlate;