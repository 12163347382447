import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reportersDashboard: null,
};

const managerDashboardSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setReportersDashboard(state, action) {
      console.log("slice", state, action.payload);
      // state.reportersDashboard = action.payload;
      return { ...state, reportersDashboard: action.payload };
    },
  },
});
console.log("slice initialState", initialState?.reportersDashboard);
export const managerDashboardActions = managerDashboardSlice.actions;
export default managerDashboardSlice;
