import { Link } from "react-router-dom";
import InnerHtml from "../../components/dynamic/InnerHtml";
import service from "./ActionPanel.module.css";
import CTATextLinkDescription from "./CTATextLinkDescription";
import { useState } from "react";
import Modal from "../global/ui/Modal";
import { useLocation } from "react-router-dom";

// #todo: make condition based on borderless | remove useLocation
const ActionPanel = (props) => {
  const {
    heading,
    description,
    type,
    children: service_list,
    viewAll,
    viewAllNewWindow,
  } = props.data;
  console.log("actionpanel", service_list);

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const location = useLocation();
  const cLocation = location.pathname.split("/").pop();
  console.log(cLocation);


  const openModal = (args) => {
    let modal = service_list[args]?.cta?.modalContent
      ? service_list[args]?.cta?.modalContent
      : service_list[args]?.cta?.modal_content;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div
        className={
          type == "no_border_bg"
            ? `${service.sscontainerborder}`
            : `${service.sscontainer}`
        }
      >
        {/* May add a new flag for header */}
        {(cLocation == "401-k-savings-plan" ||
          (cLocation != "401-k-savings-plan" && type !== "no_border_bg")) && (
          <div className={`${service.ssheadersection} col-12`}>
            <span className={service.ssheader}>{heading}</span>
            {!viewAllNewWindow ? (
              <Link className={service.ssviewall} to={viewAll.href}>
                {viewAll.title}
              </Link>
            ) : (
              <Link
                className={service.ssviewall}
                to={viewAll.href}
                target="_blank"
              >
                {viewAll.title}
              </Link>
            )}
          </div>
        )}

        {type !== "no_border_bg" && (
          <InnerHtml
            description={description}
            style={`${service.sssubheader} col-12`}
          />
        )}
        <div
          className={
            type == "no_border_bg"
              ? service.ssDetailsSectionborder
              : service.ssDetailsSection
          }
        >
          {service_list.map((val, index) => {
            return (
              <div
                key={val.uid}
                className={
                  type == "no_border_bg"
                    ? `${service.service_border_twocol}`
                    : `${service.service_border}`
                }
              >
                <CTATextLinkDescription
                  size="small"
                  iconRight="arrow-right"
                  description={val.cta?.description}
                  type="text_link"
                  style={`${type == "no_border_bg" && service.borderless} ${
                    service.ssentry
                  }  pt-3 pb-3`}
                  args={index}
                  openModal={openModal}
                  data={{
                    label: val.cta?.label,
                    url: val.cta?.url,
                    type: "secondary",
                    openIn: val.cta?.openIn || val.cta?.open_in,
                    leadingIcon: val.cts?.leadingIcon || val.cts?.leading_icon,
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </>
  );
};

export default ActionPanel;
