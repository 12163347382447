import { useQuery } from "@apollo/client";
import { CardContainer, Tab } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import Loader from "../../components/global/Loader";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import {
  GET_HR_DETAILS,
  GET_IT_DETAILS,
} from "../../features/api-wrapper/apiQueries";
import useWindowDimensions from "../../hooks/useScreenDetails";
import APIError from "../ErrorHandling/APIError";
import Case from "./Case";
import styles from "./Cases.module.css";

const EMPTY_STRING = "";

const Cases = (props) => {
  const [, isBelow] = useWindowDimensions(768);
  const [selectedCase, setSelectedCase] = useState(0);
  const [isHRError, setIsHRError] = useState(false);
  const [isITError, setIsITError] = useState(false);
  const [hrCases, setHRCases] = useState();
  const [itCases, setITCases] = useState();
  const { loading, error, data } = useQuery(GET_HR_DETAILS, {
    client: apiMiddlewareClient,
  });

  useEffect(() => {
    if (props.data && props.data?.dictionary?.length > 0) {
      const hrCases = props.data?.dictionary.slice(0, 4);
      hrCases.push(props.data.links_dictionary[0]);
      setHRCases(hrCases);
      const itCases = props.data?.dictionary.slice(4, 8);
      itCases.push(props.data.links_dictionary[1]);
      setITCases(itCases);
    }
  }, [props.data]);

  const {
    loading: ITLoading,
    error: ITError,
    data: ITData,
  } = useQuery(GET_IT_DETAILS, {
    client: apiMiddlewareClient,
  });

  const handleCases = (index) => {
    setSelectedCase(index);
  };

  const errorSection = (type) => {
    return (
      <div className={styles.errorBlock}>
        <APIError error={type=='IT_ERROR' ? ITError : error} />
      </div>
    );
  };
  useEffect(()=>{
    if (ITError) {
      setIsITError(true);
      errorSection('IT_ERROR');
    }
  },[ITError]);
  useEffect(()=>{
    if (error) {
      setIsHRError(true);
      errorSection('HR_ERROR');
    }
  },[error]);
  

  if (loading || ITLoading) return <Loader />;

  return (
    <div className={styles.casesWrapper}>
      <CardContainer className={styles.caseContainer}>
        <div className={styles.casesInner}>
          {isBelow && hrCases && itCases && (
            <div className={styles.anchors}>
              <Tab
                label={hrCases[0].value}
                className={`${styles.tabItem} ${
                  selectedCase === 0 ? styles.active : EMPTY_STRING
                }`}
                onClick={() => handleCases(0)}
              />
              <Tab
                label={itCases[0].value}
                className={`${styles.tabItem} ${
                  selectedCase === 1 ? styles.active : EMPTY_STRING
                }`}
                onClick={() => handleCases(1)}
              />
            </div>
          )}
          {!isBelow ? (
            <>
              {data && data.hrCases && hrCases && hrCases.length > 0 && (
                <Case
                  data={hrCases}
                  dummyValues={
                    data.hrCases ? data.hrCases?.noOfOpenCases : EMPTY_STRING
                  }
                  noOfTask={
                    data.hrCases ? data.hrCases?.noOfTasks : EMPTY_STRING
                  }
                  isBelow={isBelow}
                />
              )}
              {isHRError && errorSection('HR_ERROR')}
              {ITData && itCases && itCases.length > 0 && (
                <Case
                  data={itCases}
                  dummyValues={
                    ITData.itCases
                      ? ITData.itCases?.noOfOpenCases
                      : EMPTY_STRING
                  }
                  isBelow={isBelow}
                />
              )}
              {isITError && errorSection('IT_ERROR')}
            </>
          ) : (
            <>
              {data && hrCases && selectedCase === 0 && (
                <Case
                  data={hrCases}
                  dummyValues={
                    data.hrCases ? data.hrCases?.noOfOpenCases : EMPTY_STRING
                  }
                  noOfTask={
                    data.hrCases ? data.hrCases?.noOfTasks : EMPTY_STRING
                  }
                  isBelow={isBelow}
                />
              )}
              {isHRError && selectedCase === 0 && errorSection('HR_ERROR')}
              {ITData && itCases && selectedCase === 1 && (
                <Case
                  data={itCases}
                  dummyValues={
                    ITData.itCases
                      ? ITData.itCases?.noOfOpenCases
                      : EMPTY_STRING
                  }
                  isBelow={isBelow}
                />
              )}
              {isITError && selectedCase === 1 && errorSection('IT_ERROR')}
            </>
          )}
        </div>
      </CardContainer>
    </div>
  );
};

export default Cases;
