import { Button, Icon, Tab } from "@mgmresorts/mgm-ui";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../hooks/useScreenDetails";
import Loader from "../global/Loader";
import InnerHtml from "./InnerHtml";
import style from "./ViewCurrentBenefitsDialogue.module.css";
import { formatNumber } from "../../utils/formatNumber";
import { EMPTY_STRING, NOT_APPLICABLE } from "../../Const";

export const ViewCurrentBenefitsDialogue = (props) => {
  const {
    dictionaryLabel,
    benefits,
    isOpenEnrollmentStarted,
    IsEnrollmentPlanSubmitted,
    isOpenEnrollmentCompleted,
    loading,
    totalEmployeeCostCurrentBenefit,
    totalEmployerCostCurrentBenefit,
    totalEmployeeCostUpcomingBenefit,
    totalEmployerCostUpcomingBenefit,
  } = props;

  const BENEFIT = {
    CURRENT: 0, //"CURRENT", //0
    UPCOMING: 1, //"UPCOMING", //1
    // ENROLLMENT_NOT_SUBMITTED: "ENROLLMENT_NOT_SUBMITTED",
  };

  const curBenefitData = benefits?.getBenefits?.currentBenefits;
  const upcBenefitData = benefits?.getBenefits?.upcomingBenefits;

  const [selectedTab, setSelectedTab] = useState(0); //CURRENT, UPCOMING, -1
  // const [employerCost, setEmployerCost] = useState();
  // const [employeeCost, setEmployeeCost] = useState();
  const [benefitData, setBenefitData] = useState(null);
  const [enableWelCredit, setEnableWelCredit] = useState(true);
  const gqlData = useSelector((state) => state.gqlData);
  const payRateGroup = gqlData?.profile?.payRateGroup;
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  if (language) {
    moment.locale(language);
  }

  useEffect(() => {
    if (selectedTab == BENEFIT.CURRENT) {
      setBenefitData(curBenefitData);
    } else if (selectedTab == BENEFIT.UPCOMING) {
      setBenefitData(upcBenefitData);
    }
  }, [selectedTab]);

  const getdynamicText = () => {
    return (
      selectedTab == BENEFIT.UPCOMING && (
        <div>
          <InnerHtml
            description={
              payRateGroup == "Hourly"
                ? dictionaryLabel?.not_enrolled_message_hourly?.replace(
                    '<span translate="no">{variable-enrollment_date}</span>',
                    `${moment(
                      benefits?.getBenefits?.enrollmentStartDate
                    )?.format("LL")} - ${moment(
                      benefits?.getBenefits?.enrollmentEndDate
                    )?.format("LL")}`
                  )
                : dictionaryLabel?.not_enrolled_message_salary?.replace(
                    '<span translate="no">{variable-enrollment_date}</span>',
                    `${moment(
                      benefits?.getBenefits?.enrollmentStartDate
                    )?.format("LL")} - ${moment(
                      benefits?.getBenefits?.enrollmentEndDate
                    )?.format("LL")}`
                  )
            }
            style={style.innerHtml}
          ></InnerHtml>
          <div className={style.footerArea}>
            <Button
              size="large"
              variant="secondary"
              onClick={() => {
                props.onClose();
              }}
            >
              {payRateGroup == "Hourly"
                ? dictionaryLabel?.not_enrolled_button_cancel_hourly
                : dictionaryLabel?.not_enrolled_button_cancel_salary}
            </Button>
            <Button
              className={style.stickyFooterPrimaryBtn}
              size="large"
              variant="primary"
              onClick={() => {
                window.open(visitWorkdayLink?.href, "_blank");
              }}
            >
              {payRateGroup == "Hourly"
                ? dictionaryLabel?.not_enrolled_button_continue_hourly
                : dictionaryLabel?.not_enrolled_button_continue_salary}
            </Button>
          </div>
        </div>
      )
    );
  };

  // useEffect(() => {
  //   if (isOpenEnrollmentStarted != undefined) {
  //     if (isOpenEnrollmentStarted) {
  //       setSelectedTab(BENEFIT.UPCOMING);
  //     } else {
  //       setSelectedTab(BENEFIT.CURRENT);
  //     }
  //   }
  // }, [isOpenEnrollmentStarted]);

  useEffect(() => {
    //fyi: hiding welness credit columb if no data is there present of it
    if (benefitData?.length) {
      if (selectedTab == BENEFIT.CURRENT) {
        let curBenefitDataWithWC = curBenefitData.filter((d) => {
          return d.wellnessCredit != "";
        })?.length;

        setEnableWelCredit(Boolean(curBenefitDataWithWC));
      } else {
        let upcBenefitDataWithWC = upcBenefitData.filter((d) => {
          return d.wellnessCredit != "";
        })?.length;

        setEnableWelCredit(Boolean(upcBenefitDataWithWC));
      }
      ("");
      // const employerCost = benefitData.reduce((accumulator, current) => {
      //   return (
      //     accumulator +
      //     parseFloat(
      //       current.employerCost == NOT_APPLICABLE ? 0 : current.employerCost
      //     )
      //   );
      // }, 0);
      // setEmployerCost(parseFloat(employerCost).toFixed(2));
      // const employeeCost = benefitData.reduce((accumulator, current) => {
      //   return (
      //     accumulator +
      //     parseFloat(
      //       current?.employeeCost?.amount == NOT_APPLICABLE
      //         ? 0
      //         : current?.employeeCost?.amount
      //     )
      //   );
      // }, 0);
      // setEmployeeCost(parseFloat(employeeCost).toFixed(2));
    }
  }, [benefitData]);

  const handleTab = (index) => {
    setSelectedTab(index);
  };

  const [isMobile] = useWindowDimensions();
  const changeYourBenfitLink =
    props.csData[0]?.links_dictionary?.filter(
      (d) => d.name == "change_your_benefits"
    )?.length &&
    props.csData[0]?.links_dictionary?.filter(
      (d) => d.name == "change_your_benefits"
    )[0]?.link;
  const visitWorkdayLink =
    props &&
    props.csData &&
    props.csData[0]?.links_dictionary?.filter(
      (d) => d.name == "visit_workday_current_benefits"
    )[0]?.link;
  const newPlan =
    props &&
    props.csData &&
    props.csData[0]?.dictionary_with_icon?.filter(
      (d) => d.name == "new_plan"
    )[0];
  const rateChange =
    props &&
    props.csData &&
    props.csData[0]?.dictionary_with_icon?.filter(
      (d) => d.name == "rate_change"
    )[0];

  return (
    <div>
      <div className={style.modal}>
        <div
          className={
            benefitData && benefitData.length > 0
              ? style.innerHolder
              : style.noBenefitInnerHolder
          }
        >
          <div className={style.coreHolder}>
            {!isMobile && (
              <div>
                <div className={style.tabHolder}>
                  <Tab
                    label={dictionaryLabel?.tab1_title}
                    className={`${style.tabItem} ${
                      selectedTab === BENEFIT.CURRENT ? style.active : ""
                    }`}
                    onClick={() => handleTab(0)}
                  />
                  {isOpenEnrollmentStarted && (
                    <Tab
                      label={dictionaryLabel?.tab2_title}
                      className={`${style.tabItem} ${
                        selectedTab === BENEFIT.UPCOMING ? style.active : ""
                      } `}
                      onClick={() => handleTab(1)}
                      disabled={!isOpenEnrollmentStarted ? true : false}
                    />
                  )}
                </div>

                {(!isOpenEnrollmentStarted ||
                  (isOpenEnrollmentStarted &&
                    !IsEnrollmentPlanSubmitted &&
                    selectedTab == BENEFIT.CURRENT) ||
                  (isOpenEnrollmentStarted &&
                    IsEnrollmentPlanSubmitted &&
                    selectedTab == BENEFIT.CURRENT)) &&
                  (benefitData && benefitData.length > 0 ? (
                    <div className={style.scrollContainer}>
                      <div
                        className={`${style.viewCurrentBenefitTable} ${style.currentDesktop} ${style.currentBenefitsTable}`}
                      >
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "17%" }}>
                                {dictionaryLabel?.benefit_plan}
                              </th>
                              <th style={{ width: "12%" }}>
                                {dictionaryLabel?.coverage_begin_date}
                              </th>
                              <th style={{ width: "12%" }}>
                                {dictionaryLabel?.deduction_begin_date}
                              </th>
                              <th style={{ width: "12%" }}>
                                {dictionaryLabel?.coverage}
                              </th>
                              {enableWelCredit && (
                                <th style={{ width: "15%" }}>
                                  <div>{dictionaryLabel?.wellness_credit}</div>
                                  <div>({dictionaryLabel?.per_paycheck})</div>
                                </th>
                              )}
                              <th style={{ width: "15%" }}>
                                <div>
                                  {dictionaryLabel?.employer_contribution}
                                </div>
                                <div>({dictionaryLabel?.per_paycheck})</div>
                              </th>
                              <th style={{ width: "15%" }}>
                                <div>{dictionaryLabel?.employee_cost}</div>
                                <div>({dictionaryLabel?.per_paycheck})</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {benefitData &&
                              benefitData.map((bene, index) => {
                                return (
                                  <>
                                    {!bene?.benefitPlan?.newPlanIndicator && (
                                      <tr key={index}>
                                        <td style={{ width: "17%" }}>
                                          {bene.benefitPlan.name}
                                        </td>
                                        <td style={{ width: "12%" }}>
                                          {moment
                                            .utc(bene.coverageBeginDate)
                                            .format("MM/DD/YYYY")}
                                        </td>
                                        <td style={{ width: "12%" }}>
                                          {moment
                                            .utc(bene.deductionBeginDate)
                                            .format("MM/DD/YYYY")}
                                        </td>
                                        <td style={{ width: "12%" }}>
                                          {bene.coverage}
                                        </td>
                                        {enableWelCredit && (
                                          <td
                                            className={style.colStyle2}
                                            style={{ width: "15%" }}
                                          >
                                            {bene.wellnessCredit !== ""
                                              ? `$${bene.wellnessCredit}`
                                              : NOT_APPLICABLE}
                                          </td>
                                        )}
                                        <td
                                          className={style.colStyle2}
                                          style={{ width: "15%" }}
                                        >
                                          {/* {bene.employerContribution} */}
                                          {bene.employerCost == NOT_APPLICABLE
                                            ? bene.employerCost
                                            : `$${formatNumber(
                                                bene.employerCost
                                              )}`}
                                        </td>
                                        <td
                                          className={style.colStyle2}
                                          style={{ width: "15%" }}
                                        >
                                          {bene.employeeCost?.amount ==
                                          NOT_APPLICABLE
                                            ? bene.employeeCost?.amount
                                            : `$${formatNumber(
                                                bene.employeeCost?.amount
                                              )}`}
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                );
                              })}
                            {benefitData &&
                              benefitData.length == 0 &&
                              !loading && (
                                <tr>
                                  <th
                                    style={{ width: "100%" }}
                                    colSpan="4"
                                    rowSpan="4"
                                    align="center"
                                    className={style.loadBenefits}
                                  >
                                    {dictionaryLabel?.no_benefits}
                                  </th>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                      {/* Current Benefits Footer Desktop */}
                      <div className={style.currentBenefitStickyFooter}>
                        <table
                          className={`${style.currentBenefitFooterTotal}
                            ${
                              !enableWelCredit
                                ? style.currentBenfitWelCreditTotal
                                : null
                            }`}
                        >
                          <td className={`${style.alignLeft} `}>
                            <div>{dictionaryLabel?.total_per_paycheck}</div>
                          </td>
                          <td></td>
                          <td></td>
                          {enableWelCredit && <td></td>}
                          <td
                            className={`${style.alignRight} ${style.footerTotalText} `}
                          >
                            <div>
                              {totalEmployerCostCurrentBenefit !== EMPTY_STRING
                                ? `$${formatNumber(totalEmployerCostCurrentBenefit)}`
                                : EMPTY_STRING}
                            </div>
                          </td>
                          <td
                            className={`${style.alignRight} ${style.footerTotalText} `}
                          >
                            <div>
                              {totalEmployeeCostCurrentBenefit !== EMPTY_STRING
                                ? `$${formatNumber(totalEmployeeCostCurrentBenefit)}`
                                : EMPTY_STRING}
                            </div>
                          </td>
                        </table>
                      </div>
                    </div>
                  ) : (
                    ""
                  ))}
                {isOpenEnrollmentCompleted ||
                (isOpenEnrollmentStarted && IsEnrollmentPlanSubmitted)
                  ? selectedTab == BENEFIT.UPCOMING &&
                    (benefitData && benefitData.length > 0 ? (
                      <div className={style.scrollContainer}>
                        <div className={style.viewCurrentBenefitTable}>
                          <table>
                            <thead>
                              <tr>
                                <th style={{ maxWidth: "300px" }}>
                                  {dictionaryLabel?.benefit_plan}
                                </th>
                                <th>{dictionaryLabel?.coverage_begin_date}</th>
                                <th>{dictionaryLabel?.deduction_begin_date}</th>
                                <th>{dictionaryLabel?.coverage}</th>
                                {enableWelCredit && (
                                  <th>
                                    <div>
                                      {dictionaryLabel?.wellness_credit}
                                    </div>

                                    <div>({dictionaryLabel?.per_paycheck})</div>
                                  </th>
                                )}
                                <th className={style.lastCols}>
                                  <div>
                                    {dictionaryLabel?.employer_contribution}
                                  </div>
                                  <div>({dictionaryLabel?.per_paycheck})</div>
                                </th>
                                <th className={style.lastCols}>
                                  <div>{dictionaryLabel?.employee_cost}</div>
                                  <div>({dictionaryLabel?.per_paycheck})</div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {benefitData &&
                                benefitData.map((bene, index) => {
                                  return (
                                    <tr key={index}>
                                      <td style={{ maxWidth: "300px" }}>
                                        <div
                                          className={
                                            bene?.benefitPlan?.newPlanIndicator
                                              ? style.starIconContainer
                                              : style.starFlexContainer
                                          }
                                        >
                                          {bene?.benefitPlan
                                            ?.newPlanIndicator && (
                                            <Icon
                                              style={{
                                                marginRight: "8px",
                                                marginBottom: "3px",
                                                // width: "50px",
                                              }}
                                              color="#FFAD33"
                                              className={style.starIcon}
                                              name="star"
                                              size="small"
                                              variant="filled"
                                            />
                                          )}
                                          <div>{bene.benefitPlan?.name}</div>
                                        </div>
                                      </td>
                                      <td>
                                        {moment
                                          .utc(bene.coverageBeginDate)
                                          .format("MM/DD/YYYY")}
                                      </td>
                                      <td>
                                        {moment
                                          .utc(bene.deductionBeginDate)
                                          .format("MM/DD/YYYY")}
                                      </td>
                                      <td>{bene.coverage}</td>
                                      {enableWelCredit && (
                                        <td>
                                          {bene.wellnessCredit !== ""
                                            ? `$${bene.wellnessCredit}`
                                            : NOT_APPLICABLE}
                                        </td>
                                      )}
                                      <td className={style.lastCols}>
                                        {bene.employerCost == NOT_APPLICABLE
                                          ? bene.employerCost
                                          : `$${formatNumber(
                                              bene.employerCost
                                            )}`}
                                      </td>
                                      <td className={style.lastCols}>
                                        {bene?.employeeCost
                                          ?.rateChangeIndicator && (
                                          <Icon
                                            style={{
                                              marginRight: "8px",
                                              marginBottom: "3px",
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            color="#A96BB6"
                                            className={style.starIcon}
                                            name="exclamation-point-octagon"
                                            size="small"
                                            variant="filled"
                                          />
                                        )}
                                        $
                                        {formatNumber(
                                          bene.employeeCost?.amount
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              {benefitData &&
                                benefitData.length == 0 &&
                                !loading && (
                                  <tr>
                                    <th
                                      style={{ width: "100%" }}
                                      colSpan="4"
                                      rowSpan="4"
                                      align="center"
                                      className={style.loadBenefits}
                                    >
                                      {dictionaryLabel?.no_benefits}
                                    </th>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                        <div className={style.stickyFooter}>
                          <table>
                            <tr
                              className={`${style.footerTotal} ${
                                enableWelCredit ? style.enableCredits : null
                              }`}
                            >
                              <td className={`${style.alignLeft} `}>
                                <div className={style.totalPayback}>
                                  {dictionaryLabel?.total_per_paycheck}
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                              {enableWelCredit && <td></td>}
                              <td
                                className={`${style.alignRight} ${style.footerTotalText} `}
                              >
                                <div>
                                  {totalEmployerCostUpcomingBenefit !=
                                  EMPTY_STRING
                                    ? `$${formatNumber(totalEmployerCostUpcomingBenefit)}`
                                    : EMPTY_STRING}
                                </div>
                              </td>
                              <td
                                className={`${style.alignRight} ${style.footerTotalText} `}
                              >
                                <div>
                                  {totalEmployeeCostUpcomingBenefit !==
                                  EMPTY_STRING
                                    ? `$${formatNumber(totalEmployeeCostUpcomingBenefit)}`
                                    : EMPTY_STRING}
                                </div>
                              </td>
                            </tr>
                          </table>
                          <div className={style.stickyFooterBottomArea}>
                            <div className={style.iconContainer}>
                              <span>
                                <Icon
                                  color="#FFAD33"
                                  className={style.starIcon}
                                  name="star"
                                  size="small"
                                  variant="filled"
                                ></Icon>
                                {props.csData[0]?.dictionary_with_icon[0].value}
                              </span>
                              <span>
                                <Icon
                                  color="#A96BB6"
                                  name="exclamation-point-octagon"
                                  size="small"
                                  variant="filled"
                                ></Icon>
                                {props.csData[0]?.dictionary_with_icon[1].value}
                              </span>
                            </div>
                            <div className={style.stickyFooterBtnContainer}>
                              {(isOpenEnrollmentStarted &&
                                IsEnrollmentPlanSubmitted &&
                                isOpenEnrollmentCompleted) ||
                              isOpenEnrollmentCompleted ? (
                                <InnerHtml
                                  description={
                                    dictionaryLabel?.open_case_question_message
                                  }
                                />
                              ) : (
                                <Button
                                  className={style.stickyFooterPrimaryBtn}
                                  size="large"
                                  variant="primary"
                                  onClick={() => {
                                    window.open(
                                      changeYourBenfitLink?.href,
                                      "_blank"
                                    );
                                  }}
                                >
                                  {changeYourBenfitLink?.title}
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    ))
                  : getdynamicText()}
              </div>
            )}
            {isMobile ? (
              <>
                <div className={style.mobilenav}>
                  <select
                    value={selectedTab}
                    onChange={(event) =>
                      handleTab(parseInt(event.target.value))
                    }
                  >
                    <option value="0">{dictionaryLabel?.tab1_title}</option>
                    <option value="1">{dictionaryLabel?.tab2_title}</option>
                  </select>
                </div>

                <div>
                  {selectedTab == BENEFIT.CURRENT ||
                  (isOpenEnrollmentStarted && IsEnrollmentPlanSubmitted) ||
                  isOpenEnrollmentCompleted ? (
                    <div>
                      {benefitData && benefitData.length > 0 ? (
                        <div className={style.viewCurrentBenefitTableMob}>
                          <div
                            className={
                              selectedTab == BENEFIT.CURRENT
                                ? style.benefitsTable
                                : style.benefitsTableLongFooter
                            }
                          >
                            {benefitData ? (
                              benefitData.map((bene, index) => {
                                return (
                                  <Fragment key={index}>
                                    <div className={style.benefitsPlanHeader}>
                                      <div className={style.benefitsHeader}>
                                        {bene?.benefitPlan
                                          ?.newPlanIndicator && (
                                          <Icon
                                            color="#FFAD33"
                                            className={style.starIcon}
                                            name="star"
                                            size="small"
                                            variant="filled"
                                            style={{
                                              marginRight: "8px",
                                              marginBottom: "3px",
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                        )}
                                        {bene?.benefitPlan?.name}
                                      </div>
                                    </div>

                                    <div className={style.benefitsRow}>
                                      <div className={style.benefitLabel}>
                                        {dictionaryLabel?.coverage_begin_date}
                                      </div>
                                      <div className={style.benefitValue}>
                                        {bene?.coverageBeginDate
                                          ? moment
                                              .utc(bene.coverageBeginDate)
                                              .format("MM/DD/YYYY")
                                          : ""}
                                      </div>
                                    </div>

                                    <div className={style.benefitsRow}>
                                      <div className={style.benefitLabel}>
                                        {dictionaryLabel?.deduction_begin_date}
                                      </div>
                                      <div className={style.benefitValue}>
                                        {bene?.deductionBeginDate
                                          ? moment
                                              .utc(bene.deductionBeginDate)
                                              .format("MM/DD/YYYY")
                                          : ""}
                                      </div>
                                    </div>

                                    <div className={style.benefitsRow}>
                                      <div className={style.benefitLabel}>
                                        {dictionaryLabel?.coverage}
                                      </div>
                                      <div className={style.benefitValue}>
                                        {bene?.coverage}
                                      </div>
                                    </div>

                                    <div className={style.benefitsRow}>
                                      <div className={style.benefitLabel}>
                                        <p>{dictionaryLabel?.employee_cost}</p>
                                        <p>({dictionaryLabel?.per_paycheck})</p>
                                      </div>
                                      <div className={style.benefitValue}>
                                        {selectedTab === BENEFIT.UPCOMING &&
                                          bene?.employeeCost
                                            ?.rateChangeIndicator && (
                                            <Icon
                                              style={{
                                                marginRight: "8px",
                                                marginBottom: "3px",
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              color="#A96BB6"
                                              className={style.starIcon}
                                              name="exclamation-point-octagon"
                                              size="small"
                                              variant="filled"
                                            />
                                          )}
                                        {bene.employeeCost?.amount ==
                                        NOT_APPLICABLE
                                          ? bene.employeeCost?.amount
                                          : `$${formatNumber(
                                              bene.employeeCost?.amount
                                            )}`}
                                      </div>
                                    </div>

                                    <div className={style.benefitsRow}>
                                      <div className={style.benefitLabel}>
                                        <p>
                                          {
                                            dictionaryLabel?.employer_contribution
                                          }
                                        </p>
                                        <p>({dictionaryLabel?.per_paycheck})</p>
                                      </div>
                                      <div className={style.benefitValue}>
                                        {bene.employerCost == NOT_APPLICABLE
                                          ? bene.employerCost
                                          : `$${formatNumber(
                                              bene.employerCost
                                            )}`}
                                      </div>
                                    </div>

                                    {bene?.wellnessCredit && (
                                      <div className={style.benefitsRow}>
                                        <div className={style.benefitLabel}>
                                          <div>
                                            <p>
                                              {dictionaryLabel?.wellness_credit}
                                            </p>
                                            <p>
                                              ({dictionaryLabel?.per_paycheck}){" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className={style.benefitValue}>
                                          {bene.wellnessCredit !== ""
                                            ? `$${bene.wellnessCredit}`
                                            : NOT_APPLICABLE}
                                        </div>
                                      </div>
                                    )}

                                    {bene.employerContribution && (
                                      <div className={style.benefitsRow}>
                                        <div className={style.benefitLabel}>
                                          <div>
                                            <p>
                                              {
                                                dictionaryLabel?.employer_contribution
                                              }
                                            </p>
                                            <p>
                                              {" "}
                                              ({
                                                dictionaryLabel?.per_paycheck
                                              }){" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className={style.benefitValue}>
                                          {bene?.employerContribution}
                                        </div>
                                      </div>
                                    )}

                                    {/* todo: check condition */}
                                    {bene.empCost && (
                                      <div className={style.benefitsRow}>
                                        <div className={style.benefitLabel}>
                                          <div>
                                            <p>
                                              {dictionaryLabel?.employee_cost}
                                            </p>
                                            <p>
                                              {" "}
                                              ({
                                                dictionaryLabel?.per_paycheck
                                              }){" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div className={style.benefitValue}>
                                          {selectedTab === BENEFIT.UPCOMING &&
                                            bene?.employeeCost
                                              ?.rateChangeIndicator && (
                                              <Icon
                                                style={{
                                                  marginRight: "8px",
                                                  marginBottom: "3px",
                                                  width: "20px",
                                                  height: "20px",
                                                }}
                                                color="#A96BB6"
                                                className={style.starIcon}
                                                name="exclamation-point-octagon"
                                                size="small"
                                                variant="filled"
                                              />
                                            )}
                                          {bene.employeeCost?.amount ==
                                          NOT_APPLICABLE
                                            ? bene.employeeCost?.amount
                                            : `$${formatNumber(
                                                bene.employeeCost?.amount
                                              )}`}
                                        </div>
                                      </div>
                                    )}
                                  </Fragment>
                                );
                              })
                            ) : (
                              <div className={style.loadBenefits}>
                                <Loader />
                              </div>
                            )}
                            {benefitData &&
                              benefitData.length == 0 &&
                              !loading && (
                                <div className={style.loadBenefits}>
                                  {dictionaryLabel?.no_benefits}
                                </div>
                              )}
                          </div>
                          {selectedTab == BENEFIT.UPCOMING ? (
                            <div className={style.stickyFooter}>
                              <div className={` ${style.footerTotal}`}>
                                <div className={`${style.alignLeft} `}>
                                  {`${dictionaryLabel.total_employer_contribution}`}
                                  <div>{`(${dictionaryLabel.per_paycheck})`}</div>
                                </div>
                                <div className={`${style.alignRight} `}>
                                  {totalEmployerCostUpcomingBenefit !=
                                  EMPTY_STRING
                                    ? `$${formatNumber(totalEmployerCostUpcomingBenefit)}`
                                    : EMPTY_STRING}
                                </div>
                              </div>
                              <div className={` ${style.footerTotal}`}>
                                <div className={`${style.alignLeft} `}>
                                  {`${dictionaryLabel.total_employee_cost}`}
                                  <div>{`(${dictionaryLabel.per_paycheck})`}</div>
                                </div>
                                <div className={`${style.alignRight} `}>
                                  {totalEmployeeCostUpcomingBenefit !==
                                  EMPTY_STRING
                                    ? `$${formatNumber(totalEmployeeCostUpcomingBenefit)}`
                                    : EMPTY_STRING}
                                </div>
                              </div>
                              <div className={style.stickyFooterBottomArea}>
                                <div className={style.iconContainer}>
                                  <span>
                                    <Icon
                                      color="#FFAD33"
                                      className={style.starIcon}
                                      name="star"
                                      size="small"
                                      variant="filled"
                                    ></Icon>
                                    {newPlan?.value}
                                  </span>
                                  <span>
                                    <Icon
                                      color="#A96BB6"
                                      name="exclamation-point-octagon"
                                      size="small"
                                      variant="filled"
                                    ></Icon>
                                    {rateChange.value}
                                  </span>
                                </div>
                                <div className={style.stickyFooterBtnContainer}>
                                  {(isOpenEnrollmentStarted &&
                                    IsEnrollmentPlanSubmitted &&
                                    isOpenEnrollmentCompleted) ||
                                  isOpenEnrollmentCompleted ? (
                                    <InnerHtml
                                      description={
                                        dictionaryLabel?.open_case_question_message
                                      }
                                    />
                                  ) : (
                                    <Button
                                      className={style.stickyFooterPrimaryBtn}
                                      size="large"
                                      variant="primary"
                                      onClick={() => {
                                        window.open(
                                          changeYourBenfitLink?.href,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      {changeYourBenfitLink?.title}
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className={style.stickyFooter}>
                              <div className={`${style.footerTotal}`}>
                                <div className={`${style.alignLeft} `}>
                                  {`${dictionaryLabel.total_employer_contribution}`}
                                  <div>{`(${dictionaryLabel.per_paycheck})`}</div>
                                </div>
                                <div className={`${style.alignRight} `}>
                                  {totalEmployerCostCurrentBenefit !==
                                  EMPTY_STRING
                                    ? `$${formatNumber(totalEmployerCostCurrentBenefit)}`
                                    : EMPTY_STRING}
                                </div>
                              </div>
                              <div className={`${style.footerTotal}`}>
                                <div className={`${style.alignLeft} `}>
                                  {`${dictionaryLabel.total_employee_cost}`}
                                  <div>{`(${dictionaryLabel.per_paycheck})`}</div>
                                </div>
                                <div className={`${style.alignRight} `}>
                                  {totalEmployeeCostCurrentBenefit !==
                                  EMPTY_STRING
                                    ? `$${formatNumber(totalEmployeeCostCurrentBenefit)}`
                                    : EMPTY_STRING}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <InnerHtml
                          description={
                            dictionaryLabel?.upcoming_benefits_waived_message
                          }
                          // payRateGroup == "Hourly"
                          //   ? dictionaryLabel?.not_enrolled_message_hourly
                          //   : dictionaryLabel?.not_enrolled_message_salary
                          style={style.innerHtml}
                        ></InnerHtml>
                      )}
                    </div>
                  ) : (
                    getdynamicText()
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCurrentBenefitsDialogue;
