import { useDispatch } from "react-redux";
import useFetchData from "../../hooks/useFetchData";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { updateGqlData } from "../../data/gqlDataReducer";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_PROFILE_DETAILS } from "../../features/api-wrapper/apiQueries";
import APIDowntimeError from "../../pages/ErrorHandling/APIDowntimeError";
import "../../styles/profile.css";
import Favourites from "./Favourites";
import Loader from "./Loader";
import Profile from "./Profile";
import styles from "./Profile.module.css";
import { authActions } from "../../data/authentication-slice";
import ErrorHanding from "../../pages/ErrorHandling/ErrorHanding";

export default function ProfileWrapper() {
  const dispatch = useDispatch();
  const { data: csData } = useFetchData("/profile", "page_profile");
  const { loading, error, data } = useQuery(GET_PROFILE_DETAILS, {
    client: apiMiddlewareClient,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      dispatch(updateGqlData(data));
    }
  }, [data]);

  if (loading) return <Loader />;
  if (error) {
    if (error && error.graphQLErrors && error.graphQLErrors[0]) {
      if (
        error.graphQLErrors[0]?.extensions?.code === "SERVICE_UNAVAILABLE" ||
        error.graphQLErrors[0]?.message?.indexOf("503") > -1
      ) {
        dispatch(authActions.setWorkDayDownState(true));
        return (
          <>
            <div className={`${styles.aucontainer} col-12`}>
              <APIDowntimeError service_key="Workday_service_down" />
              <Favourites data={csData} />
            </div>
          </>
        );
      }
    }
    return <ErrorHanding error={error} />;
  }

  return (
    <>
      <Profile csData={csData} apiData={data}></Profile>
    </>
  );
}
