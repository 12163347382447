import { useEffect, useState } from "react";
import CTATextLink from "../../components/dynamic/CTATextLink";
import style from "./LinkedinFeed.module.css";
import DOMPurify from 'dompurify';
const LinkedInFeedCarousalItems = (props) => {
    // const openLinkedInLink = (item) => {  // might need this if MGM wants an onClick on the LInkedIn Icon
    //     window.open(item, "_blank");
    // };

    const defaultImage = props?.data?.data?.dictionary_with_icon?.filter((img) => img.name == "feed_alternate_image") ? props.data.data.dictionary_with_icon.filter((img) => img.name == "feed_alternate_image")[0]?.url : "";
    // const apiImage = props?.item ? props.item[1] ? props.item[1] !== "" ? props.item[1].replace(/^url\(["']?/, '').replace(/["']?\)$/, '') : defaultImage : defaultImage : "";
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setBackgroundImage(props.item[1].replace(/^url\(["']?/, '').replace(/["']?\)$/, ''));
        }
        img.onerror = () => {            
            setBackgroundImage(defaultImage);
        }
        img.src = props?.item ? props.item[1]?.replace(/^url\(["']?/, '').replace(/["']?\)$/, '') : defaultImage;
    }, [props.item[1], defaultImage])
    return (
        <div className={style.carousalParent}>
            <div
                className={style.linkedInimageMob}
                style={{ background: `url(${backgroundImage})` }}
            />
            <div className={`${style.linkedIncontent}`}
            >
                <div className={style.parentText}><p className={style.linkedInText}>{props?.data?.data?.dictionaryLables?.ceo_name} </p><p className={style.linkedInSubText}>&nbsp;- {props?.item ? props.item[3] ? props.item[3] : "" : ""}</p></div>
                <p dangerouslySetInnerHTML={{
                    __html:DOMPurify.sanitize( props?.item ? props.item[0] ? props.item[0] : "" : "",{ ADD_ATTR: ['target'] })}} className={style.linkedInTextStyle}></p>
                <div className={style.buttonHolder}>
                    <CTATextLink
                        size="small"
                        type="text_link"
                        style={style.readLink}
                        csData={props?.data?.data}
                        apiData={props?.item}
                        fallBackImage={backgroundImage}
                        data={{
                            label: props?.data?.data?.links_dictionary?.filter((item) => item.name == "read_more") ? props.data.data.links_dictionary.filter((item) => item.name == "read_more")[0]?.link.title : "",
                            openIn: props?.data?.data?.links_dictionary?.filter((item) => item.name == "read_more") ? props.data.data.links_dictionary.filter((item) => item.name == "read_more")[0]?.open_in : "",
                        }}
                    />
                    <img className={style.linkedInIcon} src={props?.data?.data?.dictionary_with_icon?.filter((item) => item.name ==
                        "LinkedIn_icon_feed") ? props.data.data.dictionary_with_icon.filter((item) => item.name ==
                            "LinkedIn_icon_feed")[0]?.url : ""}
                    // onClick={() => openLinkedInLink(props.item ? props.item[2] : "")} // we might need this if LinkedIcon needs to be clicked
                    ></img>
                </div>
            </div>
        </div>
    );
};

export default LinkedInFeedCarousalItems