import styles from "./CarouselRedesign.module.css";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Carousel } from "react-responsive-carousel";

// import { CardContainer } from "@mgmresorts/mgm-ui";
import CarouselFiftyFiftyPhoto from "./CarouselFiftyFiftyPhoto";
import arrowLeft from "../../assets/images/chevron-left.svg";

const CarouselRedesign = (props) => {
  const [isToggle, setIsToggle] = useState(false);
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(deviceSize);
  const carouselTT = props.data?.enableCta;
  const oneColumnEnabled = false;
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  useEffect(() => {
    let indicatorArea = document.querySelector(".control-dots");
    let leftArrow = document.querySelector(".carourselTTArrowLeft");
    let rightArrow = document.querySelector(".carourselTTArrowRight");
    if (
      document.querySelector(".carourselTTArrowLeft") &&
      document.querySelector(".control-dots") &&
      document.querySelector(".carourselTTArrowRight")
    ) {
      indicatorArea.insertBefore(leftArrow, indicatorArea.firstChild);
      indicatorArea.appendChild(rightArrow);
    }
  }, [isToggle]);
  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    // if (deviceSize < 1224) {
    if (deviceSize <= 1399) {
      setIsMobile(true);
    } else if (deviceSize > 1400) {
      setIsMobile(false);
    }
    return () => window.removeEventListener("resize", resizeW);
  }, [deviceSize]);
  return (
    <div
      className={`${styles.carouselBlock} ${
        isToggle && styles.carouselBlockDisplayBlock
      }`}
    >
      {/* <CardContainer> */}
      <div className={`${styles.cardContainerInner} ${styles.fullWidth}`}>
        {!isToggle ? (
          <Carousel
            className={carouselTT ? styles.carouselTT : styles.carouselNoTT}
            showArrows={true}
            autoPlay={true}
            infiniteLoop={true}
            showStatus={false}
            showThumbs={false}
            interval={10000}
            // dynamicHeight={true}
            // axis={"vertical"}

            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                <div
                  className={`${
                    hasPrev ? "absolute" : "hidden"
                  } carourselTTArrowLeft`}
                  onClick={clickHandler}
                >
                  <img src={arrowLeft} />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                <div
                  className={`${hasNext ? "absolute" : "hidden"} ${
                    !carouselTT ? "as" : ""
                  } carourselTTArrowRight`}
                  onClick={clickHandler}
                >
                  <img src={arrowLeft} />
                </div>
              );
            }}
          >
            {props?.data?.children?.map((item, index) => (
              <CarouselFiftyFiftyPhoto
                //   carouselTT={carouselTT}
                isMobile={isMobile}
                oneColumnEnabled={oneColumnEnabled}
                enableText={props?.data?.text_carousel}
                key={index}
                data={item}
                setToggle={() => {
                  setIsToggle(true);
                }}
              />
            ))}
          </Carousel>
        ) : (
          <div className={styles.hideWrapper}>
            <div className="col-12">
              <div
                className="row row-h pb-1"
                style={{ alignItems: "center", textAlign: "right" }}
              >
                <h3 className={`col-8 text-left ${styles.title}`}>
                  {props?.data?.children[0]?.heading}
                </h3>
                <div className="col-4">
                  <div className={`${styles.showHolder} text-right`}>
                    <button onClick={() => setIsToggle(!isToggle)}>
                      <span className={styles.textUnderline}>
                        {globalProperties?.show}
                      </span>
                      &nbsp;<span className={styles.eyeHolder}></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}{" "}
      </div>
      {/* </CardContainer> */}
    </div>
  );
};

export default CarouselRedesign;
