import { getLocalStoreValue } from "./localStore";
import Axios from "axios";
import { windowOpen } from "./handleWindowOpen";

const base64ToBlob = (base64, type = "application/octet-stream") => {
    const binStr = atob(base64);
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
    }
    return new Blob([arr], { type: type });
};
const genetateBlob = (props) => {
    const axiosBaseURL = Axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
    });

    let token = getLocalStoreValue("token");
    let apigee_token = getLocalStoreValue("apigee_token");
    // {title:WD - Electronic W-2 Opt-In&contentType:documents}
    let splittedArr = props?.data?.url
        .split("{")
        .join("")
        .split("}")
        .join("")
        .split("&");
    console.log('splittedArr @@@', splittedArr);
    if(!splittedArr)
    {
        splittedArr = [];
    }
    let queryString = "?";
    for (let i = 0; i < splittedArr.length; i++) {
        let arr = splittedArr[i].split(":");
        queryString += `${arr[0]}=${arr[1]}`;
        if (i < splittedArr.length - 1) queryString += "&";
    }
    queryString += `&locale=${props.lang}`;
    console.log('queryString @@@', queryString);
    axiosBaseURL
        .get(`${process.env.REACT_APP_CS_ASSET_URL}${queryString}`, {
            headers: {
                Authorization: `Bearer ${apigee_token}`,
                jwt: token,
            },
        })
        .then((response) => {
            if (response?.status == 200) {
                if (response?.data) {
                    const blob = base64ToBlob(
                        response?.data,
                        response?.headers?.['content-type']
                    );
                    if (props.data.openIn == "new_window") {
                        openInNewWindow(blob);
                    }
                    else {
                        downloadNow(blob,splittedArr[0])
                    }
                    
                }
            }
        })
        .catch((error) => {
            console.error("Error: ", error);
        });
}
const openInNewWindow = (blob) => {
    const _url = URL.createObjectURL(blob);
    /*console.log('@@@@@ _url',_url);
    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
        window.location.assign(_url);
      } else {
      window.open(_url, "_blank");
      }*/
      windowOpen(_url);
    
}
const downloadNow = (blob,fileName) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
}
const downloadFile = (props) => {
    console.log('download file props @@@', props.data);
    genetateBlob(props);


}
export { downloadFile }