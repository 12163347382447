import { useEffect, useState, useRef, useMemo } from "react";
import styles from "./MgmNews.module.css";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_MGM_NEWS } from "../../features/api-wrapper/apiQueries";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/zh-cn";
import 'moment/locale/tl-ph';
import { useLocation } from "react-router";
import Loader from "../global/Loader";
import APIError from "../../pages/ErrorHandling/APIError";
import { Button, Icon } from "@mgmresorts/mgm-ui";
import { useDetectOutsideClick } from "../../pages/agentsearch/useDetectOutsideClick";
import { useDispatch } from "react-redux";
import { navigationActions } from "../../data/navigation-slice";
import { useSelector } from "react-redux";
import MgmNewsMessage from "./MgmNewsMessage";
//import { useSearchParams, useNavigationType } from "react-router-dom";
import { useNavigationType } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import ConfirmationPopup from "../global/ConfirmPrompt";

const DEFAULT_PAGE_SIZE = 9;

const MgmNews = (props) => {
  // debugger; // eslint-disable-line no-debugger
  const { state } = useLocation();
  const [openPageIndex, setOpenPageIndex] = useState(-1);
  const [newsAll, setnewsAll] = useState([]);
  const [newsData, setnewsData] = useState([]);
  const [newsLatest, setnewsLatest] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [selectedId, setSelectedId] = useState(0);
  const [dataAdded, setDataAdded] = useState(false);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const dispatch = useDispatch();
  //const [searchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const [pageNumber, setPageNumber] = useState(1);
  const [visibleCards, setVisibleCards] = useState(DEFAULT_PAGE_SIZE);
  const [paginationBlock, setpaginationBlock] = useState(true);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [selectedLinkCard, setSelectedLinkCard] = useState();

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  function windowOpen(url, name) {
    if (url) {
      if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url;
      }
      return window.open(url, name);
    }
  }

  useEffect(() => {
    if (language) {
      const lang =
        language == "zh-chs" ? "zh_cn" : language == "tl" ? "tl-ph" : language;
      moment.locale(lang);
    }
  }, [language]);

  const [getMgmnews, { loading, error, data }] = useLazyQuery(GET_MGM_NEWS, {
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
  });

  useEffect(() => {
    if (pageNumber) {
      getMgmnews({
        variables: {
          pageNumber: pageNumber,
          size: pageNumber === 1 ? DEFAULT_PAGE_SIZE : DEFAULT_PAGE_SIZE,
        },
      });
    }
  }, [pageNumber]);

  const loadMoreCards = () => {
    updateURL(-1);
    setVisibleCards((prev) => prev + DEFAULT_PAGE_SIZE);
    setPageNumber(pageNumber + 1);
  };

  const moveLeft = () => {
    let index = openPageIndex === 0 ? newsData.length - 1 : openPageIndex - 1;
    let numIndex = Number(index);
    setOpenPageIndex(numIndex);
    updateURL(numIndex, true);
  };
  const moveRight = () => {
    let index = openPageIndex === newsData.length - 1 ? 0 : openPageIndex + 1;
    let numIndex = Number(index);
    setOpenPageIndex(numIndex);
    updateURL(numIndex, true);
  };
  const loadLandingPage = () => {

    setpaginationBlock(true);
    setOpenPageIndex(-1);
    updateURL(-1);
  };
  const readMoreHandler = (index) => {
    console.log('index >>>',index);
    dispatch(navigationActions.setBreadcrumbForceEnable(true));
    let numIndex = Number(index);
    setOpenPageIndex(numIndex);
    updateURL(numIndex,true);
    //setPageNumber(numIndex);
    setpaginationBlock(false);
  };

  useEffect(() => {
    console.log('state %% ',state);
    //const messageId = searchParams.get("news");
    //if (messageId) readMoreHandler(messageId);
    if (state) {
      const messageId = state?.selectedIndex ? parseInt(state?.selectedIndex) : 0;
      console.log('state %% 1',messageId);
      if (messageId>= 0){
        console.log('state %% 2',messageId);
        readMoreHandler(messageId);
        setOpenPageIndex(messageId);
      } 
      
    }
  }, []);

  const updateURL = (id, skipNavType) => {
    setTimeout(() => {
      if (navigationType == "PUSH" || skipNavType) {
        const url = new URL(window.location.href);
        if (id == -1) {
          url.searchParams.delete("news");
          window.history.pushState(null, "", url.toString());
        } else {
          url.searchParams.set("news", id);
          window.history.pushState(null, "", url.toString());
        }
      }
    }, 100);
  };

  const openSortDD = () => {
    setIsActive(!isActive);
  };
  const setSortOption = (selection) => {
    setIsActive(false);
    setSelectedValue(selection);
    updateData(selection);
  };
  const updateData = (sortBy) => {
    console.log("arrayToSort:::", sortBy);

    /*if (sortBy == apiWidgetValues.newest) {
      let arr = [];
      let l = data.mgmNews.length;
      for (let i = 1; i < l; i++) {
        data.mgmNews[i] && arr.push(data.mgmNews[i]);
      }
      setnewsAll(arr);
    } else {
      let arr = [];
      let l = data.mgmNews.length;
      for (let i = 0; i < l - 1; i++) {
        data.mgmNews[l - i - 1] && arr.push(data.mgmNews[l - i - 1]);
      }
      setnewsAll(arr);
    }*/
  };

  useEffect(() => {
    if (data && data.mgmNews && data.mgmNews.length) {
      if (selectedValue == apiWidgetValues.newest) {
        data.mgmNews.sort(
          (a, b) => b?.newsPublishedDate - a?.newsPublishedDate
        );
      } else {
        data.mgmNews.sort(
          (a, b) => a?.newsPublishedDate - b?.newsPublishedDate
        );
      }
      if (pageNumber === 1) {
        setnewsAll(data.mgmNews.filter((d, i) => i != 0));
        setnewsData(data.mgmNews);
        setnewsLatest(data.mgmNews[0]);
      } else {
        let Arr = newsAll.concat(data.mgmNews);
        setnewsAll(Arr);
        let allNewsRecord = [newsLatest, ...Arr];
        setnewsData(allNewsRecord);
      }
    }
    if (state) {
      if (state.isExternalNews) {
        setnewsData((prev) => {
          let news = [...prev];
          news.push(state.news);
          return news;
        });
        setDataAdded(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (dataAdded) {
      //readMoreHandler(newsData.length - 1);
    }
  }, [dataAdded]);

 /* useEffect(() => {
    console.log('index >>> state',state);
    if (state) {
      setOpenPageIndex(
        state?.selectedIndex ? parseInt(state?.selectedIndex) : -1
      );
    }
  }, [state]);*/

  const getApiWidgetValues = (array) => {
    let obj = {};
    for (let i = 0; i < array.length; i++) {
      obj[array[i].name] = array[i].value;
      /*
      if (array[i].hash) obj[array[i].name + "_hash"] = array[i].hash;
      if (array[i].$?.value["data-cslp"])
        obj[array[i].name + "_hash"] = array[i].$?.value["data-cslp"];
      */
    }
    return obj;
  };

  const apiWidgetValues = useMemo(
    () => getApiWidgetValues(props.data.dictionary),
    [props.data]
  );

  useEffect(() => {
    let value =
      selectedId === 0 ? apiWidgetValues.newest : apiWidgetValues.oldest;
    setSelectedValue(value);
  }, [apiWidgetValues]);

  useEffect(() => {
    let arrayToSort = [newsLatest, ...newsAll];

    if (selectedValue == apiWidgetValues.newest) {
      arrayToSort.sort(
        (a, b) =>
          new Date(b?.newsPublishedDate) - new Date(a?.newsPublishedDate)
      );
    } else {
      arrayToSort.sort(
        (a, b) =>
          new Date(a?.newsPublishedDate) - new Date(b?.newsPublishedDate)
      );
    }
    setnewsAll(arrayToSort.filter((d, i) => i != 0));

    setnewsLatest(arrayToSort[0]);
    if (selectedValue === apiWidgetValues.newest) setSelectedId(0);
    else setSelectedId(1);
  }, [selectedValue]);

  useEffect(() => {
    if (isContinue && data) {
      windowOpen(selectedLinkCard, "_blank");
      setIsConfirmPopupOpen(false);
      setIsContinue(false);
    }
  }, [isContinue, data]);

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  return (
    <>
      <div className={styles.mgmNewsContainer}>
        {openPageIndex === -1 && newsLatest && newsAll.length > 0 && (
          <div className={styles.mgmNews}>
            <div style={{ display: "flex" }}>
              <div className={styles.mgmNewsLatest}>
                <div
                  style={{
                    background: `url("${
                      newsLatest?.newsContentType === "video"
                        ? newsLatest.videos[0].videoPreviewImageURL
                        : newsLatest.images[0].imageURL
                    }")`,
                  }}
                  className={styles.imgHolder}
                ></div>
                <div className={styles.content}>
                  <p className={styles.date}>
                    {/* {
                    globalProperties?.short_month[
                      new Date(newsLatest.newsPublishedDate).getMonth()
                    ]
                  }{" "} */}
                    {moment
                      .utc(newsLatest.newsPublishedDate)
                      .format("MMM D, YYYY")}
                  </p>
                  <h2>{newsLatest.newsTitle}</h2>
                  <div className={styles.desc}>
                    <p>{newsLatest.newsSummary}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className={styles.filterMgmNews}>
              <div
                ref={dropdownRef}
                className="menu-container"
                style={{ maxWidth: "176px" }}
              >
                <button onClick={openSortDD} className="menu-trigger">
                  <span style={{ width: "100%" }}>
                    {apiWidgetValues.sort_by}: {selectedValue}
                  </span>
                  <Icon name="chevron-down" size="small" />
                </button>
                <nav className={`menu ${isActive ? "active" : "inactive"}`}>
                  <ul>
                    <li onClick={() => setSortOption(apiWidgetValues.newest)}>
                      {apiWidgetValues.newest}
                    </li>
                    <li onClick={() => setSortOption(apiWidgetValues.oldest)}>
                      {apiWidgetValues.oldest}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className={styles.volunteerOpportunitiesContainer}>
              {newsAll &&
                newsAll.length > 0 &&
                newsAll.map((d, index) => {
                  return (
                    <div key={index} className={styles.mgmNewsDetail}>
                      <div
                        style={{
                          background: `url("${
                            d?.newsContentType === "video"
                              ? d.videos[0].videoPreviewImageURL
                              : d.images[0]?.imageURL
                          }")`,
                        }}
                        className={styles.imgHolderDetail}
                      ></div>
                      <div className={styles.content}>
                        <p className={styles.date}>
                          {d.links && d.links.length > 0 ? (
                            <div className={styles.dateandlink}>
                              <div>
                                {" "}
                                {moment
                                  .utc(d.newsPublishedDate)
                                  .format("MMM D, YYYY")}{" "}
                              </div>
                              <p>|</p>
                              <div>
                                {d.links[0] &&
                                  d.links[0]
                                    .replace(/^https?:\/\//, "")
                                    .match(/^[^/]+/)[0]}
                              </div>
                            </div>
                          ) : (
                            <div>
                              {" "}
                              {moment
                                .utc(d.newsPublishedDate)
                                .format("MMM D, YYYY")}
                            </div>
                          )}
                        </p>
                        <div className={styles.newsCardTitle}>
                          <h2>{d.newsTitle}</h2>
                        </div>
                        <div className={styles.desc}>
                          <p>{d.newsSummary}</p>
                        </div>
                      </div>
                      {d.links && d.links.length > 0 ? (
                        <div>
                          <Button
                            size="small"
                            variant="secondary"
                            className={styles.readMoreButton}
                            onClick={() => {
                              setSelectedLinkCard(d.links[0]);
                              setIsConfirmPopupOpen(true);
                            }}
                          >
                            <div className={styles.linkimage}>
                              <img
                                data-cslp={
                                  props.data?.$?.dictionaryWithIconLables
                                    ?.read_more?.url
                                }
                                src={
                                  props.data.dictionaryWithIconLables?.read_more
                                    ?.url
                                }
                              />
                            </div>
                            {
                              props.data.dictionaryWithIconLables?.read_more
                                ?.value
                            }
                            {/* {apiWidgetValues.read_more} */}
                          </Button>
                        </div>
                      ) : (
                        <div className={styles.newsCardButton}>
                          <Button
                            size="small"
                            variant="secondary"
                            className={styles.readMoreButton}
                            onClick={() => {
                              readMoreHandler(index + 1);
                            }}
                          >
                            {/* {apiWidgetValues.read_more} */}
                            {
                              props.data.dictionaryWithIconLables?.read_more
                                ?.value
                            }
                          </Button>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
            {isConfirmPopupOpen && (
              <ConfirmationPopup
                isConfirmPopupOpen={isConfirmPopupOpen}
                onContinue={setIsContinue}
                onClose={() => {
                  setIsConfirmPopupOpen(false);
                }}
              />
            )}
          </div>
        )}

        {openPageIndex != -1 && newsData.length > 0 && (
          <MgmNewsMessage
            selectedIndex={openPageIndex}
            relatedMgmNewsLabel={apiWidgetValues.related_mgm_news}
            moveLeft={moveLeft}
            moveRight={moveRight}
            loadLandingPage={loadLandingPage}
            dictionaryWithIconLables={props.data.dictionaryWithIconLables}
            data={newsData[openPageIndex]}
          />
        )}
        {paginationBlock && data?.mgmNews?.length > 0 && visibleCards && (
          <div className={styles.showMoreLabel}>
            <div>
              <div className={styles.showMorepadding}>
                {globalProperties?.showing} {newsAll?.length}
              </div>
              <Button
                variant="secondary"
                onClick={() => {
                  loadMoreCards();
                }}
              >
                {globalProperties?.load_more}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MgmNews;
