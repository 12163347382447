import styles from "./MyApp.module.css";
import { useEffect, useState } from "react";
import ConfirmationPopup from "../global/ConfirmPrompt";
import InnerHtml from "./InnerHtml";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { GET_MY_APPS } from "../../features/api-wrapper/apiQueries";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import Loader from "../global/Loader";
import APIError from "../../pages/ErrorHandling/APIError";
import HeroSubHeading from "../common/HeroSubHeading";

const MyApp = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [myAppsData, setMyAppsData] = useState();
  const [linkUrl, setLinkUrl] = useState();
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  const lang = language === undefined || language === "EN" ? "en-us" : language;
  const {
    error,
    loading,
    data: myAppsAPIData,
  } = useQuery(GET_MY_APPS, {
    variables: { locale: lang },
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
  });

  useEffect(() => {
    if (myAppsAPIData) {
      const groupedAPIData = myAppsAPIData.myApps.reduce((acc, value) => {
        if (!acc[value.subHeading]) {
          acc[value.subHeading] = [];
        }
        acc[value.subHeading].push(value);
        return acc;
      }, {});

      const res = Object.entries(groupedAPIData).map(([heading, list]) => ({
        heading,
        list,
      }));
      const selectedData = res.filter((myData) => {
        return myData.heading !== "";
      });
      setMyAppsData(selectedData);
    }
  }, [myAppsAPIData]);

  const clickHandler = (link) => {
    setLinkUrl(link);
    whiteListedDomain.some((v) => link.includes(v))
      ? window.open(link, "_blank")
      : setIsModalOpen(true);
  };

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      window.open(linkUrl, "_blank");
    }
  }, [isContinue]);

  if (loading) return <Loader />;
  if (error)
    return (
      <div style={{ marginTop: "2rem" }}>
        <APIError error={error} />
      </div>
    );

  return (
    <>
      {myAppsData &&
        myAppsData.length > 0 &&
        myAppsData.map((myApp, index) => (
          <div className={styles.myAppComp} key={index}>
            <HeroSubHeading data={{ heading: myApp.heading }} />
            <div className={styles.appListContainer}>
              {myApp.list.map((listItem, ind) => (
                <div className={styles.myAppHolder} key={ind}>
                  <a
                    className={styles.anchor}
                    onClick={() => clickHandler(listItem.link)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={styles.myApp}>
                      {listItem?.icon ? <div className={styles.iconWrapper}>
                      <div  style={{background:`url("${listItem?.icon}")`, backgroundRepeat: 'no-repeat',backgroundColor:'white', backgroundSize:'contain',backgroundPosition:'center'}} className={styles.logoHolder}>
                        
                      </div>
                      </div>:<div><img
                          data-cslp={props.data?.$?.icon}
                          src={
                            props?.data?.dictionary_with_icon?props?.data?.dictionary_with_icon[0]?.url:''
                          }
                        /></div>}
                      <div>
                        <h3 data-cslp={props.data?.$?.heading}>
                          {listItem.displayName}
                        </h3>
                        <InnerHtml
                          description={listItem.description}
                          style={styles.description}
                          hash={props.data?.$?.description}
                        />
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}
      {isModalOpen && (
        <ConfirmationPopup
          isModalOpen={isModalOpen}
          onContinue={setIsContinue}
          onClose={() => {
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default MyApp;
