import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recognitionSubmited: false,
};

const recognitionSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setRecognitionSubmited(state, action) {
      state.recognitionSubmited = action.payload;
    },
    isRecognitionSubmited(state) {
      return state.recognitionSubmited;
    },
  },
});

export const recognitionActions = recognitionSlice.actions;
export default recognitionSlice;
