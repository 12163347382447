import { CardContainer, Tab } from "@mgmresorts/mgm-ui";
import styles from "./QuickSnapshot.module.css";
import useMediaQuery from "../../../hooks/useMediaQuery";

const QuickSnapshot = (props) => {
  const { dictionaryLables } = props.data;
  const isDesktop = useMediaQuery("screen and (min-width: 1024px)");

  const tabTitles = Object.keys(dictionaryLables)
    .filter((key) => key.startsWith("tab") && key.endsWith("_title"))
    .map((key) => dictionaryLables[key]);

  return (
    <>
      <div>
        <CardContainer className={styles.cardContainer}>
          {isDesktop ? (
            <div className={styles.cardContainerInner}>
              {tabTitles.map((title, index) => (
                <div key={index} className={styles.tabHolder}>
                  <Tab
                    label={title}
                    className={styles.inactive}
                    tabIndex="-1"
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.mobilenav}>
              <select disabled={true} className={styles.dropdownDisabled}>
                <option>{dictionaryLables.tab1_title}</option>
                <option>{dictionaryLables.tab2_title}</option>
              </select>
            </div>
          )}

          <div>
            <div className={styles.comingSoonContainer}>
              {dictionaryLables && dictionaryLables.coming_soon_title}
            </div>
          </div>
          <div className={styles.springText}>
            {dictionaryLables && dictionaryLables.coming_soon_message_title}
          </div>
          <div className={styles.springDescription}>
            {dictionaryLables && dictionaryLables.coming_soon_message}
          </div>
        </CardContainer>
      </div>
    </>
  );
};

export default QuickSnapshot;
