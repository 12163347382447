import styles from "./CTAQuickAction.module.css";
import { useNavigate } from "react-router-dom";
//import ContentAuthorNote from "../../pages/ErrorHandling/ContentAuthorNote";
import { useEffect, useState } from "react";
import ConfirmationPopup from "../global/ConfirmPrompt";
import { Icon } from "@mgmresorts/mgm-ui";
import { useSelector } from "react-redux";
import { downloadFile } from "../../utils/fileDownloadPreview";
import NewHireAccesDelayModal from "../global/NewHireAccesDelayModal";
import ViewCurrentBenefitsModal from "./ViewCurrentBenefitsModal";
import { windowOpen } from "../../utils/handleWindowOpen";

const CTAQuickAction = (props) => {
  //console.log("CTAQuickAction >");
  let size = props.size ? props.size : "small";
  
  if (props.data === null) return;
  if (props.data.length === 0) return;

  return (
    <>
      {props.data && (
        <>
          {Array.isArray(props.data) &&
            props.data.length !== 0 &&
            props.data.map((item, index) => (
              <CTAQuickActionSingle
                data={item}
                key={index}
                size={size}
                {...props}
              />
            ))}
          {!Array.isArray(props.data) && (
            <CTAQuickActionSingle size={size} {...props} />
          )}
        </>
      )}
    </>
  );
};

export default CTAQuickAction;

const CTAQuickActionSingle = (props) => {
  //console.log("props>", props);
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  let url = props.data?.url;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [isNewHireModal, setNewHireModal] = useState(false);
  const [isViewCurrentBenefit, setIsViewCurrentBenefit] = useState(false);
  const isNewHire = useSelector((state) => state.gqlData?.profile?.newHire);

  //let linkVariants = ["text_link", "help_topics"];
  const navigate = useNavigate();

  const hidePopup = () => {
    setNewHireModal(false);
  };

  const hideBenefitModal = () => {
    setIsViewCurrentBenefit(false);
  };
  const openURL = (url)=>{
    /*if (navigator.userAgent.indexOf('Mac OS X') != -1) {
      window.location.assign(url);
    } else {
    window.open(url, "_blank");
    }*/
    windowOpen(url);
  }
  const clickHandler = (e, opensIn) => {
    e.preventDefault();
    if(props.data?.download == true)
    {
      downloadFile(props);
      return;
    }
    if (opensIn === "new_window") {
      if (isNewHire && url.includes("neocase")) {
        setNewHireModal(true);
      } else {
        whiteListedDomain.some((v) => url.includes(v))
          ? //window.open(url, "_blank")
         openURL(url) : setIsModalOpen(true);
      }
    } else if (opensIn === "same_window") {
      if (isNewHire && url.includes("neocase")) {
        setNewHireModal(true);
      } else {
        navigate(url);
      }
    } else if (opensIn === "modal") {
            props.openModal(props.args);
    } else if (opensIn === "modal_api_widget") {
      setIsViewCurrentBenefit(true);
    }
  };

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      openURL(url)
      //window.open(url, "_blank");
    }
  }, [isContinue]);

  return (
    <>
      {props.data?.label !== "" && (
        <>
          <div
            onClick={(e) => clickHandler(e, props.data?.openIn)}
            className={`${styles.quickAction} ${props.style} ${
              styles[props.panelSize]
            }`}
          >

          <div className={styles.leadingIcon}>
        { props.data?.leadingIconFile  ? (
                <img
                src={props.data?.leadingIconFile}
                />
              ) : props.data?.leadingIcon && 
              <Icon
              name={props.data?.leadingIcon}
              variant="outlined"
              size="large"
              role="img"
            />
            }
        </div>
            <div>
              <h4>{props.data?.label}</h4>
              {props.data?.description && <p>{props.data?.description}</p>}
            </div>

            <div className={styles.trailingIcon}>
        { props.data?.trailingIconFile  ? (
                <img
                src={props.data?.trailingIconFile}
                />

              ) :  props.data?.trailingIcon && 
              <Icon
              name={props.data?.trailingIcon}
              variant="filled"
              size="large"
              role="img"
              color="#BAA173"
            />
            }
        </div>
          </div>

          {isModalOpen && (
            <ConfirmationPopup
              isModalOpen={isModalOpen}
              onContinue={setIsContinue}
              onClose={() => {
                setIsModalOpen(false);
              }}
            />
          )}
          {isNewHireModal && <NewHireAccesDelayModal onClose={hidePopup} />}
          {isViewCurrentBenefit && (
            <ViewCurrentBenefitsModal
              onClose={hideBenefitModal}
              csData={props.data.modalApiWidget}
            />
          )}
        </>
      )}
    </>
  );
};
