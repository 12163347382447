import styles from "./ContentNotFound.module.css";

import HeroText from "../../components/common/HeroText";

const ContentNotFound = (props) => {
  const heroDummy = {
    heading: !props.error?.title ? "Content not available" : props.error?.title,
    description: props.error?.message,
  };
  return (
    <div className={styles.contentNotFoundHolder}>
      <HeroText data={heroDummy} />
    </div>
  );
};

export default ContentNotFound;
