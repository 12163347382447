import { Button } from "@mgmresorts/mgm-ui";
import useFetchData from "../../hooks/useFetchData";
import styles from "./Recognition.module.css";
import { getBadgeIcon, toTitleCase } from "./RecognizationUtility";

import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../data/authentication-slice";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { FEEDBACK_RE_GI } from "../../features/api-wrapper/apiQueries";
import APIDowntimeError from "../../pages/ErrorHandling/APIDowntimeError";
import APIError from "../../pages/ErrorHandling/APIError";
import FormModal from "../global/ui/FormModal";

const max_badge_count = 4;

const Recognition = (props) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [apiData, setapiData] = useState();
  const [badgesA, setBadgesA] = useState([]);
  const { data: badgeData } = useFetchData("", "recognition_badges");
  const dispatch = useDispatch();
  const isWorkDaydown = useSelector((state) => state.auth.workdayApiState);

  const {
    dictionary_with_icon: cs_icon,
    dictionaryLables: cs_text,
    links_dictionary: cs_link,
  } = props.data;

  const {
    error,
    data: apiDataN,
  } = useQuery(FEEDBACK_RE_GI, {
    client: apiMiddlewareClient,
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (apiDataN?.feedbackReceived?.recognitionMetrics?.length) {
      var badges = [...apiDataN.feedbackReceived.recognitionMetrics]
        .sort((a, b) => b.count - a.count)
        .slice(0, max_badge_count);

      setapiData(badges);
    }
  }, [apiDataN]);

  useEffect(() => {
    badgeData &&
      setBadgesA(
        badgeData[0]?.data?.badges?.map((d) => {
          return { ...d, icon: d?.icon?.filename };
        })
      );
    dispatch(authActions.setBadgeData(badgeData));
  }, [badgeData]);

  const openSubmitRecognitionForm = () => {
    setIsFormModalOpen(true);
  };

  return (
    <div>
      <div className={styles.recogBlock1}>
        <session style={{ width: "100%" }}>
          <div className={styles.myRecog}>
            <div className={styles.recogImp}>
              <h2>{cs_text?.left_column_heading}</h2>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: cs_text?.left_column_content,
                  }}
                />
              </p>
            </div>

            {isWorkDaydown ? (
              <div className={`${styles.error401Container}`}>
                <APIDowntimeError service_key="Workday_service_down" />
              </div>
            ) : (
              <div
                className={`${styles.boxStyling} ${styles.giveRecognizeBox}`}
              >
                <img className={styles.chatIcon} src={cs_icon[0]?.url} />
                <div className={styles.giveRBoxContentOuter}>
                  <div className={styles.giveRBoxContent}>
                    <h3>{cs_icon[0]?.value}</h3>

                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cs_text?.recognition_someone_content,
                        }}
                      />
                    </p>
                  </div>
                  {isFormModalOpen && cs_link && (
                    <FormModal
                      isFormModalOpen={isFormModalOpen}
                      heading={cs_text?.recognize_someone_form_heading}
                      formURL={cs_link[0]?.link?.href}
                      onClose={() => {
                        setIsFormModalOpen(false);
                      }}
                    />
                  )}
                  {cs_link && (
                    <Button
                      variant="primary"
                      className={styles.recogSomeoneButton}
                      onClick={openSubmitRecognitionForm}
                    >
                      {cs_link[0]?.link?.title}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </session>

        <session>
          <div className={styles.myRecogStatus}>
            <h2>{cs_text.right_column_heading}</h2>
            <div className={styles.recogTileContainer}>
              {error ? (
                <APIError error={error} />
              ) : (
                <>
                  {apiData &&
                    apiData.map((d, index) => (
                      <RecogIconTiles
                        key={index}
                        number={d.count}
                        iconData={
                          badgesA &&
                          badgesA
                            .map((badge) => badge.name == d.badgeID && badge)
                            .filter((d) => d)[0]
                        }
                      />
                    ))}
                </>
              )}
            </div>
          </div>
        </session>
      </div>
    </div>
  );
};

const RecogIconTiles = (props) => {
  const { number, iconData } = props;
  
  return (
    <div className={styles.rTiles}>
      <img src={getBadgeIcon(iconData?.icon)} className={styles.rTileIcon} />
      <p>{toTitleCase(iconData?.value)}</p>
      <p className={styles.number}>{number}</p>
    </div>
  );
};

export default Recognition;
