import styles from "./ThreeColumn.module.css";
import ChooseCta from "../../hooks/ChooseCta";
import { useMemo, useState } from "react";
import InnerHtml from "./InnerHtml";
import Modal from "../global/ui/Modal";
import { useSelector } from "react-redux";
import { Icon } from "@mgmresorts/mgm-ui";

const ThreeColumn = (props) => {
  let hashes = props.data?.$;
  //console.log("ThreeColumn>>>> ", props.data);

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  const getPackedColumns = (columns) => {
    return columns.map((col, index) => {
      if (index === 0) {
        return { ...col };
      } else {
        return {
          ...col,
          items: [
            ...col.items.map((sub) => {
              let date = new Date(sub.date);
              let yearOrHeading =
                sub.sub_heading === ""
                  ? `, ${date.getFullYear()}`
                  : `: ${sub.sub_heading}`;
              let newHeading = `${
                globalProperties?.full_month[date.getMonth()]
              } ${date.getDate()}${yearOrHeading}`;
              return { ...sub, newHeading };
            }),
          ],
        };
      }
    });
  };

  const columns = useMemo(
    () => getPackedColumns(props.data.columns),
    [props.data.columns]
  );

  const openModal = (args) => {
    let modal = columns[args[0]]?.items[args[1]].cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.threeColumn}>
      <div className={styles.blocks}>
        {columns.map((col, index) => (
          <div key={index} className={styles.block}>
            {col.iconFile ? (
              <img src={col.iconFile} />
            ) : (
              col.icon && (
                <Icon
                  className={styles.imgaeHolder}
                  name={col.icon}
                  variant="outlined"
                  size="large"
                  role="img"
                />
              )
            )}
            <h3 data-cslp={hashes?.columns[index]?.heading}>{col.heading}</h3>
            {index === 0 ? (
              <InnerHtml
                description={col.items[0]?.description}
                style={styles.innerHtml}
                hash={hashes?.columns[index]?.items[0]?.sub_heading}
              />
            ) : (
              <>
                {col.items.map((sub, subIndex) => (
                  <div key={subIndex} className={styles.subBlock}>
                    <h4
                      data-cslp={
                        hashes?.columns[index]?.items[subIndex]?.sub_heading
                      }
                    >
                      {sub.newHeading}
                    </h4>
                    <InnerHtml
                      description={sub.description}
                      style={styles.innerHtml}
                      hash={
                        hashes?.columns[index]?.items[subIndex]?.description
                      }
                    />
                    {sub?.cta && (
                      <div className={styles.buttonHolder}>
                        <ChooseCta
                          data={sub?.cta}
                          size="large"
                          type="text_link"
                          args={[index, subIndex]}
                          openModal={openModal}
                          style={styles.textLink}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        ))}
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </div>
  );
};

export default ThreeColumn;
