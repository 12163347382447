import { useEffect } from "react";
import styles from "./APIDowntimeError.module.css";
import { useState } from "react";
//import useFetchData from "../../hooks/useFetchData";
import {Icon } from "@mgmresorts/mgm-ui";
import { useSelector } from "react-redux";
import DOMPurify from 'dompurify';
const APIDowntimeError = (props) => {
  
  console.log('props.service_key>>>',props.service_key);
  //const { data: csGlobalData } = useFetchData("", "globals");
  const csGlobalData = useSelector((state) => state.globalNotification);
  const systemErrors = csGlobalData?csGlobalData.systemErrors:[];
  const [apiDowntimeData,setApiDowntimeData] = useState();

  useEffect(()=>{
    if(systemErrors)
    {
      let filterData;
      if(props.service_key)
      {
        filterData = systemErrors.filter((obj)=>{
          if(obj.type == props.service_key)
          {
              return obj;
          }
      })
      }
      else
      {
        filterData = systemErrors.filter((obj)=>{
          if(obj.type == "website_maintenance")
          {
              return obj;
          }
      })
      }
      
        if(filterData && filterData.length>0)
        {
            setApiDowntimeData(filterData[0]);
        }
        else if(systemErrors[0]){
          setApiDowntimeData(systemErrors[0]);
        }
        
    }
    
},[systemErrors])
useEffect(()=>{
    console.log('apiDowntimeData >>>>>>>>>>',apiDowntimeData);
},[apiDowntimeData])

  return (<>{!apiDowntimeData?<></>:<div className={`${styles.eholder}`}>
      
        <div
          className={styles.APIErrContainer}
        >
          <div className={`${styles.iconholder}`}>
            <Icon name="exclamation-point-triangle" size="small" />
          </div>
          <div className={styles.txtH}>
            <div className={styles.txt1}>{apiDowntimeData.heading}</div>
            <div
              className={styles.txt2}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(apiDowntimeData.description,{ ADD_ATTR: ['target'] }) }}
            ></div>
          </div>
        </div>
      
    </div>}
 </> );
};

export default APIDowntimeError;