import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import VolunteerUpcomingEvents from "../../src/pages/News/GivingVolunteering/VolunteerUpcomingEvents";
import { pageViewTracking } from "../appInsights";
import Callout from "../components/common/Callout";
import CalloutHorizontal from "../components/common/CalloutHorizontal";
import ContentCardPlain from "../components/common/ContentCardPlain";
import HeroSubHeading from "../components/common/HeroSubHeading";
import HeroText from "../components/common/HeroText";
import ActionGroupItem from "../components/common/actionGroupItem/ActionGroupItem";
import Mgmoffer from "../components/common/mgmoffer/Mgmoffer";
import Accordion from "../components/dynamic/Accordion";
import AccordionGroup from "../components/dynamic/AccordionGroup";
import ActionPanel from "../components/dynamic/ActionPanel";
import Bio from "../components/dynamic/Bio";
import CarousalBlockOneColumn from "../components/dynamic/CarousalBlockOneColumn";
// import CarouselBlock from "../components/dynamic/CarouselBlock";
import CarouselRedesign from "../components/dynamic/CarouselRedesign";
import CategoryGroupLinks from "../components/dynamic/CategoryGroupLinks";
import FiftyFiftyCard from "../components/dynamic/FiftyFiftyCard";
import FiftyFiftyPhoto from "../components/dynamic/FiftyFiftyPhoto";
import HeroBanner from "../components/dynamic/HeroBanner";
import LOAOpenRequests from "../components/dynamic/LOAOpenRequests";
import LeadershipMessagesList from "../components/dynamic/LeadershipMessagesList";
import LinkSet from "../components/dynamic/LinkSet";
import MgmNews from "../components/dynamic/MgmNews";
import MyApp from "../components/dynamic/MyApp";
import OfferCardList from "../components/dynamic/OfferCardList";
import OffersCard from "../components/dynamic/OffersCard";
import OneColumn from "../components/dynamic/OneColumn";
import PageAlert from "../components/dynamic/PageAlert";
import PromoGroup from "../components/dynamic/PromoGroup";
import QuickActions from "../components/dynamic/QuickActions";
import Quote from "../components/dynamic/Quote";
import Recognition from "../components/dynamic/Recognition";
import RecognitionReGi from "../components/dynamic/RecognitionReGi";
import SavingsPlan401K_En from "../components/dynamic/SavingsPlan401K_En";
import ThreeColumn from "../components/dynamic/ThreeColumn";
import Tile from "../components/dynamic/Tile";
import Timeline from "../components/dynamic/Timeline";
// import TrendingTopcisGroup from "../components/dynamic/TrendingTopicsGroup";
import TrendingTopicsGroupRedesign from "../components/dynamic/TrendingTopicGroupRedesign";
import TwoColumn from "../components/dynamic/TwoColumn";
import TwoColumnV2 from "../components/dynamic/TwoColumnV2";
import Cases from "../pages/Dashboard/Cases";
import Greetings from "../pages/Dashboard/Greetings";
import MgmNewsDashboard from "../pages/Dashboard/MgmNews";
import MyScheduleBoard from "../pages/Dashboard/MyScheduleBoard";
import MyTasks from "../pages/Dashboard/MyTasks";
import MyTimesheet from "../pages/Dashboard/MyTimesheet";
import UpdatesandEvents from "../pages/Dashboard/UpdatesandEvents";
import ViewEDRMenu from "../pages/Dashboard/ViewEDRMenu";
import ContentAuthorNote from "../pages/ErrorHandling/ContentAuthorNote";
import JobOpportunities from "../pages/JobOpportunities/JobOpportunitiesApi";
import LinkedInFeed from "../pages/LinkedInFeed/LinkedInFeed";
import VolunteerOpportunities from "../pages/News/GivingVolunteering/VolunteerOpportunities";
import VolunteeringMatrix from "../pages/News/GivingVolunteering/VolunteeringMatrix";
import VolunteeringMatrixFallback from "../pages/News/GivingVolunteering/VolunteeringMatrixFallback";
import Features from "../pages/News/UpdateEvents/Features";
import Occupancy from "../pages/News/UpdateEvents/Occupancy";
import UpcomingEvents from "../pages/News/UpdateEvents/UpcomingEvents";
import WeeklyUpdates from "../pages/News/UpdateEvents/WeeklyUpdates";
import ReporteeCard from "../pages/EmployeeResources/ManageMyTeam/ReporteeCard";
import ManagerDirectReports from "../pages/EmployeeResources/ManageMyTeam/ManagerDirectReports";
import DirectReportees from '../pages/EmployeeResources/ManageMyTeam/DirectReportees';
import QuickSnapshot from '../pages/EmployeeResources/ManageMyTeam/QuickSnapshot';
import ManagerTasksToDo from '../pages/EmployeeResources/ManageMyTeam/ManagerTasksToDo';

import { getLocalStoreValue } from "../utils/localStore";

const GetDynamicComponent = (props) => {
  //console.log("GetDynamicComponent > ", props);
  const componentMapping = [
    { name: "hero", component: "hero" },
    { name: "2_col", component: "twocolumn" },
    { name: "two_column", component: "twocolumn" },
    { name: "two_column_v2", component: "twocolumnV2" },
    { name: "three_column", component: "threecolumn" },
    { name: "accordion_card", component: "accordion" },
    { name: "accordion", component: "accordion" },
    { name: "accordiongroup", component: "accordiongroup" },
    { name: "50_50_card", component: "fiftyfiftycard" },
    { name: "50_50", component: "fiftyfiftycard" }, //may rename as 50_50_card
    { name: "fifty_fifty_card", component: "fiftyfiftycard" },
    { name: "50_50_Photo", component: "fiftyfiftyphoto" },
    { name: "fifty_fifty_photo", component: "fiftyfiftyphoto" },
    { name: "tiles", component: "tiles" },
    { name: "bio_card", component: "bio" },
    { name: "carousel", component: "carousel" },
    { name: "carousel_v2", component: "CarousalBlockOneColumn" },
    // { name: "trending_topics_group", component: "TrendingTopcisGroup" },
    { name: "offers_card", component: "offerscard" },
    { name: "promo_card", component: "PromoGroup" },
    { name: "one_column", component: "OneColumn" },
    { name: "one_col", component: "OneColumn" },
    { name: "timeline", component: "Timeline" },
    { name: "my_apps", component: "MyApp" },
    { name: "callout", component: "Callout" },
    { name: "content_card", component: "ContentCardPlain" },
    { name: "quick_actions", component: "QuickActions" },
    { name: "offers_list", component: "OfferCardList" },
    { name: "action_panel", component: "ActionPanel" },
    { name: "link_set", component: "LinkSet" },
    { name: "quote", component: "Quote" },
    { name: "weekly_updates", component: "WeeklyUpdates" },
    { name: "upcoming_events", component: "UpcomingEvents" },
    { name: "featured_news", component: "Features" },
    { name: "property_occupancy", component: "Occupancy" },
    { name: "leadership_messages_list", component: "LeadershipMessagesList" },
    { name: "callout_horizontal", component: "CalloutHorizontal" },
    { name: "mgm_news", component: "MgmNews" },
    { name: "greetings", component: "Greetings" },
    { name: "time_sheet", component: "MyTimesheet" },
    { name: "offers", component: "Mgmoffer" },
    { name: "dashboard_news", component: "MgmNewsDashboard" },
    { name: "property_updates", component: "UpdatesandEvents" },
    { name: "hr_it_cases", component: "Cases" },
    { name: "my_tasks", component: "MyTasks" },
    { name: "category_group_links", component: "CategoryGroupLinks" },
    { name: "in_page_alert", component: "PageAlert" },

    { name: "job_opportunities", component: "JobOpportunities" },
    { name: "action_group", component: "ActionGroupItem" },
    { name: "dashboard_my_schedule", component: "MyScheduleBoard" },
    { name: "my_recognition", component: "Recognition" },
    { name: "recognition_list", component: "RecognitionReGi" },
    { name: "edr_menu", component: "ViewEDRMenu" },
    { name: "401_k_savings_plan", component: "SavingsPlan401K_En" },
    {
      name: "volunteering_new_opportunities",
      component: "VolunteerOpportunities",
    },
    { name: "volunteered", component: "VolunteeringMatrix" },
    { name: "not_volunteered", component: "VolunteeringMatrixFallback" },
    {
      name: "volunteering_my_upcoming_events",
      component: "VolunteerUpcomingEvents",
    },
    { name: "loa_open_requests", component: "LOAOpenRequests" },
    { name: "ceo_linkedin", component: "LinkedInFeed" },
    { name: "trending_topics",  component: "TrendingTopicsGroupRedesign"}, 
    {name:"reportee_profile", component:"ReporteeCard"},   
    { name: "manager_direct_reports", component: "ManagerDirectReports" },
    { name: "reportee_direct_reports", component: "DirectReportees" },
    {name : "reportee_quick_snapshot", component: "QuickSnapshot"},
    {name: "manager_tasks_to_do", component: "ManagerTasksToDo"}

  ];

  const componentNames = {
    herobanner: HeroBanner,
    herotext: HeroText,
    herosubheading: HeroSubHeading,
    twocolumn: TwoColumn,
    twocolumnV2: TwoColumnV2,
    threecolumn: ThreeColumn,
    accordion: Accordion,
    accordiongroup: AccordionGroup,
    fiftyfiftyphoto: FiftyFiftyPhoto,
    fiftyfiftycard: FiftyFiftyCard,
    tiles: Tile,
    bio: Bio,
    carousel: CarouselRedesign,
    CarousalBlockOneColumn,
    // TrendingTopcisGroup,
    offerscard: OffersCard,
    PromoGroup,
    OneColumn,
    Timeline,
    MyApp,
    Callout,
    ContentCardPlain,
    ActionGroupItem,
    QuickActions,
    OfferCardList,
    ActionPanel,
    LinkSet,
    Quote,
    WeeklyUpdates,
    UpcomingEvents,
    Features,
    Occupancy,
    LeadershipMessagesList,
    CalloutHorizontal,
    MgmNews,
    Greetings,
    MyTimesheet,
    Mgmoffer,
    MgmNewsDashboard,
    UpdatesandEvents,
    Cases,
    MyTasks,
    CategoryGroupLinks,
    PageAlert,
    MyScheduleBoard,

    JobOpportunities,

    Recognition,
    RecognitionReGi,
    ViewEDRMenu,
    SavingsPlan401K_En,
    TwoColumnV2,
    VolunteerOpportunities,
    VolunteeringMatrix,
    VolunteeringMatrixFallback,
    VolunteerUpcomingEvents,
    LOAOpenRequests,
    LinkedInFeed,
    TrendingTopicsGroupRedesign,
    ReporteeCard,
    ManagerDirectReports,
    DirectReportees,
    QuickSnapshot,
    ManagerTasksToDo

  };

  if (props.error)
    return (
      <ContentAuthorNote
        message={`'${props.comp}' -> ${props.error?.message}`}
      />
    );

  let index = componentMapping.findIndex((item) => {
    if (props.comp === "api_widget" || props.comp === "html_widget")
      return item.name === props.type;
    else return item.name === props.comp;
  });

  if (index === -1)
    return (
      <ContentAuthorNote
        message={`'${props.comp}' -> Component not yet created in frontend!`}
      />
    );
  let token = getLocalStoreValue("token");
  let user = "";
  let userRoles = "";
  if (token) {
    user = jwtDecode(token ? token : "");
    userRoles = user?.roles?.join(",");
  }

  const gqlData = useSelector((state) => state.gqlData);
  const pageTitle = useSelector((state) => state.dom.pageAnalyticsTitle);
  const profile = gqlData?.profile;
  const profileInfo = {
    userid: profile?.employeeId,
    employeeType: profile?.payRateGroup,
    businessLocation: profile?.primaryLocation,
    role: profile?.businessTitle,
    employeeRole: userRoles,
    locale: profile?.preferredLanguage,
  };
  let componentName = componentMapping[index]?.component;

  useEffect(() => {
    const compObj = {
      name: componentName,
      pageUrl: window.location.pathname,
      parentPageTitle: pageTitle,
      componentName: componentName,
    };
    pageViewTracking(componentName, window.location.pathname, {
      ...profileInfo,
      ...compObj,
    });
  }, [componentName]);

  if (componentName === "MyApp" && props.comp !== "api_widget") return <></>;

  if (componentName === "hero") {
    if (props.data?.type === "banner") {
      componentName = "herobanner";
    } else if (props.data?.type === "text") {
      componentName = "herotext";
    } else if (props.data?.type === "sub_heading") {
      componentName = "herosubheading";
    } else {
      return (
        <ContentAuthorNote
          message={`'${props.comp}' -> The type not specified on Contentstack!`}
        />
      );
    }
  }
  if (componentName === "accordion") {
    if (props.data?.contentType === "accordion") {
      componentName = "accordion";
    } else if (props.data?.contentType === "accordion_group") {
      componentName = "accordiongroup";
    } else {
      return (
        <ContentAuthorNote
          message={`'${props.comp}' -> The type not specified on Contentstack!`}
        />
      );
    }
  }
  if (componentName === "Callout") {
    if (props.data?.contentType === "callout") {
      componentName = "Callout";
    } else if (props.data?.contentType === "callout_horizontal") {
      componentName = "CalloutHorizontal";
    } else {
      return (
        <ContentAuthorNote
          message={`'${props.comp}' -> The type not specified on Contentstack!`}
        />
      );
    }
  }
  if (!componentName)
    return (
      <ContentAuthorNote
        message={`Something went wrong! Component not derived properly!`}
      />
    );

  let Component = componentNames[componentName];

  if (!Component)
    return (
      <ContentAuthorNote
        message={`Something went wrong! Component not derived properly!`}
      />
    );
  return <Component data={props.data} />;
};

export default GetDynamicComponent;
