import styles from "./PageAlert.module.css";
import InnerHtml from "./InnerHtml";
import { Icon } from "@mgmresorts/mgm-ui";
import ChooseCta from "../../hooks/ChooseCta";
import { useState } from "react";

const PageAlert = (props) => {
  console.log("PageAlert>> ", props);
  const [isPageAlertClosed, setIsPageAlertClosed] = useState(false);

  const closeClickHandler = () => {
    localStorage.setItem("pageAlert_" + props.data?.uid, "true");
    setIsPageAlertClosed(true);
  };

  if (
    localStorage.getItem("pageAlert_" + props.data?.uid) === "true" ||
    isPageAlertClosed
  )
    return;

  return (
    <div className={styles.pageAlertHolder}>
      <div className={styles.closeButton} onClick={closeClickHandler}>
        <Icon name="symbol-x" variant="outlined" size="large" role="img" />
      </div>
      <div className={styles.iconHolder}>
        {props.data?.iconFile ? (
          <img src={props.data?.iconFile} />
        ) : (
          props.data?.icon && (
            <Icon
              name={props.data?.icon}
              variant="outlined"
              size="small"
              role="img"
            />
          )
        )}
      </div>
      <div>
        <h4>{props.data?.heading}</h4>
        <InnerHtml description={props.data?.description} />
        {props.data?.ctaEnabled && (
          <div className={styles.buttonHolder}>
            <ChooseCta data={props.data?.cta} size="small" type="text_link" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageAlert;
