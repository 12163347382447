import { useEffect, useState } from "react";
import OffersEmpCard from "./OffersEmpCard";
import { Checkbox, Button, Icon, Pagination } from "@mgmresorts/mgm-ui";
import FilterModal from "./FilterModal";
import OfferModal from "./OfferModal";
import styles from "./OfferCardList.module.css";

//const slackedLinePath = "../../../../search/stacked-line-filter.jpg";

const OfferCardList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [currentOfferData, setCurrentOfferData] = useState(null);
  const [isLimitedTimeOfferChecked, setIsLimitedTimeOfferChecked] =
    useState(false);
  const [currentData, setCurrentData] = useState([]);
  const[applyClicked,setApplyClicked]=useState(false);
  const [allData, setAllData] = useState([]); //filteredData
  const [filterRegion, setfilterRegion] = useState([]);
  const [filterCategory, setfilterCategory] = useState([]);
  const [filterCount, setfilterCount] = useState(0);
  const [visibleCards, setVisibleCards] = useState(12);
  const [offerShowingFrom, setofferShowingFrom] = useState(
    props.data.offers.length ? 1 : 0
  );  
  const load_more_label = props.data?.name_value?.filter((i)=>i.name=="load_more")?props.data.name_value.filter((i)=>i.name=="load_more")[0]?.value:"";
  const handleApplyClicked=(childData)=>{
    setApplyClicked(childData);

  }

  // const offerTotal = props.data.offers.length;

  const allCategory =
    [
      ...new Set(
        props.data?.offers
          .map((d) => d?.category && d?.category[0]?.category_name)
          .filter((d) => d)
      ),
    ] || [];

  const allRegion =
    [
      ...new Set(
        props.data?.offers
          .map((d) => d?.region && d?.region[0]?.region_name)
          .filter((d) => d)
      ),
    ] || [];

  const onApply = ({ region, category }) => {
    setfilterRegion(region);
    setfilterCategory(category);

    setfilterCount(category?.length + region?.length);
  };

  const getCsValue = (key) => {
    let text = props?.data?.name_value?.filter((d) => d.name == key) || "";
    return text && text.length && text[0]?.value ? text[0].value : "";
  };

  useEffect(() => {
    setAllData(props.data.offers);
  }, []);

  useEffect(() => {
    if (filterRegion || filterCategory) {
      var data = props.data.offers;
      var data_temp = [];
      for (let i = 0; i < data.length; i++) {
        //region
        // consider all_check, none_check, check
        let regB = false;
        if (!filterRegion.length) {
          regB = true;
        } else {
          regB =
            data[i].region &&
            data[i].region.length &&
            filterRegion.filter((d) => d == data[i]?.region[0]?.region_name)
              .length
              ? true
              : false;
        }

        //category
        let catB = false;
        if (!filterCategory.length) {
          catB = true;
        } else {
          catB =
            data[i]?.category &&
            data[i]?.category.length &&
            filterCategory.filter((d) => d == data[i].category[0].category_name)
              .length
              ? true
              : false;
        }

        //limitedTimeoffer
        // let ltoB = isLimitedTimeOfferChecked
        //   ? allData.filter((d) => d.limited_time_offer)
        //   : true;

        // if (regB && catB && ltoB) {
        if (regB && catB) {
          // if (regB && catB && ltoB) {
          data_temp.push(data[i]);
        }
      }
    }
    setAllData(data_temp);
  }, [filterRegion, filterCategory]);
  useEffect(() => {
    setVisibleCards(12)
  }, [applyClicked]);
  useEffect(() => {
    if (allData?.length && isLimitedTimeOfferChecked) {
      setCurrentData(allData.filter((d) => d?.limited_time_offer));
    } else {
      setCurrentData(allData);
    }
  }, [allData, isLimitedTimeOfferChecked]);

  useEffect(() => {
    if (currentData?.length) {
      setofferShowingFrom(currentData.length ? 1 : 0);
      // setofferShowingTo(currentData.length);
    } else {
      setofferShowingFrom(0);
      // setofferShowingTo(0);
    }
  }, [currentData]);

  let showing_of = props?.data?.name_value && getCsValue("showing_of");
  const loadMoreCards = () => {

    setVisibleCards((prev) => prev + 12);

  };
  return (
    <div className={styles.offerCardListContainer}>
      <div className={styles.filterSession}>
        <div>
          <h3>{props.data.name_value && getCsValue("current_offers")}</h3>
          <p>
            {showing_of
              .replace('<span translate="no">{variable-from}</span>', offerShowingFrom)
              .replace('<span translate="no">{variable-to}</span>', offerShowingFrom==0?0:Math.min(visibleCards, currentData?.length))
              .replace('<span translate="no">{variable-total}</span>', offerShowingFrom==0?0:currentData?.length)}
          </p>
        </div>
        <div className={styles.filterSessionRight}>
          <div className={styles.limTimeOff}>
            <Checkbox
              variant="small"
              name={"limitedTimeOfferCheckBox"}
              onChange={(e) => {
                setIsLimitedTimeOfferChecked(e.currentTarget.checked);
              }}
              checked={isLimitedTimeOfferChecked}
              testId=""
            />

            <label
              // htmlFor={index}
              className={`${styles.cLable}`}
              style={{ marginRight: "28px", marginLeft: "16px" }}
            >
              {getCsValue("limited_time_offers")}
            </label>
          </div>
          <Button
            onClick={() => {
              setShowModal(true);
              setApplyClicked(false);
            }}
            style={{ borderRadius: "100px", background:"#00012b"}}
            variant="primary"
          >
            <Icon color="#ffffff" name="stacked-lines-filter" />

            {getCsValue("filters")}
            {Boolean(filterCount) && (
              <div className={styles.circleNumber}>{filterCount}</div>
            )}
          </Button>
        </div>
      </div>

      <div className={styles.offerCardList}>
        {currentData.slice(0, visibleCards).map((d, i) => {
          return (
            <div key={i} className={styles.offersEmpCardStyle}>
              <OffersEmpCard
                data={d}
                onClick={(d) => {
                  setCurrentOfferData(d);
                }}
                name_value={props?.data?.name_value}
              />
            </div>
          );
        })}

        {showModal && (
          <FilterModal
            setModal={setShowModal}
            onApply={onApply}
            handleApplyClicked={handleApplyClicked}
            filterRegion={filterRegion}
            filterCategory={filterCategory}
            region={allRegion}
            category={allCategory}
            name_value={props?.data?.name_value}
            getCsValue={getCsValue}
          />
        )}

        {currentOfferData && (
          <OfferModal
            setModal={() => setCurrentOfferData(null)}
            data={currentOfferData}
            name_value={props?.data?.name_value}
          ></OfferModal>
        )}
      </div>
      {currentData?.length != 0 && <div className={styles.showMoreLabel}>
        <Pagination
          id="pagination"
          label={load_more_label}
          onClick={loadMoreCards}
          maxValue={currentData?.length}
          value={Math.min(visibleCards, currentData?.length)}
        />
      </div>}
    </div>
  );
};

export default OfferCardList;
