import { useEffect } from "react";

const ApiKey=()=>{
    useEffect(() => {
        const script = document.createElement("script");
        
        script.type = "text/javascript";
        script.src =
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        script.async = true;
        document.body.appendChild(script);
      
        return () => {
            document.body.removeChild(script);
        };
      }, []);
      return( <></>);

}

export default ApiKey
