import { TextLink } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentAuthorNote from "../../pages/ErrorHandling/ContentAuthorNote";
import { downloadFile } from "../../utils/fileDownloadPreview";
import ConfirmationPopup from "../global/ConfirmPrompt";
import NewHireAccesDelayModal from "../global/NewHireAccesDelayModal";
import style from "./CTATextLink.module.css";
import ViewLinkedInModal from "./ViewLinkedInModal";
import { windowOpen } from "../../utils/handleWindowOpen";

const CTATextLink = (props) => {
  let size = props.size ? props.size : "small";

  if (props.data === null) return;
  if (props.data.length === 0) return;

  return (
    <>
      {props.data && (
        <>
          {Array.isArray(props.data) &&
            props.data.length !== 0 &&
            props.data.map((item, index) => (
              <CTATextLinkSingle
                data={item}
                key={index}
                size={size}
                {...props}
              />
            ))}
          {!Array.isArray(props.data) && (
            <CTATextLinkSingle size={size} {...props} />
          )}
        </>
      )}
    </>
  );
};

export default CTATextLink;

const CTATextLinkSingle = (props) => {
  const globalNotification = useSelector((state) => state.globalNotification);
  const { whiteListedDomain } = globalNotification || [];
  let url = props.data?.url;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContinue, setIsContinue] = useState(false);
  const [isNewHireModal, setNewHireModal] = useState(false);
  const [isModalApi, setIsModalApi] = useState(false);
  const isNewHire = useSelector((state) => state.gqlData?.profile?.newHire);

  const hidePopup = () => {
    setNewHireModal(false);
  };
  const hideModalApi = () => {
    setIsModalApi(false);
  };
  let linkVariants = ["text_link"];
  const navigate = useNavigate();
  const openURL = (url) => {
    /*if (navigator.userAgent.indexOf("Mac OS X") != -1) {
      window.location.assign(url);
            
    } else {
      window.open(url, "_blank");
    }*/
    windowOpen(url);
  };
  const clickHandler = (e, opensIn) => {
    e.preventDefault();
    if (props.data?.download == true) {
      downloadFile(props);
      return;
    }
    if (opensIn === "new_window") {
      if (isNewHire && url.includes("neocase")) {
        setNewHireModal(true);
      } else {
        whiteListedDomain.some((v) => url.includes(v))
          ? //window.open(url, "_blank")
            openURL(url)
          : setIsModalOpen(true);
      }
    } else if (opensIn === "same_window") {
      if (isNewHire && url.includes("neocase")) {
        setNewHireModal(true);
      } else {
        navigate(url);
      }
    } else if (opensIn === "modal") {
      if (props.args) {
        props.openModal(props.args);
      } else {
        //#CS: need to check about arg passing.. Below condition is added for 401K
        props.openModal(props.data.modal_content);
      }
      // }
    }
    else if(opensIn=="modal_api"){
      setIsModalApi(true);
    }
  };
  useEffect(() => {
    if (isModalApi) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isModalApi]);

  useEffect(() => {
    if (isContinue) {
      setIsModalOpen(false);
      setIsContinue(false);
      //window.open(url, "_blank");
      openURL(url);
    }
  }, [isContinue]);

  return (
    <>
      {props.data?.label?.trim() !== "" && (
        <>
          {linkVariants.findIndex(
            (variant) => variant === props.type?.toLowerCase()
          ) !== -1 ? (
              <TextLink
                className={props.style}
                data-cslp={props.data?.labelHash}
                href={props.data.url}
                iconLeft={
                  props.data?.leading_icon_file
                    ? null
                    : props.data?.leadingIcon || props.iconLeft
                }
                iconRight={
                  props.data?.trailing_icon_file
                    ? null
                    : props.data?.trailingIcon || props.iconRight
                }
                variant={props.size}
                inverted={props.inverted}
                target="_blank"
                onClick={
                  (e) =>
                    clickHandler(e, props.data?.openIn || props.data?.open_in) //#CS update
                }
              >
                {props.data?.leading_icon_file ? (
                  <img
                    src={props.data?.leading_icon_file.url}
                    className={style.iconSize}
                  />
                ) : null}
                <span>{props.data.label}</span>
                {props.data?.trailing_icon_file ? (
                  <img
                    src={props.data?.trailing_icon_file.url}
                    className={style.iconSize}
                  />
                ) : null}
              </TextLink>
          ) : (
            <ContentAuthorNote
              message={`'${props.type}' -> this varient of CTA will not be supported for this component!`}
            />
          )}
          {isModalOpen && (
            <ConfirmationPopup
              isModalOpen={isModalOpen}
              onContinue={setIsContinue}
              onClose={() => {
                setIsModalOpen(false);
              }}
            />
          )}
           {isNewHireModal && <NewHireAccesDelayModal onClose={hidePopup} />}
           {isModalApi && (
            <ViewLinkedInModal
              onClose={hideModalApi}
              openModal={isModalApi}
              csData={props?.csData}
              apiData={props?.apiData}
              fallBackImage={props?.fallBackImage}
            />
          )}
        </>
      )}
    </>
  );
};
