import styles from "./FiftyFiftyCard.module.css";
import { CardContainer, Icon } from "@mgmresorts/mgm-ui";
import ChooseCta from "../../hooks/ChooseCta";
import InnerHtml from "./InnerHtml";
import { useState } from "react";
import Modal from "../global/ui/Modal";

const FiftyFiftyCard = (props) => {
  //console.log(props.data);

  let [showPopup, setShowPopup] = useState(false);
  let [selectedModal, setSelectedModal] = useState();

  const openModal = () => {
    let modal = props.data?.cta?.modalContent;
    if (modal != null && modal != undefined) {
      setSelectedModal({
        heading: modal.heading,
        body: modal.content,
      });
    } else {
      setSelectedModal({ heading: "", body: "" });
    }
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  return (
    <div className={styles.fiftyFiftyCardHolder}>
      <div className={styles.fiftyFiftyCard}>
        <CardContainer
          className={styles.cardContainer}
          style={{ borderRadius: "8px", margin: "0", padding: "0" }}
        >
          <div
            className={`${styles.cardContainerInner} ${
              props.data?.ctaEnabled ? styles.withCta : ""
            }`}
          >
            <div className={styles.iconHolder}>
            {  props.data?.iconFile  ? (
                <img
                src={props.data?.iconFile}
                />
            ) : props.data?.icon &&
              <Icon
                data-cslp={props.data?.$?.icon}
                name={props.data?.icon}
                variant="outlined"
                size="large"
                role="img"
                color="#373AE5"
              />
              }
            </div>
            <h3 data-cslp={props.data?.$?.heading}>{props.data?.heading}</h3>
            <InnerHtml
              description={props.data?.description}
              style={styles.bodyContentH}
              hash={props.data?.$?.description}
            />
            {props.data?.ctaEnabled && (
              <div className={styles.buttonHolder}>
                <ChooseCta
                  data={props.data?.cta}
                  size="small"
                  type="secondary"
                  openModal={openModal}
                />
              </div>
            )}
          </div>
        </CardContainer>
      </div>
      {showPopup && (
        <Modal onClose={onPopupClose} heading={selectedModal.heading}>
          <InnerHtml description={selectedModal.body} />
        </Modal>
      )}
    </div>
  );
};

export default FiftyFiftyCard;
