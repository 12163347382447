// import { useState } from "react";
import { Button, Checkbox, CenterModal } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import styles from "./FilterModal.module.css";

const FilterModal = (props) => {
  const regionSelected = props.filterRegion || [];
  const categorySelected = props.filterCategory || [];

  const [regionObj, setRegionObj] = useState([]);
  const [categoryObj, setcategoryObj] = useState([]);

  const closeModal = (e) => {
    console.log(e);
    // e.currentTarget.tagName.toLowerCase() == "button" &&
    props.setModal(false);
  };

  const regionChangeHandler = (index) => {
    let updatedRegionObj = regionObj.map((d, i) => {
      if (i == index) {
        d.isChecked = !d.isChecked;
      }
      return d;
    });
    setRegionObj(updatedRegionObj);
  };

  const categoryChangeHandler = (index) => {
    let updatedObj = categoryObj.map((d, i) => {
      if (i == index) {
        d.isChecked = !d.isChecked;
      }
      return d;
    });
    setcategoryObj(updatedObj);
  };

  useEffect(() => {
    //hide scrollbar
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
    //remove extra space from footer of modal
    document.getElementsByTagName("dialog")[0].style.height = "min-content";
    //set modal width
    document.getElementsByTagName("dialog")[0].style.maxWidth = "776px";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    let regionObj = props.region.sort().map((d) => {
      return { name: d, isChecked: false };
    });

    if (regionSelected.length) {
      let reg_temp;
      for (let i = 0; i < regionSelected.length; i++) {
        reg_temp = regionObj.map((f) => {
          if (f.name == regionSelected[i]) {
            f.isChecked = true;
          }
          return f;
        });
      }
      setRegionObj(reg_temp);
    } else {
      setRegionObj(regionObj);
    }

    let categoryObj = props.category.sort().map((d) => {
      return { name: d, isChecked: false };
    });

    if (categorySelected.length) {
      let cat_temp;
      for (let i = 0; i < categorySelected.length; i++) {
        cat_temp = categoryObj.map((f) => {
          if (f.name == categorySelected[i]) {
            f.isChecked = true;
          }
          return f;
        });
      }
      setcategoryObj(cat_temp);
    } else {
      setcategoryObj(categoryObj);
    }
  }, props);

  return (
    <CenterModal
      title={
        <span style={{ fontSize: "24px" }}>
          {props.getCsValue("filters")}
        </span>
      }
      open
      size="medium"
      onClose={(e) => closeModal(e)}
      className={styles.filterModal}
    >
      {Boolean(regionObj.length) && <h2>{props.getCsValue("regions")}</h2>}
      <div className={`${styles.checkboxGroup} `}>
        {regionObj.map((d, index) => (
          <div key={"reg" + index} className={d.isChecked?styles.checkbox:styles.uncheckedBox}>
            <div>
              <Checkbox
                id={"reg" + index}
                variant="small"
                name="region"
                testId=""
                onChange={() => {
                  regionChangeHandler(index);
                }}
                checked={d.isChecked}
              />
            </div>
            <label htmlFor={index} className={`${styles.checkboxLabels}`}>
              {d.name}
            </label>
          </div>
        ))}
      </div>
      <br />
      {Boolean(categoryObj.length) && <h2>{props.getCsValue("category")}</h2>}
      <div className={`${styles.checkboxGroup} `}>
        {categoryObj.map((d, index) => (
          <div key={"cat" + index} className={d.isChecked?styles.checkbox:styles.uncheckedBox}>
            <div>
              <Checkbox
                id={"cat" + index}
                variant="small"
                name={"category"}
                testId=""
                onChange={() => {
                  categoryChangeHandler(index);
                }}
                checked={d.isChecked}
              />
            </div>
            <label htmlFor={index} className={`${styles.checkboxLabels}`}>
              {d.name}
            </label>
          </div>
        ))}
      </div>

      <div className={styles.buttonGroup}>
        <Button
          className={`${styles.clearButton}`}
          onClick={() => {
            let region = regionObj.map((d) => {
              d.isChecked = d.isChecked ? false : d.isChecked;
              return d;
            });

            let category = categoryObj.map((d) => {
              d.isChecked = d.isChecked ? false : d.isChecked;
              return d;
            });

            setRegionObj(region);
            setcategoryObj(category);
          }}
          variant="primary"
        >
          {props.getCsValue("clear_all")}
        </Button>
        <Button
          className={`${styles.applyButton}`}
          onClick={() => {
            let region = regionObj
              .map((d) => d.isChecked && d.name)
              .filter((d) => d);

            let category = categoryObj
              .map((d) => d.isChecked && d.name)
              .filter((d) => d);

            props.onApply({ region, category });
            closeModal();
          }}
          variant="primary"
        >
          {props.handleApplyClicked(true)}
          {props.getCsValue("apply")}
        </Button>
      </div>
    </CenterModal>
  );
};
export default FilterModal;
