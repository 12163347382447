import { Tab } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import "../../styles/profile.css";
import styles from "./Profile.module.css";

import { useLocation } from "react-router-dom";
import Favourites from "./Favourites";
import PersonalInfo from "./PersonalInfo";
import ProfileUserInfo from "./ProfileUserInfo";

export default function Profile(props) {
  const location = useLocation();
  const isFav = location.state;
  let [selectedTab, setSelectedTab] = useState(0);
  const { dictionaryLables } =
    props.csData[2]?.data || {};

  useEffect(() => {
    if (isFav && isFav.isFavouriteTab) {
      setSelectedTab(1);
    }
  }, [isFav]);

  const handleTab = (index) => {
    setSelectedTab(index);
  };

  return (
    <>
      {props.apiData ? (
        <div className={`${styles.aucontainer} col-12`}>
          <ProfileUserInfo />
          <div>
            <div className={styles.mobilenav}>
              <select
                onChange={(event) => handleTab(parseInt(event.target.value))}
              >
                <option value="0" selected={selectedTab == 0}>
                  {dictionaryLables?.personal_info_tab}
                </option>

                <option value="1" selected={selectedTab == 1}>
                  {dictionaryLables?.favorites_tab}
                </option>
              </select>
            </div>
            <div className={styles.tabHolder}>
              <Tab
                label={dictionaryLables?.personal_info_tab}
                className={`${styles.tabItem} ${
                  selectedTab === 0 ? styles.active : ""
                }`}
                onClick={() => handleTab(0)}
              />{" "}
              <Tab
                label={dictionaryLables?.favorites_tab}
                className={`${styles.tabItem} ${
                  selectedTab === 1 ? styles.active : ""
                }`}
                onClick={() => handleTab(1)}
              />
              <Tab />
            </div>
            <>
              {selectedTab == 0 && <PersonalInfo />}
              {selectedTab == 1 && <Favourites data={props.csData} />}
            </>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
