import "moment/locale/es";
import styles from "./EmployeeCard.module.css";

const EmployeeCard = (props) => {
  const {
    businessTitle,
    id,
    indirectReportCount,
    name,
    primaryCompanyName,
    isActive,
  } = props;

  const getShortName = () => {
    if (name) {
      let modifiedName = name.includes("(")
        ? name.split(" (")[0]
        : name.includes("[")
        ? name.split(" [")[0]
        : name;
      return modifiedName
        .toString()
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    }
  };

  const getName = () => {
    if (name) {
      if (name.includes("(")) {
        const expString = name.split(" (");
        return expString[0].toString().toLowerCase();
      } else {
        return name.toLowerCase();
      }
    }
  };

  return (
    <a
      className={`${styles.directReportsContainer} ${
        isActive ? styles.goldColor : styles.greyColor
      }`}
      onClick={() => props.onClick()}
    >
      <div className={styles.reports} key={id}>
        <div className={styles.cardContainerInner}>
          <div className={styles.reportsContainer}>
            <div className={styles.directReportIcon}>
              <div className={styles.iconBackground}>{getShortName()}</div>
              {indirectReportCount > 0 && (
                <div className={styles.smallIcon}>
                  <div className={styles.iconText}>{indirectReportCount}</div>
                </div>
              )}
            </div>
            <div className={styles.employeeCardBlock}>
              <div className={styles.employeeDetails}>
                <div className={styles.employeeName}>{getName()}</div>
                <div className={styles.employeeDescp1}>{businessTitle}</div>
                <div className={styles.employeeDescp2}>
                  {primaryCompanyName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default EmployeeCard;
