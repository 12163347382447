import { useEffect } from "react";

import style from "./LinkedinFeed.module.css";
import LinkedInFeedChild from "./LinkedInFeedChild";

const LinkedInFeed = (props) => {

    useEffect(() => {
        const script = document.createElement("script");
        script.id =
            "sprinklr-embed-loader-5265-ZW1iZWQ6NjZjNTFiZTFiMTYzMjAyZGQ0MmJlZjQx";
        script.type = "text/javascript";
        script.src =
            "https://gallery.sprinklr.com/clients/5265/embeds/ZW1iZWQ6NjZjNTFiZTFiMTYzMjAyZGQ0MmJlZjQx/script.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className={style.linkedinContainer} >

            <div id="sprinklr-embed-control-5265-ZW1iZWQ6NjZjNTFiZTFiMTYzMjAyZGQ0MmJlZjQx"></div>
            <div style={{display:"none"}}id="sprinklr-embed-5265-ZW1iZWQ6NjZjNTFiZTFiMTYzMjAyZGQ0MmJlZjQx"></div>

            <LinkedInFeedChild data-testid="LinkedInFeedChild" data={props}></LinkedInFeedChild>

        </div>
    );
};

export default LinkedInFeed;