import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MANAGER_DEFAULT_PAGE_SIZE } from "../../../Const";
import Loader from "../../../components/global/Loader";
import { managerDashboardActions } from "../../../data/manager-dashboard-slice";
import APIError from "../../ErrorHandling/APIError";
import EmployeeCard from "./EmployeeCard";
import styles from "./ManagerDirectReports.module.css";
import ManagerHeader from "./ManagerHeader";
import useTeamMembers from "./useTeamMembers";

const ManagerDirectReports = (props) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();
  const [indexValue, setIndexValue] = useState({
    startIndex: 0,
    endIndex: MANAGER_DEFAULT_PAGE_SIZE,
  });
  const { loadingMembers, errorMembers, teamMembers, callTeamMemberApi } =
    useTeamMembers();

  const { loading, error, data, callManagerReportsApi } = useTeamMembers();

  const directReportsData = useSelector(
    (state) => state.managerDashboard?.reportersDashboard
  );

  useEffect(() => {
    if (!directReportsData) {
      callManagerReportsApi();
    }
  }, []);

  useEffect(() => {
    if (data && data.getDirectReports) {
      dispatch(
        managerDashboardActions.setReportersDashboard(
          data.getDirectReports.data
        )
      );
    }
  }, [data]);

  const getTeamMemebers = (id, index) => {
    if (id) {
      if (directReportsData.teams[index]?.members.length == 0) {
        callTeamMemberApi(id);
      }
      setSelectedTab(index);
    }
  };

  useEffect(() => {
    if (teamMembers && teamMembers.getTeamMembers) {
      let membersData = teamMembers.getTeamMembers.data;
      const updatedTeamMember = directReportsData.teams.map((teams, index) => {
        if (selectedTab == index) {
          return { ...teams, members: membersData };
        }
        return teams;
      });
      const updatedData = { ...directReportsData, teams: updatedTeamMember };
      dispatch(managerDashboardActions.setReportersDashboard(updatedData));
    }
  }, [teamMembers]);

  const redirectToEmployeeDetails = (empID, name) => {
    navigate("./employee-details", {
      state: {
        memberID: empID,
        managerName: name,
        teamID: directReportsData.teams[selectedTab].id,
      },
    });
  };

  if (loading) return <Loader />;
  if (error || errorMembers)
    return (
      <div className="api-error">
        <APIError error={error} />
      </div>
    );

  return (
    <div className={styles.mangerDirectReportsContainer}>
      {directReportsData && (
        <ManagerHeader
          csData={props.data}
          isManagerDashboard={true}
          data={directReportsData}
          setIndexValues={(startIndex, endIndex) => {
            setIndexValue({ startIndex: startIndex, endIndex: endIndex });
          }}
          selectedTab={(id, index, isMemberAvailable) => {
            getTeamMemebers(id, index, isMemberAvailable);
          }}
        >
          {loadingMembers ? (
            <Loader />
          ) : (
            <div className={styles.reportsContainer}>
              {selectedTab.toString() &&
              directReportsData &&
              directReportsData.teams && 
              directReportsData.teams[selectedTab] &&
              directReportsData.teams[selectedTab].members &&
              directReportsData.teams[selectedTab].members.length > 0
                ? 
                [...directReportsData.teams[selectedTab].members]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .slice(indexValue?.startIndex, indexValue?.endIndex)
                    .map((employee) => {
                      return (
                        <EmployeeCard
                          key={employee.id}
                          {...employee}
                          onClick={() => {
                            redirectToEmployeeDetails(
                              employee.id,
                              employee.name
                            );
                          }}
                          isActive={true}
                        />
                      );
                    })
                : null}
            </div>
          )}
        </ManagerHeader>
      )}
    </div>
  );
};

export default ManagerDirectReports;
