import moment from "moment";
import "moment/locale/es";
import ChooseCta from "../../../hooks/ChooseCta";
import useMediaQuery from "../../../hooks/useMediaQuery";
import styles from "./VolunteerCard.module.css";
import { useEffect, useState } from "react";

const EMPTY_STRING = "";

const  formatTimeToAMPM = (date)=> {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return hours + ':' + minutes + ' ' + ampm;
}

const getCardDetails = (props, iconLists, isSingleCard) => {
  const { date_icon, pin_location_icon, timer_icon } = iconLists;
  return (
    <>
      <div className={styles.dateTimeWrapper}>
        <div className={styles.dateBlock}>
          <div>
            <img src={date_icon?.url} />
          </div>
          {moment(props?.startDateTimeLocal).format("MM/DD/YYYY")}
          {!isSingleCard &&
          props.endDateTimeLocal &&
          moment(props?.startDateTimeLocal).format("MM/DD/YYYY") !=
            moment(props?.endDateTimeLocal).format("MM/DD/YYYY")
            ? ` - ${moment(props?.endDateTimeLocal).format("MM/DD/YYYY")}`
            : ""}
        </div>
        {isSingleCard && props.startDateTimeLocal && (
          <div className={styles.dateBlock}>
            <div>
              <img src={timer_icon?.url} />
            </div>
            {formatTimeToAMPM(new Date(props.startDateTimeLocal))}
            {/*props.startDateTimeLocal && props.startDateTimeLocal.split(" ")[1]
              ? props.startDateTimeLocal.split(" ")[1].substring(0, 5)
        : props.startDateTimeLocal.substring(0, 5)*/}
          </div>
        )}
      </div>
      <div className={styles.locationBlock}>
        <div className="mr-1">
          <img src={pin_location_icon?.url} />
        </div>
        <div className={styles.locationDetails}>
          {props.location?.address1
            ? `${props.location?.address1}, `
            : EMPTY_STRING}
          {props.location?.address2
            ? `${props.location?.address2}, `
            : EMPTY_STRING}
          {`${
            props.location?.city ? `${props.location?.city}, ` : EMPTY_STRING
          }`}
          {props.location?.state ? `${props.location?.state} ` : EMPTY_STRING}
          {props.location?.zip ? `- ${props.location?.zip}` : EMPTY_STRING}
        </div>
      </div>
      {isSingleCard && (
        <div>
          <ChooseCta
            data={{
              label: props.buttonText,
              url: props.deepLink,
              openIn: "new_window",
            }}
            size="small"
            type="secondary"
          />
        </div>
      )}
    </>
  );
};

const VolunteerCard = (props) => {
  const isDesktop = useMediaQuery("screen and (min-width: 1280px)");
  const { dummy_image } = props.iconList;
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setBackgroundImage(
        props.imageUrl.replace(/^url\(["']?/, "").replace(/["']?\)$/, "")
      );
    };
    img.onerror = () => {
      setBackgroundImage(dummy_image.url);
    };
    img.src = props.imageUrl
      ? props.imageUrl?.replace(/^url\(["']?/, "").replace(/["']?\)$/, "")
      : dummy_image.url;
  }, [props.imageUrl, dummy_image]);

  return (
    <div className={styles.volunteer}>
      <div className={styles.cardContainerInner}>
        <div
          className={`${
            props?.isSingleCard
              ? styles.singleVolunteer
              : styles.volunteerContainer
          }`}
        >
          <div
            className={`${styles.ImgWrapper} ${
              props?.isSingleCard && !isDesktop
                ? styles.singleCardImgWrap
                : null
            }`}
          >
            <img src={backgroundImage} width="100%" alt="volunteering image" />
          </div>
          <div className={styles.volunteerOppBlock}>
            <div className={styles.volunteerOppBlockTitle}>
              <h6>{props?.title}</h6>
            </div>
            {(isDesktop || props?.isSingleCard) &&
              getCardDetails(props, props.iconList, props.isSingleCard)}
          </div>
        </div>
        {!isDesktop && !props?.isSingleCard && (
          <div className={styles.volunteerOppBlock}>
            {getCardDetails(props, props.iconList, props.isSingleCard)}
          </div>
        )}
        {!props.isSingleCard && (
          <div className={styles.btnContainer}>
            <ChooseCta
              data={{
                label: props.buttonText,
                url: props.deepLink,
                openIn: "new_window",
              }}
              size="small"
              type="secondary"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VolunteerCard;
