import moment from "moment";
import { useState, useEffect } from "react";
//import useFetchData from "../../hooks/useFetchData";
import use401K from "./use401K";

import CheckmarkcircleIcon from "../../assets/checkmark-circle-icon.svg";
import DollarIcon from "../../assets/dollar-icon.svg";
import styles from "./SavingsPlan401K_En.module.css";
import Loader from "../global/Loader";
import { useSelector } from "react-redux";
import APIDowntimeError from "../../pages/ErrorHandling/APIDowntimeError";

const SavingsPlan401K_En = (props) => {
  const [showBalance, setShowBalance] = useState(false);
  const [balance, setBalance] = useState(0);
  const data_cs = props?.data;
  var apiData = use401K();
  // var error = apiData?.error;
  var data_gq = apiData?.data;

  //const { data: data_global } = useFetchData("", "globals");
  const data_global = useSelector((state) => state.globalNotification);
  const cs_global = data_global?.globalProperties;

  useEffect(() => {
    apiData && setBalance(apiData?.data?.get401KDetails?.totalBalance);
  }, [apiData]);

  return (
    <div>
      {/* {!data_gq?.get401KDetails?.isUserEnrolled && ( //forTesting */}
      {data_gq?.get401KDetails?.isUserEnrolled == undefined && <Loader />}
      {data_gq?.get401KDetails?.isUserEnrolled && (
        <div className={styles.retirementContainer}>
          <session>
            <h3>{data_cs?.dictionary[0]?.value}</h3>
            <div className={styles.rtTilesContainer}>
              <RetirementTiles
                // heading="Balance"
                heading={data_cs?.dictionary_with_icon[0].value}
                // subHeading="View Details"
                subHeadingAsLink={
                  balance !== "" && data_cs?.links_dictionary[0].link
                }
                icon={
                  data_cs && data_cs?.dictionary_with_icon[0].icon
                    ? data_cs?.dictionary_with_icon[0].icon
                    : DollarIcon
                }
              >
                <div>
                  <div
                    className={`${styles.balanceContainer} ${
                      balance === "" && styles.balanceContainerZero
                    }`}
                  >
                    {balance !== "" && (
                      <>
                        {!showBalance && (
                          <span className={styles.mainBalance}>$ XX,XXX</span>
                        )}
                        {showBalance != 0 && (
                          <span className={styles.mainBalance}>
                            {"$" +
                              Number(
                                parseFloat(balance)?.toFixed(2)
                              )?.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </span>
                        )}
                      </>
                    )}

                    {balance !== "" && (
                      <span>
                        <a
                          className={styles.textSpacing}
                          onClick={() => setShowBalance(!showBalance)}
                        >
                          {!showBalance ? cs_global?.show : cs_global?.hide}
                        </a>
                      </span>
                    )}
                  </div>

                  {balance !== "" && (
                    <p className={styles.ptext}>
                      As of {moment().format("MM/DD/YYYY")}
                    </p>
                  )}
                  {balance === "" && (
                    <span>
                      <a
                        className={styles.viewDetail}
                        href={data_cs?.links_dictionary[1]?.link?.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/* View Details */}
                        {data_cs?.links_dictionary[1]?.link?.title}
                      </a>
                    </span>
                  )}
                </div>
              </RetirementTiles>

              {data_gq?.get401KDetails.statusCode == "503" ||
              data_gq?.get401KDetails.statusCode == "400" ? (
                <div className={`${styles.error401Container}`}>
                  <APIDowntimeError service_key="Workday_service_down" />
                </div>
              ) : (
                <RetirementTiles
                  heading={data_cs?.dictionary_with_icon[1].value}
                  icon={
                    data_cs && data_cs?.dictionary_with_icon[0].icon
                      ? data_cs?.dictionary_with_icon[1].icon
                      : DollarIcon
                  }
                >
                  <ContributionTypePercent
                    data_cs={data_cs}
                    coverage401KRoth={
                      apiData?.data?.get401KDetails?.coverage401KRoth
                    }
                    coverage401K={apiData?.data?.get401KDetails?.coverage401K}
                  />
                </RetirementTiles>
              )}

              <RetirementTiles
                // heading="My Beneficiary"
                heading={data_cs?.dictionary_with_icon[2].value}
                // icon={CheckmarkcircleIcon}
                icon={
                  data_cs && data_cs?.dictionary_with_icon[2].icon
                    ? data_cs?.dictionary_with_icon[1].icon
                    : CheckmarkcircleIcon
                }
              >
                {/* <div>View Details</div> */}
                <a
                  className={styles.viewDetailBen}
                  href={data_cs?.links_dictionary[2].link.href}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data_cs?.links_dictionary[2].link.title}
                </a>
              </RetirementTiles>
            </div>
          </session>
        </div>
      )}
    </div>
  );
};

// update text

// type - both, 401k, roth
const ContributionTypePercent = ({
  coverage401KRoth,
  coverage401K,
  data_cs,
}) => {
  console.log(data_cs?.dictionary[0].value);
  return (
    <div>
      {coverage401KRoth && coverage401K && (
        <div className={styles.myContributionType}>
          <div className={styles.cTypeBox}>
            <p className={styles.cType}>
              {/* {data_cs?.dictionary[0].value} */}
              {"401K"}
            </p>
            <p className={styles.cPercent}>{coverage401K}</p>
          </div>
          <div className={styles.verticalGoldenLine}></div>
          <div className={styles.cTypeBox}>
            <p className={styles.cType}>
              {/* {data_cs?.dictionary[0].value} */}
              {"401K Roth"}
            </p>
            <p className={styles.cPercent}>{coverage401KRoth}</p>
          </div>
        </div>
      )}
      {(coverage401KRoth || coverage401K) &&
        !(coverage401KRoth && coverage401K) && (
          <div className={styles.cTypeBox2}>
            <p className={styles.cType}>
              {/* {type == "401k" && data_cs?.dictionary[0].value} */}
              {coverage401KRoth ? "401K Roth" : "401K"}
            </p>
            <p className={styles.cPercent}>
              {coverage401KRoth ? coverage401KRoth : coverage401K}
            </p>
          </div>
        )}
    </div>
  );
};

const RetirementTiles = (props) => {
  const { heading, subHeadingAsLink, icon } = props;

  return (
    <div className={styles.rtTile}>
      <div className={styles.content}>
        <img className={styles.customIconStyle} src={icon} />
        <div className={styles.rtTileHeading}>
          {heading}
          {subHeadingAsLink?.title && (
            <span>
              <a href={subHeadingAsLink?.href} target="_blank" rel="noreferrer">
                {subHeadingAsLink?.title}
              </a>
            </span>
          )}
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  );
};

export default SavingsPlan401K_En;
