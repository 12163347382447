import { useQuery } from "@apollo/client";
//import { useSelector } from "react-redux";
import Loader from "../../../components/global/Loader";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_EMPLOYEE_VOLUNTEERING_MATRIX } from "../../../features/api-wrapper/apiQueries";
//import ErrorHanding from "../../ErrorHandling/ErrorHanding";

const MatrixWrapper = (EmptyComponent) => {
  function PackedComponent(props) {
    //const gqlData = useSelector((state) => state.gqlData);
    //let employeeId = gqlData.profile ? gqlData.profile.employeeId : "";
    
    const { error, loading, data } = useQuery(
      GET_EMPLOYEE_VOLUNTEERING_MATRIX,
      {
        //variables: { employeeId: String(employeeId) },
        fetchPolicy: "no-cache",
        client: apiMiddlewareClient,
      }
    );
    if (loading) return <Loader />;
    
    //if (error) return <><div>test</div><ErrorHanding error={error} /></>;
    return <EmptyComponent data={{ ...props, ...data,...error}} />;
  }
  return PackedComponent;
};

export default MatrixWrapper;
