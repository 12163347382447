// import { useSelector } from "react-redux";
import styles from "./TrendingTopicsGroup.module.css";
import { useEffect, useState } from "react";

import TrendingTopic from "./TrendingTopic";
import { LegacyCarousel } from "@mgmresorts/mgm-ui";
// import AliceCarousel from "react-alice-carousel";

const TrendingTopicsGroupRedesign = (props) => {
  console.log("NEW PROPS", props);

  // const globalProperties = useSelector(
  //   (state) => state.globalNotification?.globalProperties
  // );
  const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(deviceSize);

  useEffect(() => {
    const resizeW = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", resizeW); // Update the width on resize
    // if (deviceSize < 1224) {
    if (deviceSize <= 899) {
      setIsMobile(true);
    } else if (deviceSize > 900) {
      setIsMobile(false);
    }
    return () => window.removeEventListener("resize", resizeW);
  }, [deviceSize]);

  console.log("mobile',", isMobile);

  return (
    <>
      <div
        // className={`${styles.cardContainerInner1} ${styles[cardContainerWidth]}`}
        className={styles.cardContainerInner}
      >
        {/* <div>
          <h3 className={`${styles.cardHeader} ${"col-8 text-left"}`}>
            {props.data.heading}
          </h3>
          

        </div> */}
        {/* <div className={styles.trendingTopicContainer}> */}
        {!isMobile ? (
          <LegacyCarousel
            header={props?.data?.heading}
            headingLevel={"h3"}
            // children={props.data?.trendingTopics}
            className={styles.trendingCarousel}
            step={"page"}
            spacing={24}
            hiddenArrows={false}
            mobile={false}
          >
            {props?.data?.trendingTopics?.map((item, index) => {
              return <TrendingTopic data={item} key={index} />;
            })}
          </LegacyCarousel>
        ) : (
          <LegacyCarousel

            header={props?.data?.heading}
            headingLevel={"h3"}
            // children={props.data?.trendingTopics}
            className={styles.trendingCarousel}
            step={"page"}
            spacing={0}
            hiddenArrows={true}
            swipeAmount={50}
            swipeEnabled
            mobile
          >
            {props.data?.trendingTopics?.map((item, index) => {
              return <TrendingTopic data={item} key={index} />;
            })}
          </LegacyCarousel>
        )}
        {/* {props.data?.trendingTopics?.map((item, index) => {
            return <TrendingTopic data={item} key={index} />;
          })} */}
        {/* </div> */}
      </div>
    </>
  );
};

export default TrendingTopicsGroupRedesign;
