import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MANAGER_DEFAULT_PAGE_SIZE } from "../../../Const";
import Loader from "../../../components/global/Loader";
import APIError from "../../ErrorHandling/APIError";
import EmployeeCard from "./EmployeeCard";
import styles from "./ManagerDirectReports.module.css";
import ManagerHeader from "./ManagerHeader";
import useTeamMembers from "./useTeamMembers";

const DirectReportees = (props) => {
  const [directReportsData, setDirectReportsData] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const { state } = useLocation();
  const [indexValue, setIndexValue] = useState({
    startIndex: 0,
    endIndex: MANAGER_DEFAULT_PAGE_SIZE,
  });
  const { loading, error, data, callManagerReportsApi } = useTeamMembers();

  const { loadingMembers, errorMembers, teamMembers, callTeamMemberApi } =
    useTeamMembers();

  useEffect(() => {
    if (state && state.memberID) {
      callManagerReportsApi(state.memberID);
    }
  }, [state]);

  useEffect(() => {
    if (data && data.getDirectReports) {
      setDirectReportsData(data.getDirectReports.data);
    }
  }, [data]);

  const getTeamMemebers = (id, index) => {
    if (id) {
      if (directReportsData.teams[index]?.members.length == 0) {
        callTeamMemberApi(id);
      }
      setSelectedTab(index);
    }
  };

  useEffect(() => {
    if (
      teamMembers &&
      teamMembers.getTeamMembers &&
      teamMembers.getTeamMembers.data
    ) {
      let membersData = teamMembers.getTeamMembers.data;
      const updatedTeamMember = directReportsData.teams.map((teams, index) => {
        if (selectedTab == index) {
          return { ...teams, members: membersData };
        }
        return teams;
      });
      const updatedData = { ...directReportsData, teams: updatedTeamMember };
      setDirectReportsData(updatedData);
    }
  }, [teamMembers]);

  if (loading) return <Loader />;
  if (error || errorMembers)
    return (
      <div className="api-error">
        <APIError error={error} />
      </div>
    );

  if (
    directReportsData &&
    directReportsData.teams &&
    directReportsData.teams.length == 0
  ) {
    return <></>;
  }

  return (
    <div className={styles.mangerDirectReportsContainer}>
      {directReportsData && (
        <ManagerHeader
          csData={props.data}
          isManagerDashboard={false}
          data={directReportsData}
          setIndexValues={(startIndex, endIndex) => {
            setIndexValue({ startIndex: startIndex, endIndex: endIndex });
          }}
          selectedTab={(id, index) => {
            getTeamMemebers(id, index);
          }}
          state={state}
        >
          {loadingMembers ? (
            <Loader />
          ) : (
            <div className={styles.reportsContainer}>
              {selectedTab.toString() &&
              directReportsData &&
              directReportsData.teams &&
              directReportsData.teams.length > 0 &&
              directReportsData.teams[selectedTab] &&
              directReportsData.teams[selectedTab].members &&
              directReportsData.teams[selectedTab].members.length > 0
                ? 
                [...directReportsData.teams[selectedTab].members]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .slice(indexValue.startIndex, indexValue.endIndex)
                    .map((employee) => {
                      return (
                        <EmployeeCard
                          key={employee.id}
                          {...employee}
                          onClick={() => {}}
                          isActive={false}
                        />
                      );
                    })
                : null}
            </div>
          )}
        </ManagerHeader>
      )}
    </div>
  );
};

export default DirectReportees;
