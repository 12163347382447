import { CardContainer } from "@mgmresorts/mgm-ui";
import styles from "./ManagerTasksToDo.module.css";

const ManagerTasksToDo = (props) => {
  const { dictionaryLables } = props.data;

  return (
    <div>
      <CardContainer
        className={styles.cardContainer}
        style={{ borderRadius: "8px", margin: "0", padding: "40px" }}>
        <div className={styles.cardContainerInner}>
          {dictionaryLables && dictionaryLables.task_to_do}
        </div>
        <div>
          <div className={styles.comingSoonContainer}>
            {dictionaryLables && dictionaryLables.coming_soon_title}
          </div>
        </div>
        <div className={styles.springText}>
          {dictionaryLables && dictionaryLables.coming_soon_message_title}
        </div>
        <div className={styles.springDescription}>
          {dictionaryLables && dictionaryLables.coming_soon_message}
        </div>
      </CardContainer>
    </div>
  );
};

export default ManagerTasksToDo;
