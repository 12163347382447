import style from "./SearchInputGlobal.module.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Loader from "../../components/global/Loader";
import Filter from "./FilterAs";
import FilterMobile from "./FilterAsMobile";
import ErrorHanding from "../ErrorHandling/ErrorHandingAgentSearch";
import { CardContainer } from "@mgmresorts/mgm-ui";
import { handleTokenExpiry } from "../../utils/error";
import { useNavigate } from "react-router-dom";
import { Button } from "@mgmresorts/mgm-ui";
import { useSelector } from "react-redux";
import InnerHtml from "../../components/dynamic/InnerHtml";
//import useFetchData from "../../hooks/useFetchData";
import { useLocation } from "react-router-dom";
import { customEventTracking } from "../../appInsights";
import { getLocalStoreValue } from "../../utils/localStore";
import { jwtDecode } from "jwt-decode";
import DOMPurify from 'dompurify';
import APIError from "../ErrorHandling/APIError";
import { Icon } from "@mgmresorts/mgm-ui";
//const slackedLinePath = "../../../../search/stacked-line-filter.jpg";

const SearchInputGlobal = (props) => {
  const csData = props.data[0].data.dictionary;
  const  { dictionaryLables } = props.data[0].data;
  const { api } = window;
  //let token =   sessionStorage.getItem("token") || process.env.REACT_APP_BEARER_TOKEN;
  let token = getLocalStoreValue("token");
  let apigee_token = sessionStorage.getItem("apigee_token");

  const [error, setError] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [assignValue, setAssignValue] = useState();
  const [searchClick, setSearchClick] = useState(true); // ()
  const [isLoading, setLoading] = useState(false);
  const [showfilter, setshowfilter] = useState(false);
  const [facetcounts, setfacetcounts] = useState({});
  const [resultdata, setResultdata] = useState([]);
  const [topresult, settopresult] = useState();
  const [numFound, setnumFound] = useState();
  const [totalNum, setTotalNum] = useState(0);
  let totalResult;
  const [totalPage, settotalPage] = useState();
  const [filterdata, setfilterData] = useState();
  const [btnState, setbtnState] = useState(true);
  const [searchterm, setsearchterm] = useState();
  let catQueryString = "";
  const [page, setPage] = useState(1);
  const [hideSuggestion, setHideSuggestion] = useState(true);

  const [spellCheck, setSpellCheck] = useState("");
  const [isSuggestionSelected, setIsSuggestionSelected] = useState();
  const [showClearBtn, setShowClearBtn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [facetcountForMob, setfacetcountForMob] = useState(null);
  const gqlData = useSelector((state) => state.gqlData);
  const [showContentLoader, setShowContentLoader] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [globalHeaderSearchText, setglobalHeaderSearchText] = useState();

  const [isSpellCheckClicked, setIsSpellCheckClicked] = useState(false);

  const employeeId = gqlData.profile ? gqlData.profile.employeeId : "";
  const profile =gqlData?.profile;
  const [userRoles, setUserRoles] = useState();

  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );
  let lang = language === undefined || language === "EN" ? "en-us" : language;
    // zh-chs tl
  const navigate = useNavigate();
  //const { data: csGlobalData } = useFetchData("", "globals");
  const csGlobalData = useSelector((state) => state.globalNotification);
  const location = useLocation();
  // const data = location.state;
  const [data, setData] = useState();

  const preview = (url) => {
    navigate(url);
  };

  const handleResize = () => {
    let nowScreenWidth = window.screen.width;
    // if (nowScreenWidth <= 425) {
    if (nowScreenWidth < 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    setData(location.state);
  });

  useEffect(() => {
    setData(location.state);
  });

  useEffect(() => {
    if (data?.trendingDataSelected) {
      setAssignValue(data.trendingDataSelected);
      setglobalHeaderSearchText(data.trendingDataSelected);
      setShowClearBtn(true);
    }
  }, [data]);

  useEffect(() => {
    if (globalHeaderSearchText) {
      doSearch();
    }
  }, [globalHeaderSearchText]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isSuggestionSelected == true) {
      doSearch();
    }
  }, [isSuggestionSelected]);

  useEffect(() => {
    if (isSpellCheckClicked) {
      //Fixed: if same word again is searched using global header after the spell check(Ex:Welcame), caused creates issue.
      setglobalHeaderSearchText(assignValue);

      setIsSpellCheckClicked(false);
      doSearch();
    }
  }, [isSpellCheckClicked]);

  useEffect(() => {
    handleTokenExpiry(error);
  }, [error]);

  useEffect(() => {
    window.addEventListener("scroll", fnScroll);
    return () => window.removeEventListener("scroll", fnScroll);
  }, [isLoading, assignValue]);

  useEffect(() => {
    if (!resultdata) {
      return;
    }
    if (topresult) {
      resultdata && resultdata.length && setnumFound(resultdata.length + 1);
    } else {
      resultdata && resultdata.length && setnumFound(resultdata.length);
    }
    console.log("numFound>>>", numFound);
  }, [resultdata]);

  useEffect(() => {
    if (page && totalPage) {
      if (page < totalPage) {
        setShowLoadMore(true);
      } else {
        setShowLoadMore(false);
      }
    }
  }, [page, totalPage]);

  const headers = {
    Authorization: `Bearer ${apigee_token}`,
    jwt: token,
    //Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  };

  let searchAheadData;
  const baseURL = process.env.REACT_APP_BASE_URL;

  //const baseURL = "https://eh-midgdleware.azurewebsites.net/api/lw-search-ahead";
  const getSearchAheadData = async (e) => {
    await axios
      .get(
        `${baseURL}api/lw-search-ahead?q=${encodeURIComponent(
          e.target.value
        )}&employeeId=${employeeId}&locale=${lang}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        try {
          let suggestName =
            lang === "en-us" ? "mgmSuggestor" : "mgmSuggestor_es";
          searchAheadData =
            response.data.suggest[suggestName][`${e.target.value}`].suggestions;
          if (searchAheadData && searchAheadData.length > 0) {
            let result = [];
            searchAheadData.forEach((ele) => {
              let filterStr = ele.term;
              let dropdata = {
                value: filterStr,
              };
              result.push(dropdata);
            });
            setSuggestions(result);
          }
        } catch (e) {
          console.log(e);
        }

        setError(null);
      })
      .catch((error) => {
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
      });
  };

  useEffect(() => {
    if (token) {
      const user = jwtDecode(token);
      setUserRoles(user.roles?.join(","));
    }
  }, [token]);
  // this function will get data when the search button is clicked
  const getSearchResultData = async (type = "search", trendingSearch = "", isCategory = false) => {
    // trendingSearch && setAssignValue(trendingSearch);
    trendingSearch && setShowClearBtn(true);
    let searchText = trendingSearch ? trendingSearch : assignValue;
    setsearchterm(searchText);
    setLoading(true);
    if(profile && userRoles && !isCategory){
     const profileInfo ={userid:profile.employeeId,employeeType:profile.payRateGroup,businessLocation:profile.primaryLocation,searchterm:searchText,searchLocation:"Search Page", searchType:type, role: profile.businessTitle, employeeRole: userRoles};
    customEventTracking("Search",profileInfo)
    }

    await axios
      .get(
        `${baseURL}api/lw-search?q=${encodeURIComponent(
          searchText
        )}&category=${encodeURIComponent(catQueryString)}&employeeId=${employeeId}&locale=${lang}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        //might needs to handle more for status 500
        // if (response.status != 500)

        settopresult(null);
        let result = response?.data;
        setLoading(false);
        if (type == "search") {
          setfacetcounts(createCategoryData(result?.facet_counts));
        }

        !result?.response?.docs?.length &&
          setSpellCheck(
            response?.data?.spellcheck?.collations &&
              response.data.spellcheck.collations[0] &&
              response.data.spellcheck.collations[0][1]?.collationQuery
          );


        if (!result?.response?.docs?.length) {
          setShowLoadMore(false);
        }

        //condition added as it was giving error for zero result or 500 response
        result?.response &&
          setResultdata(
            createResultdata(result?.response?.docs, result?.highlighting)
          );
        setnumFound(result?.response?.docs?.length);
        totalResult = result?.response?.numFound;
        setTotalNum(totalResult ? totalResult : 0);
        settotalPage(Math.ceil(totalResult / 10));

        totalResult && !isMobile && setshowfilter(true);
        setError(null);
      })
      .catch((error) => {
        setfacetcounts(null);
        setResultdata(null);
        setnumFound(0);
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
        setLoading(false);
      });
  };
  const createResultdata = (rdata, hdata, type = "search") => {
    if (rdata && rdata.length <= 0 && type == "search") {
      return [];
    }
    let newData = rdata.map((d) => {
      let id = d.id;
      if (hdata && hdata[id]) {
        d.highlight = hdata[id]["pageBody"]
          ? hdata[id]["pageBody"][0]
          : hdata[id]["pageHeading"]
          ? hdata[id]["pageHeading"][0]
          : d.pageShortDesc_t;
      } else {
        d.highlight = d.pageShortDesc_t;
      }

      if (!d["[elevated]"]) {
        return d;
      } else {
        if (type == "search") {
          settopresult(d);
        }
      }
    });
    newData = newData.filter((d) => {
      if (d) {
        return d;
      }
    });

    return newData;
  };
  const createCategoryData = (facetcounts) => {
    if (facetcounts?.length <= 0) {
      return [];
    }
    let fData =
      facetcounts?.facet_fields && facetcounts?.facet_fields?.pageCategory_t
        ? facetcounts?.facet_fields?.pageCategory_t
        : [];
    let newData = [];
    fData.map((d) => {
      let obj = {};
      obj.name = d[0];
      obj.checked = false;
      if (obj.name != "") {
        newData.push(obj);
      }
    });

    return newData;
  };

  const delay = 300;
  let timer;
  const onChangeMethod = (e) => {
    // if (e.target.value == "") {
    //   setShowClearBtn(false); //assign should not be blank - to do
    // }

    setAssignValue(e.target.value);
    setShowClearBtn(true);

    if (e.target.value.length >= 3) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getSearchAheadData(e);
      }, delay);
      setHideSuggestion(false);
    } else {
      setSuggestions([]);
    }
  };

  const handleClick = (e) => {
    let filterStr = e.currentTarget.innerHTML.replace(/(<([^>]+)>)/gi, "");
    filterStr = filterStr.replace("&amp;", "&");
    setAssignValue(filterStr);
    setShowClearBtn(true); //assign should not be blank - to do

    setSuggestions([]);
    setIsSuggestionSelected(true);
  };
  const doSearch = () => {
    setIsSuggestionSelected(false);
    if (!assignValue) {
      return;
    } else if (assignValue.trim() == "") {
      return;
    }
    if (searchClick != true) {
      setSearchClick(true);
      doResize();
    }
    setPage(1);
    setbtnState(true);
    setSuggestions([]);
    catQueryString = "";
    setHideSuggestion(true);
    getSearchResultData();
  };
  const doResize = () => {
    try {
      api.send("resizeWindowtoSR", "resizeWindowtoSR");
    } catch (e) {
      //console.log("error occured");
    }
  };
  const clearfilter = () => {
    setPage(1);
    catQueryString = "";
    getSearchResultData("filter");
    setfilterData(null);
    setbtnState(true);
  };
  const filterResult = (data, isCategory) => {
    let QueryString = "";
    if (data) {
      data.map((f, index) => {
        if (f.checked == true) {
          QueryString += f.name;
          if (index != data.length - 1) {
            QueryString += ":";
          }
        }
      });
    }

    console.log("QueryString >>>", QueryString);
    setPage(1);
    setfilterData(data);
    catQueryString = QueryString;
    getSearchResultData("filter", "", isCategory);
    setbtnState(false);
  };

  // the functionalities for infinite scroll
  const getUniqueCat = (cat) => {
    let uniqueObject = {};
    let newArray = [];
    for (let i in cat) {
      // Extract the name
      let objName = cat[i]["name"];

      // Use the name as the index
      uniqueObject[objName] = cat[i];
    }

    // Loop to push unique object into array
    for (let i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    return newArray;
  };
  const fetchNxtdata = async () => {
    setError(null);
    let QueryString = "";
    if (filterdata) {
      filterdata.map((f, index) => {
        if (f.checked == true) {
          QueryString += f.name;
          if (index != filterdata.length - 1) {
            QueryString += ":";
          }
        }
      });
    }
    await axios
      .get(
        `${baseURL}api/lw-search?q=${encodeURIComponent(
          assignValue
        )}&category=${encodeURIComponent(QueryString)}&start=${
          page * 10
        }&employeeId=${employeeId}&locale=${lang}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setShowContentLoader(false);
        let result = response.data;
        setResultdata((prevResultdata) => [
          ...prevResultdata,
          ...createResultdata(
            result.response.docs,
            result.highlighting,
            "scroll"
          ),
        ]);
        setfacetcounts((prevVal) => [
          ...prevVal,
          ...createCategoryData(result.facet_counts),
        ]);
        setPage((prevPage) => prevPage + 1);

        console.log("filterdata>>>", filterdata);

        let filterCat = getUniqueCat(facetcounts);

        setfacetcounts(filterCat);
        console.log("facetcounts>>>", facetcounts);
      })
      .catch((error) => {
        setError({
          title: error.response?.data ? error.response?.data : error.message,
          message: error.message ? error.message : error.response?.data,
          status: error.response?.status ? error.response?.status : null,
        });
        setLoading(false);
      });
  };

  const fnScroll = () => {
    if (!assignValue) {
      return;
    } else if (assignValue.trim() == "") {
      return;
    }

    if (page >= totalPage) {
      return;
    }
    /* for browser */
    if (
      Math.floor(window.innerHeight) +
        Math.floor(document.documentElement.scrollTop) +
        1 <
        Math.floor(document.documentElement.offsetHeight) ||
      isLoading
    ) {
      return;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error?<><div><APIError error={error}/></div></>:(
        <div
          className={`${style.inputcomph}
           ${!showfilter && style.inputcomph_woFilter} `}
        >
          {isMobile && showfilter && (
            <FilterMobile
              facetcounts={facetcounts}
              filterResult={filterResult}
              clearfilter={clearfilter}
              btnState={btnState}
              setbtnState={setbtnState}
              setfacetcounts={setfacetcounts}
              setfacetcountForMob={setfacetcountForMob}
              csData={csData}
              onClose={() => setshowfilter(false)}
            />
          )}

          {!isMobile && showfilter && (
            <div className={style.filterH} style={{ paddingTop: "36px" }}>
              <Filter
                facetcounts={facetcounts}
                filterResult={filterResult}
                clearfilter={clearfilter}
                btnState={btnState}
                setbtnState={setbtnState}
                setfacetcounts={setfacetcounts}
                csData={csData}
                profile={profile}
              ></Filter>
            </div>
          )}

          <div
            className={
              !isMobile && showfilter ? style.inputHMainless : style.inputHMain
            }
          >
            <div style={{ maxWidth: "760px" }}>
              <div
                className={
                  showfilter ? style.inputholderless : style.inputholder
                }
              >
                <div className={style.icon}></div>
                <div className={style.inputh}>
                  <input
                    type="text"
                    value={assignValue}
                    className={style.input}
                    placeholder={
                      csData?.filter(
                        (d) => d.name?.trim() == "search_placeholder"
                      )[0]?.value
                    }
                    onChange={(e) => {
                      onChangeMethod(e);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setSuggestions([]);
                        doSearch();
                      }
                    }}
                  />
                  {showClearBtn && (
                    <div
                      className={style.clearIcon}
                      onClick={() => {
                        //reset all
                        setfacetcountForMob(null);
                        setAssignValue("");
                        setSearchClick(false);
                        setshowfilter(false);
                        setResultdata([]);
                        setTotalNum(0);
                        settopresult(null);
                        setSuggestions([]);
                        setShowClearBtn(false);
                        setShowContentLoader(false);
                        setPage(1);
                        settotalPage(0);
                        setShowLoadMore(false);
                        setfilterData([]);
                      }}
                    ></div>
                  )}
                </div>
                <button
                  type="button"
                  disabled={!assignValue ? true : false}
                  className={`${style.buttonSearch}`}
                  onClick={doSearch}
                >
                  {csData?.filter((d) => d.name == "search")[0].value}
                </button>
              </div>
            </div>
            {suggestions.length > 0 && !hideSuggestion && language != 'tl' && language != 'zh-chs' ? (
              <div className={style.suggestionH}>
                {suggestions.map((obj, index) => {
                  return (
                    <div
                      key={index}
                      className={style.drop}
                      onClick={(e) => handleClick(e)}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(obj.value,{ ADD_ATTR: ['target'] }),
                      }}
                    ></div>
                  );
                })}
              </div>
            ) : (
              <></>
            )}
            {error ? <ErrorHanding error={error}></ErrorHanding> : <></>}

            {/* show my new 0 Result - UI */}
            {/* {showfilter && ( */}
            <div className={`${!showfilter && style.maxWidthForSearchPage}`}>
              {/* remove flex and baseline align from resultHC */}
              {/* {showfilter && ( */}
              {searchClick && (
                <>
                  <div className={style.resultHCBorder}>
                    <div
                      className={style.resultHC}
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        
                      }}
                    >
                      {/* show 0Result && n Results */}
                      <div className={style.st}>
                        &quot;{searchterm}&quot;&nbsp;&nbsp;
                      </div>
                      <div className={style.num}>
                        {totalNum}{" "}
                        {totalNum > 1
                          ? csData.filter((d) => d.name == "results")[0]?.value
                          : csData.filter((d) => d.name == "result label")[0]
                              ?.value}
                      </div>
                    </div>
                    
                    {isMobile && totalNum != 0 && (
                      <div className={style.buttonGroup}>
                        <Button
                          className={style.filterButton}
                          onClick={() => setshowfilter(true)}
                          variant="primary"
                        >
                          <Icon color="white" name="stacked-lines-filter" size="small"/>
                          {/*<img
                            src={slackedLinePath}
                            width="20px"
                            height="20px"
                    />*/}
                          <span style={{ margin: "0 8px" }}>
                            {
                              csData.filter(
                                (d) => d.name?.toLowerCase() == "filter results"
                              )[0]?.value
                            }
                          </span>
                          {Boolean(facetcountForMob) && (
                            <div className={style.circleNumber}>
                              {facetcountForMob}
                            </div>
                          )}
                        </Button>
                      </div>
                    )}

                    {/* show spellCheck | 0 result | from api*/}
                    {!totalNum && spellCheck && (
                      <div>
                        <p>
                           {dictionaryLables.did_you_mean}&nbsp;
                          <span
                            style={{ cursor: "pointer", color: "#373AE5" }}
                            onClick={() => {
                              setIsSpellCheckClicked(true);
                              setAssignValue(spellCheck);
                              setShowClearBtn(true);
                            }}
                          >
                            {spellCheck}
                          </span>
                          ?
                        </p>
                      </div>
                    )}
                  </div>

                  {/* Tips - on 0Result */}
                  {!totalNum && (
                    <div
                      style={{
                        color: "#00012B",
                        margin: "40px auto",
                      }}
                    >
                      <InnerHtml
                        description={csData[1].html_value}
                        style={style.innerHtml}
                        // hash={props.data?.$?.description}
                      />
                    </div>
                  )}
                </>
              )}

              {/* on load - trending search but not in all result*/}

              {/* {!totalNum && trendingSearchData && (
                <div className={style.trendingSearchContainer}>
                  <h3
                    style={{
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    {csData[2].value}
                  </h3>

                  <div className={style.itemHolder}>
                    {trendingSearchData.map((d, i) => {
                      return (
                        <div
                          key={`trending_${i}`}
                          className={style.iconmGlass}
                          name={d}
                          onClick={(e) => {
                            getSearchResultData("search", e.target.innerHTML);
                            setSearchClick(true);
                          }}
                        >
                          {d}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )} */}

              {/* not on 0 Result */}
              {topresult && (
                <CardContainer
                  className={style.con}
                  style={{
                    padding: "24px",
                    marginTop: "40px",
                    marginBottom: "16px",
                    height: "auto",
                    maxWidth: "760px",
                  }}
                >
                  <div className={style.topResult}>Top result</div>
                  <div className={style.resultitemtop}>
                    {topresult.pageTemplate_s == "documents" ? (
                      <>
                        <div className={style.titleDoc}>
                          {topresult.documentName_t}
                        </div>
                        <div className={style.highlight}>
                          {topresult.pageBody}
                        </div>
                        <InnerHtml
                          description={`<a href="{title:${topresult.pageTitle_t}&contentType:${topresult.pageTemplate_s}}" target="_blank" oncontextmenu="return false">${dictionaryLables?.documents_link_title}</a>`}
                        />
                      </>
                    ) : (
                      <>
                        <div
                          className={style.cat}
                          onClick={() => preview(topresult.pageURL_s)}
                        >
                          {topresult.pageCategory_t}
                        </div>
                        <div
                          className={style.title}
                          onClick={() => preview(topresult.pageURL_s)}
                        >
                          {topresult.pageTitle_t}
                        </div>
                        {topresult.highlight && (
                          <div
                            className={style.highlight}
                            dangerouslySetInnerHTML={{
                              __html: `...${topresult.highlight}...`,
                            }}
                          ></div>
                        )}
                        <a
                          className={style.url}
                          href={`${topresult.pageURL_s}`}
                        >
                          {topresult.pageURL_s}
                        </a>
                      </>
                    )}
                    {/*<a className={style.url} href={`${topresult.pageURL_s}`} >{topresult.pageURL_s}</a>*/}
                    {/*<div
                      className={style.url}
                      onClick={() => preview(topresult.pageURL_s)}
                    >
                      {topresult.pageURL_s}
                      </div>*/}
                  </div>
                </CardContainer>
              )}

              {/* not on 0Result */}
              {resultdata && resultdata.length > 0 ? (
                resultdata.map((d, index) => (
                  <div
                    key={index}
                    className={style.resultitem}
                    style={{ maxWidth: "760px" }}
                  >
                    {d.pageTemplate_s == "documents" ? (
                      <>
                        <div className={style.titleDoc}>{d.documentName_t}</div>
                        <div className={style.highlight}>{d.pageBody}</div>
                        <InnerHtml
                          description={`<a href="{title:${d.pageTitle_t}&contentType:${d.pageTemplate_s}}" target="_blank"  oncontextmenu="return false">${dictionaryLables?.documents_link_title}</a>`}
                        />
                      </>
                    ) : (
                      <>
                        <div
                          className={style.cat}
                          onClick={() => preview(d.pageURL_s)}
                        >
                          {d.pageCategory_t}
                        </div>
                        <div
                          className={style.title}
                          onClick={() => preview(d.pageURL_s)}
                        >
                          {d.pageTitle_t}
                        </div>
                        {d.highlight && (
                          <div
                            className={style.highlight}
                            dangerouslySetInnerHTML={{
                              __html: `...${d.highlight}...`,
                            }}
                          ></div>
                        )}
                        <a className={style.url} href={`${d.pageURL_s}`}>
                          {d.pageURL_s}
                        </a>
                      </>
                    )}
                    {/*<a className={style.url} href={`${d.pageURL_s}`}>{d.pageURL_s}</a>*/}
                    {/*<div
                      className={style.url}
                      onClick={() => preview(d.pageURL_s)}
                    >
                      {d.pageURL_s}
                      </div>*/}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>

            <div className={style.contentLoaderContainer}>
              {showLoadMore && (
                <Button
                  label={csGlobalData?.globalProperties.load_more}
                  size={isMobile ? "small" : "large"}
                  variant="primary"
                  onClick={() => {
                    setShowLoadMore(false);
                    setShowContentLoader(true);
                    fetchNxtdata();
                  }}
                />
              )}
              {showContentLoader && <div className={style.contentLoader}></div>}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchInputGlobal;