//
import { AppConfigurationClient } from "@azure/app-configuration"
import { useMemo, useState } from "react";
 
//the above key id moved to env files
const client = new AppConfigurationClient(process.env.REACT_APP_AZURE_APP_CONFIG)
//TODO: Add Connection String copied from Access Keys Step 

function useAzureAppConfig (flagKey) {


 const [visibility, setVisibility] = useState(false);


 useMemo(async () => {


  if (!flagKey || !flagKey.toString().trim().length) {
   console.error('A flag key is not correct.');
 } else {
   try {
     const result = await client.getConfigurationSetting({
       key: `.appconfig.featureflag/${flagKey.toString().trim()}`,
     });
     if (result && typeof result === 'object') {
        if(result.value)
        {
         setVisibility(JSON.parse(result.value).enabled);
        }
     }
   } catch (error) {
     console.error(error);
   }
 }
}, [flagKey]);


 return visibility;
}


export default useAzureAppConfig;