import { useLazyQuery } from "@apollo/client";
import { apiMiddlewareClient } from "../../../features/api-wrapper/apiMiddlewareWrapper";
import {
  GET_DIRECT_REPORTS,
  GET_TEAM_MEMBERS,
} from "../../../features/api-wrapper/apiQueries";

const useTeamMembers = () => {
  const [
    getMemebers,
    { loading: loadingMembers, error: errorMembers, data: teamMembers },
  ] = useLazyQuery(GET_TEAM_MEMBERS, {
    enabled: false,
    manual: true,
    fetchPolicy: "no-cache",
    client: apiMiddlewareClient,
  });

  const [getManagerDirectReports, { loading, error, data }] = useLazyQuery(
    GET_DIRECT_REPORTS,
    {
      enabled: false,
      manual: true,
      fetchPolicy: "no-cache",
      client: apiMiddlewareClient,
    }
  );

  const callTeamMemberApi = (id) => {
    if (id) {
      getMemebers({ variables: { teamId: id } });
    }
  };

  const callManagerReportsApi = (memberId) => {
    getManagerDirectReports({
      variables: { memberId: memberId ? memberId : "" },
    });
  };

  return {
    loading,
    error,
    data,
    loadingMembers,
    errorMembers,
    teamMembers,
    callTeamMemberApi,
    callManagerReportsApi,
  };
};

export default useTeamMembers;
