import { createSlice, current } from "@reduxjs/toolkit";



const initialState = {
  currentUrl: "/",
  rawResponse: [],
  csViewAll: false,
  breadcrumbVisibility: true,
  breadcrumbForceEnable: false,
  selectedNavigation: [],
  selectedRoutes: [],
  navigationList: [],
  //routesList: [],
  selectedSecondaryList: [],
  selectedTertiaryList: [],
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setCurrentUrl(state, action) {
      state.currentUrl = action.payload;
    },
    setBreadcrumbVisibility(state, action) {
      state.breadcrumbVisibility = action.payload;
    },
    setRawResponse(state, action) {
      state.rawResponse = action.payload?.csData;
      state.csViewAll = action.payload?.csViewAll || false;
    },
    setInitialValues(state, action) {
      
      state.navigationList = [...action.payload];
    },
    /*
    setInitialRoutes(state, action) {
      let l1Routes = [
        ...action.payload.map((item) => {
          return { link: item.link, absoluteLink: item.absoluteLink };
        }),
      ];
      let l2Routes = [
        ...action.payload
          .filter((item) => item.children.length !== 0)
          .map((item) =>
            item.children.map((item) => {
              return { link: item.link, absoluteLink: item.absoluteLink };
            })
          )
          .flat(2),
      ];
      let l3Routes = [
        ...action.payload
          .filter((item) => item.children.length !== 0)
          .map((item) =>
            item.children
              .filter((item) => item.children.length !== 0)
              .map((item) =>
                item.children.map((item) => {
                  return { link: item.link, absoluteLink: item.absoluteLink };
                })
              )
          )
          .flat(2),
      ];
      state.routesList = [...l1Routes, ...l2Routes, ...l3Routes];
      console.log(state.routesList);
    },
    */
    setSelectedNavigation(state, action) {
      
      state.selectedNavigation = [];
      let links = action.payload?.path
        .split("/")
        .filter((nav) => nav !== "")
        .map((nav) => "/" + nav);
       
        
      let l1Nav, l2Nav, l3Nav;
      
      if (links.length > 0) {
        let index = current(state.navigationList).findIndex(
          (nav) => nav.link === links[0]
        );
        
        if (index !== -1) {
          l1Nav = current(state.navigationList).find(
            (nav) => nav.link === links[0]
          );
          state.selectedNavigation.push({
            index,
            link: l1Nav.link,
            label: l1Nav.label,
            absoluteLink: l1Nav.absoluteLink,
            hasPage: l1Nav.children.length === 0 || l1Nav.showPage,
          });
          if (links.length > 1) {
            let index = l1Nav.children.findIndex(
              (nav) => nav.link === links[1]
            );
            
            if (index !== -1) {
              l2Nav = l1Nav.children.find((nav) => nav.link === links[1]);
              state.selectedNavigation.push({
                index,
                link: l2Nav.link,
                label: l2Nav.label,
                absoluteLink: l2Nav.absoluteLink,
                hasPage: l2Nav.children.length === 0 || l2Nav.showPage,
              });

              if (links.length > 2) {
                let index = l2Nav.children.findIndex(
                  (nav) => nav.link === links[2]
                );
                
                if (index !== -1) {
                  l3Nav = l2Nav.children.find((nav) => nav.link === links[2]);
                  state.selectedNavigation.push({
                    index,
                    link: l3Nav.link,
                    label: l3Nav.label,
                    absoluteLink: l3Nav.absoluteLink,
                    hasPage: true,
                  });
                }
                else if(links[2] == action.payload?.matchUrl)
                {
                  //section for employee-details
                  state.selectedNavigation.push({
                    index:2,
                    link: action.payload?.matchUrl,
                    label: action.payload?.label,
                    absoluteLink: action.payload?.matchUrl,
                    hasPage: true,
                  });
                }
              }
            }
            else if(links[1] == action.payload?.matchUrl){
              //from PageBanner.js we are getting the data of matchUrl
              //section for employee-details
              
              state.selectedNavigation.push({
                index:2,
                link: action.payload?.matchUrl,
                label: action.payload?.label,
                absoluteLink: action.payload?.matchUrl,
                hasPage: true,
              });
              
            }
          }
        }
      } else {
        state.selectedNavigation.push({
          index: 0,
          link: state.navigationList[0]?.link,
          label: state.navigationList[0]?.label,
          absoluteLink: state.navigationList[0]?.link,
          hasPage: true,
        });
      }
      
    },
    setBreadcrumbForceEnable(state,action) {
      state.breadcrumbForceEnable = action.payload;
    },
    setSelectedRoutes(state, action) {
      state.selectedRoutes = [];
      let links = action.payload
        .split("/")
        .filter((nav) => nav !== "")
        .map((nav) => {
          return { label: nav, link: "/" + nav };
        });
      if (links.length > 0) {
        let index = current(state.navigationList).findIndex(
          (nav) => nav.link === links[0]
        );
        if (index === -1) {
          state.selectedRoutes.push({
            link: links[0].link,
            label: links[0].label,
            absoluteLink: links[0].link,
          });
          if (links.length > 1) {
            state.selectedRoutes.push({
              link: links[1].link,
              label: links[1].label,
              absoluteLink: links[0].link + links[1].link,
            });
            if (links.length > 2) {
              state.selectedRoutes.push({
                link: links[2].link,
                label: links[2].label,
                absoluteLink: links[0].link + links[1].link + links[2].link,
              });
            }
          }
        }
      }
    },
    setSelectedTertiaryList(state) {
      state.selectedTertiaryList =
        state.navigationList[state.selectedNavigation[0].index].children[
          state.selectedNavigation[1].index
        ].children;
    },
    setSelectedSecondaryList(state) {
      
      state.selectedSecondaryList =
        state.navigationList[state.selectedNavigation[0].index].children;
    },
    getHomeLink(state) {
      return state.navigationList[0]?.link;
    },
  },
});

export const navigationActions = navigationSlice.actions;
export default navigationSlice;
