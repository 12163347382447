import { useQuery } from "@apollo/client";
import DOMPurify from "dompurify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { apiMiddlewareClient } from "../../features/api-wrapper/apiMiddlewareWrapper";
import { GET_OPEN_LOA_REQUEST_STATUS } from "../../features/api-wrapper/apiQueries";
import useMediaQuery from "../../hooks/useMediaQuery";
import APIError from "../../pages/ErrorHandling/APIError";
import { formatPaginationLabel } from "../../pages/News/GivingVolunteering/VolunteerOpportunities";
import LeaveOfAbsenceFMLA from "../../pages/Timeoff/LeaveOfAbsenceFMLA";
import Loader from "../global/Loader";
import styles from "./LOAOpenRequests.module.css";
import { EMPTY_STRING } from "../../Const";

export const MAX_TOTAL_OPEN_REQUESTS = 2;

const LOAOpenRequests = (props) => {
  const isDesktop = useMediaQuery("screen and (min-width: 1280px)");
  const { dictionaryLables, dictionaryWithIconLables, linkDictionaryLables } =
    props.data;
  const {
    loading,
    error,
    data: openRequests,
  } = useQuery(GET_OPEN_LOA_REQUEST_STATUS, {
    client: apiMiddlewareClient,
  });

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  const globalProperties = useSelector(
    (state) => state.globalNotification?.globalProperties
  );

  if (loading) return <Loader />;
  if (error) return <APIError error={error} />;

  return (
    <>
      {openRequests &&
        !openRequests.getOpenLOARequestStatus?.isFMLACardHidden && <LeaveOfAbsenceFMLA data={props} apiData={openRequests?.getOpenLOARequestStatus?.fmlaData}/>}
      <div className={styles.LOAOpenRequestContainer}>

        <div className={styles.headingSection}>
          <div className={styles.Title}>
            {dictionaryLables.heading}
            {!isDesktop && (
              <div>
                {openRequests &&
                  openRequests.getOpenLOARequestStatus.caseStatusList.length >
                  0 && (
                    <div className={styles.showMoreLabel}>
                      {formatPaginationLabel(
                        globalProperties,
                        openRequests.getOpenLOARequestStatus.caseStatusList
                          .length > MAX_TOTAL_OPEN_REQUESTS
                          ? MAX_TOTAL_OPEN_REQUESTS
                          : openRequests.getOpenLOARequestStatus.caseStatusList
                            .length,
                        openRequests.getOpenLOARequestStatus.totalCount
                      )}
                    </div>
                  )}
              </div>
            )}
          </div>
          <div className={styles.openRequestLink}>
            {isDesktop &&
              openRequests &&
              openRequests.getOpenLOARequestStatus.caseStatusList.length >
              0 && (
                <div className={styles.showMoreLabel}>
                  {formatPaginationLabel(
                    globalProperties,
                    openRequests.getOpenLOARequestStatus.caseStatusList.length >
                      MAX_TOTAL_OPEN_REQUESTS
                      ? MAX_TOTAL_OPEN_REQUESTS
                      : openRequests.getOpenLOARequestStatus.caseStatusList
                        .length,
                    openRequests.getOpenLOARequestStatus.totalCount
                  )}
                </div>
              )}
            <Link
              className={styles.viewall}
              to={linkDictionaryLables?.view_all_link?.link.href}
              target="_blank"
            >
              {linkDictionaryLables?.view_all_link?.link.title}
            </Link>
          </div>
        </div>
        {openRequests &&
          openRequests.getOpenLOARequestStatus &&
          openRequests.getOpenLOARequestStatus.caseStatusList.length > 0 ? (
          openRequests.getOpenLOARequestStatus.caseStatusList
            .slice(0, MAX_TOTAL_OPEN_REQUESTS)
            .map((request, index) => (
              <div className={styles.openRequestWrapper} key={index}>
                <div className={styles.openRequest}>
                  <div className={styles.cases}>
                    <div className={styles.casesTitle}>
                      <div className={styles.caseBoxTitle}>
                        {" "}
                        {dictionaryLables.case}{" "}
                      </div>
                      <div className={styles.caseBoxContent}>
                        {request.caseId}
                      </div>
                    </div>
                  </div>
                  <div className={styles.RequestDate}>
                    <div className={styles.casesTitle}>
                      <div className={styles.caseBoxTitle}>
                        {dictionaryLables.request_date}
                      </div>
                      <div className={styles.caseBoxContent}>
                        {formatDateString(request.requestDate)}
                      </div>
                    </div>
                  </div>
                  <div className={styles.Category}>
                    <div className={styles.casesTitle}>
                      <div className={styles.caseBoxTitle}>
                        {dictionaryLables.category}
                      </div>
                      <div className={styles.caseBoxContent}>
                        {request.category}
                      </div>
                    </div>
                  </div>
                  <div className={styles.SubCategory}>
                    <div className={styles.casesTitle}>
                      <div className={styles.caseBoxTitle}>
                        {dictionaryLables.sub_category}
                      </div>
                      <div className={styles.caseBoxContent}>
                        {request.subCategory}
                      </div>
                    </div>
                  </div>

                  <div
                    className={styles.openRequestStatus}
                    style={{
                      backgroundColor:
                        request.status === EMPTY_STRING
                          ? "#F5F5F5"
                          : request.statusColourIndicator
                          ? request.statusColourIndicator === "green"
                            ? "#E6F9EF"
                            : "#FEE6E6"
                            : "#F5F5F5",
                    }}
                  >
                    <div className={styles.iconStyle}>
                      {request.status ? (
                        <img
                          src={
                            request.statusColourIndicator === "green"
                              ? dictionaryWithIconLables?.calendar_clock_icon
                                ?.url
                              : dictionaryWithIconLables
                                ?.exclamation_point_octagon_icon?.url
                          }
                          color="#373AE5"
                          size="small"
                          className="left-arrow"
                        />
                      ) : (
                        <img
                          className="left-arrow"
                          name="exclamation-point-triangle"
                          size="small"
                          src={dictionaryWithIconLables?.no_status_text?.url}
                        />
                      )}
                      {request.status ? (
                        <p
                        className={
                          request.statusColourIndicator == "green"
                            ? styles.textGreenAlert
                            : request.statusColourIndicator == "red"
                            ? styles.textRedAlert
                            : styles.defaultText
                        }
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(request.status, {
                              ADD_ATTR: ["target"],
                            }),
                          }}
                        ></p>
                      ) : (
                        <p className={styles.defaultText}>
                          {dictionaryWithIconLables?.no_status_text?.value}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {isDesktop && (
                  <div className={styles.icon}>
                    <Link to={request.link} target="_blank">
                      <img
                        src={dictionaryWithIconLables.open_request_icon?.url}
                      />
                    </Link>
                  </div>
                )}
                {!isDesktop && (
                  <div className={styles.mobileIcon}>
                    <Link className={styles.viewCase} target="_blank">
                      {dictionaryLables.view_case}
                    </Link>
                  </div>
                )}
              </div>
            ))
        ) : (
          <p>{dictionaryLables.no_open_request_message}</p>
        )}
      </div>
    </>
  );
};

export default LOAOpenRequests;
