import styles from "./PageBanner.module.css";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "./Breadcrumb";
import Bookmark from "./Bookmark";
import TertiaryNavigation from "./TertiaryNavigation";
import SecondaryNavigation from "./SecondaryNavigation";
import useWindowDimensions from "../../hooks/useScreenDetails";
import { navigationActions } from "../../data/navigation-slice";

const PageBanner = () => {
  const dispatch = useDispatch();
  const selectedNavigation = useSelector(
    (state) => state.navigation.selectedNavigation
  );
  const [isShowBookmark, setIsShowBookmark] = useState(true);
  const breadcrumbVisibility = useSelector(
    (state) => state.navigation.breadcrumbVisibility
  );
  const navigationList = useSelector(
    (state) => state.navigation.navigationList
  );
  const currentUrl = useSelector((state) => state.navigation.currentUrl);
  const location = useLocation();
  const [isMobile] = useWindowDimensions();

  const csGlobalData = useSelector((state) => state.globalNotification);

  // temporary solution to hide contact us bookmark
  useEffect(() => {
    if (selectedNavigation) {
      if (selectedNavigation.length >= 3) {
        if (
          selectedNavigation[2].absoluteLink.includes("contact-us") ||
          selectedNavigation[2].absoluteLink.includes("employee-details")
        ) {
          return setIsShowBookmark(false);
        }
      }
      setIsShowBookmark(true);
    }
  }, [selectedNavigation]);

  useEffect(() => {
    let visibility =
      location.pathname === "/" ||
      location.pathname.includes("dashboard") ||
      location.pathname.includes("login")
        ? false
        : true;
    dispatch(navigationActions.setBreadcrumbVisibility(visibility));
    let splitUrlArray = csGlobalData.globalProperties?.manage_my_team_employee_details_pageurl
    ?.url?.split('/');
    let matchUrl = splitUrlArray?splitUrlArray.length>0?'/'+splitUrlArray[splitUrlArray.length-1]:'':'';
    
    const manage_myteam_obj = {
      path: location.pathname,
      label:
        csGlobalData.globalProperties?.manage_my_team_employee_details_pageurl
          ?.label,
          matchUrl: matchUrl
      
    };
  
    dispatch(navigationActions.setSelectedNavigation(manage_myteam_obj));
    dispatch(navigationActions.setSelectedRoutes(location.pathname));
    if (currentUrl !== location.pathname) {
      dispatch(navigationActions.setCurrentUrl(location.pathname));
    }
    dispatch(navigationActions.setBreadcrumbForceEnable(false));
  }, [location, navigationList]);

  if (!breadcrumbVisibility) return;

  return (
    <section className={styles.pageBanner}>
      <div className={styles.topBlock}>
        <Breadcrumb />
        {isShowBookmark && <Bookmark />}
      </div>
      {isMobile && selectedNavigation.length === 2 && <SecondaryNavigation />}
      {isMobile && selectedNavigation.length === 3 && <TertiaryNavigation />}
    </section>
  );
};

export default PageBanner;
