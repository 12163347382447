import style from "./FilterAsMobile.module.css";
import { Checkbox, Button, TextLink } from "@mgmresorts/mgm-ui";
import { useState, useEffect } from "react";

const FilterMobile = (props) => {
  const [btnDisable, setbtnDisable] = useState(true);

  useEffect(() => {
    setbtnDisable(props.btnState);
  }, [props.btnState]);

  const ctrlBtnState = (e) => {
    if (e.target.checked) {
      setbtnDisable(false);
      props.setbtnState(false);
    } else {
      let isatleastonechecked = false;
      props.facetcounts.map((d) => {
        if (d.checked == true) {
          isatleastonechecked = true;
          return;
        }
      });
      if (isatleastonechecked) {
        setbtnDisable(false);
        props.setbtnState(false);
      } else {
        setbtnDisable(true);
        props.setbtnState(true);
      }
    }
  };

  const getVal = (e) => {
    console.log(e.target.name);
    let clickedname = e.target.name;
    //e.target.setAttribute("checked", "checked");
    let returndata;
    let obj = props.facetcounts.map((d) => {
      return d;
    });
    returndata = obj.map((d) => {
      let newObj = {};
      if (d.name === clickedname) {
        //d.checked = !d.checked;
        newObj.checked = !d.checked;
        newObj.name = d.name;
      } else {
        newObj.checked = d.checked;
        newObj.name = d.name;
      }
      return newObj;
    });
    props.setfacetcounts(returndata);
  };

  const updateCount = (filterArr) => {
    if (filterArr && filterArr.length) {
      return filterArr.reduce((t, d) => {
        return d.checked ? t + 1 : t;
      }, 0);
    } else {
      return null;
    }
  };

  const clearFilter = () => {
    props.onClose();
    let returndata;
    returndata = props.facetcounts.map((d) => {
      let newObj = {};
      newObj.name = d.name;
      newObj.checked = false;
      return newObj;
    });
    props.setfacetcounts(returndata);
    props.clearfilter();
    setbtnDisable(true);
    props.setfacetcountForMob(null);
  };

  const applyFilter = () => {
    props.onClose();
    props.filterResult(props.facetcounts);
    props.setfacetcountForMob(updateCount(props.facetcounts));
  };

  return (
    <div className={style.filterContainer}>
      <div>
        <div className={style.filterHeader}>
          <div className={style.filterTitle}>
            {props.csData
              ? props.csData.filter(
                  (d) => d.name?.toLowerCase() == "filter results"
                )[0]?.value
              : "Filter results"}
          </div>
          <div className={style.closeBtn} onClick={props.onClose} />
        </div>
        <div className={style.filterSubTitle}>
          {props.csData
            ? props.csData.filter((d) => d.name?.toLowerCase() == "category")[0]
                .value
            : "Category"}
        </div>
        <div className={style.mholder}>
          <div className={style.inputH}>
            {props.facetcounts &&
              props.facetcounts.map((c, index) => (
                <div
                  key={index}
                  className={`${style.checkHolder} ${
                    c.checked ? style.checkedHolder : null
                  }`}
                >
                  <Checkbox
                    testId=""
                    key={index}
                    id={index}
                    variant="small"
                    onClick={getVal}
                    name={c.name}
                    onChange={ctrlBtnState}
                    checked={c.checked}
                    className="filterCheckbox"
                  />
                  <label htmlFor={index} className={`${style.cLable}`}>
                    {c.name}
                  </label>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className={style.filterBottom}>
        <hr />
        <div className={style.filterControlH}>
          <div className={style.filterClear} onClick={clearFilter}>
            <TextLink variant="small">
              {props.csData
                ? props.csData.filter(
                    (d) => d.name?.toLowerCase() == "clear filters"
                  )[0].value
                : "Clear filters"}
            </TextLink>
          </div>
          <div className={style.filterApply}>
            <Button
              onClick={applyFilter}
              label={
                props.csData
                  ? props.csData.filter(
                      (d) => d.name?.toLowerCase() == "apply filters"
                    )[0].value
                  : "Apply filters"
              }
              size="small"
              variant="primary"
              disabled={btnDisable}
              className="filterApplyBtn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMobile;
