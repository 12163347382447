import style from "./stock.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../global/Loader";
import { useSelector } from "react-redux";
import { getLocalStoreValue } from "../../../utils/localStore";
import InnerHtml from "../../dynamic/InnerHtml";

const Stock = () => {
  let token = getLocalStoreValue("token");
  let apigee_token = getLocalStoreValue("apigee_token");
  const stockState = useSelector((state) => state.dom.stockCompState);
  const csGlobalData = useSelector((state) => state.globalNotification);
  const systemErrors = csGlobalData ? csGlobalData.systemErrors : [];
  const stockIconUrl = csGlobalData?.property_icon
    ? csGlobalData?.property_icon[0]?.icon?.url
    : "";
  const language = useSelector(
    (state) => state.gqlData?.profile?.preferredLanguage
  );

  const headers = {
    Authorization: `Bearer ${apigee_token}`,
    jwt: token,
    //Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
  };

  const stockApiEndPoint = process.env.REACT_APP_BASE_URL;
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [stockData, setStockData] = useState();

  useEffect(() => {
    if (language) {
      setLoading(true);
      axios
        .get(`${stockApiEndPoint}api/stock-price`, {
          headers: headers,
        })
        .then((response) => {
          setStockData(response?.data);
          setLoading(false);
        })
        .catch((error) => {
          let filterData = systemErrors.filter((obj) => {
            if (obj.type == error?.response?.data?.service_key) {
              return obj;
            }
          });
          let { errorHeading, errorDescription, errorImage } = "";
          if (filterData && filterData.length > 0) {
            errorHeading = filterData[0].heading;
            errorDescription = filterData[0].description;
            errorImage = filterData[0].image?.url;
          } else if (systemErrors && systemErrors[0]) {
            errorHeading = systemErrors[0].heading;
            errorDescription = systemErrors[0].description;
            errorImage = systemErrors[0].image?.url;
          }
          setError({
            title: error.response?.data ? error.response?.data : error.message,
            message: error.message ? error.message : error.response?.data,
            status: error.response?.status ? error.response?.status : null,
            service_key: error.response?.service_key
              ? error.response?.service_key
              : "",
            heading: errorHeading,
            description: errorDescription,
            imgUrl: errorImage,
          });

          setLoading(false);
        });
    }
  }, [systemErrors]);

  const formatdateAMPM = (dateStr) => {
    var date = new Date(dateStr);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();

    return (
      [
        (mm > 9 ? "" : "0") + mm + "/",
        (dd > 9 ? "" : "0") + dd + "/",
        date.getFullYear(),
      ].join("") +
      " " +
      strTime
    );
  };

  return (
    <>
      <div className={`${stockState ? style.show : style.hide}`}>
        {isLoading ? <Loader /> : null}
        {error ? (
          <div className={style.stockErrorContainer}>
            <img src={error.imgUrl} alt="stock image" />
            <h6>{error.heading}</h6>
            <InnerHtml description={error.description} />
          </div>
        ) : stockData ? (
          <>
            <div className={`${style.stockDesktop}`}>
              <div className={style.topDataHolder}>
                <div className={style.iconHolder}>
                  <img width="20px" height="20px" src={stockIconUrl} />
                </div>
                <div className={`${style.stockName} ${style.dStockName}`}>
                  {stockData?.Exchange} : {stockData?.Symbol}
                </div>
              </div>
              <div className={`${style.dateTimeHolder}`}>
                <span className={style.price}>
                  $
                  {stockData.TradePrice
                    ? Number(stockData.TradePrice).toFixed(2)
                    : ""}
                </span>
                <span
                  className={`${
                    stockData.PercChange == 0
                      ? style.zero
                      : stockData.PercChange < 0
                      ? style.indicatorred
                      : style.indicator
                  }`}
                ></span>
                <span className={style.percent}>
                  ({" "}
                  {stockData.PercChange == 0
                    ? stockData.PercChange
                    : stockData.PercChange
                    ? Number(stockData.PercChange).toFixed(2)
                    : ""}{" "}
                  )
                </span>
                <span className={style.dateTime}>
                  {stockData.TradeDate
                    ? formatdateAMPM(stockData.TradeDate)
                    : ""}
                </span>
              </div>
            </div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    </>
  );
};

export default Stock;
