import { CenterModal, FullScreenModal } from "@mgmresorts/mgm-ui";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import useWindowDimensions from "../../hooks/useScreenDetails";
import ConfirmationPopup from "../global/ConfirmPrompt";
import style from "./ViewLinkedInModal.module.css";
import DOMPurify from 'dompurify';

const Backdrop = (props) => {
    return <div className={style.backdrop} onClick={props.onClose} />;
};

export const ViewLinkedInModalDialogue = (props) => {
    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
    const [isContinue, setIsContinue] = useState(false);
    function windowOpen(url, name) {
        if (!url.match(/^https?:\/\//i)) {
            url = "http://" + url;
        }
        return window.open(url, name);
    }
    const { apiData, csData, onClose, fallBackImage } = props;
    const [isMobile] = useWindowDimensions();
    const modalTitle = csData?.links_dictionary?.filter((item) => item.name == "read_more") ? csData.links_dictionary.filter((item) => item.name == "read_more")[0]?.
        name_value?.filter((item) => item.name == "modal_title")[0]?.value ? csData.links_dictionary.filter((item) => item.name == "read_more")[0].
            name_value.filter((item) => item.name == "modal_title")[0].value : csData.links_dictionary.filter((item) => item.name == "read_more")[0].
                name_value.filter((item) => item.name == "modal_title")[0].html_value : ""
    const modalRedirect = csData?.links_dictionary?.filter((item) => item.name == "read_more") ? csData.links_dictionary.filter((item) => item.name == "read_more")[0]?.
        name_value?.filter((item) => item.name == "view_post_link_title")[0]?.value ? csData.links_dictionary.filter((item) => item.name == "read_more")[0].
            name_value.filter((item) => item.name == "view_post_link_title")[0].value : csData.links_dictionary.filter((item) => item.name == "read_more")[0].
                name_value.filter((item) => item.name == "view_post_link_title")[0].html_value : "";
    // const extractedUrl = apiData ? apiData[1] ? apiData[1].replace(/^url\(["']?/, '').replace(/["']?\)$/, '') : fallBackImage : "";
    const ceoHandler = csData?.links_dictionary?.filter((item) => item.name == "read_more") ? csData.links_dictionary.filter((item) => item.name == "read_more")[0]?.
        name_value?.filter((item) => item.name == "ceo_linkedin_id")[0]?.value ? csData.links_dictionary.filter((item) => item.name == "read_more")[0].
            name_value.filter((item) => item.name == "ceo_linkedin_id")[0].value : csData.links_dictionary.filter((item) => item.name == "read_more")[0].
                name_value.filter((item) => item.name == "ceo_linkedin_id")[0].html_value : "";
    useEffect(() => {
        if (isContinue) {
            setIsConfirmPopupOpen(false);
            setIsContinue(false);
            windowOpen(apiData ? apiData[2] : "", "_blank");
        }
    }, [isContinue]);
    return (
        <>
            {!isMobile && <CenterModal open={true} className={style.centerModal} title={modalTitle}
                size="large" onClose={onClose}>
                <div className={style.modalContent}>
                    <div className={style.modalDivParent}>
                        <div className={style.modalDivChild}>
                            <div>
                                <p className={style.modalSubtitle}>{csData?.dictionaryLables?.ceo_name}</p>
                                <p className={style.modalHandler}>{ceoHandler}</p></div>
                            <div className={style.modalrightRail}><p>{apiData ? apiData[3] ? apiData[3] : "" : ""}</p>
                                <img className={style.linkedInIcon} src={csData?.dictionary_with_icon?.filter((item) => item.name ==
                                    "LinkedIn_icon_feed") ? csData.dictionary_with_icon.filter((item) => item.name ==
                                        "LinkedIn_icon_feed")[0]?.url : ""}></img>
                            </div>
                        </div>
                        <a className={style.modalRedirect} onClick={() => setIsConfirmPopupOpen(true)} >{modalRedirect}</a>
                    </div>
                    <div className={style.modalBody}>
                        <div className={style.imgLinkedIn}>
                            <div className={style.imageContainer}>
                                <img src={fallBackImage} alt="Fallback" />
                            </div>                           
                            <div className={style.textContent}>
                                <p dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(apiData ? apiData[0] ? apiData[0] : "" : "", { ADD_ATTR: ['target'] })
                                }} ></p>
                            </div>
                        </div>
                    </div>
                </div>

            </CenterModal>}
            {isMobile && <FullScreenModal open={true} title={modalTitle}
                size="medium" onClose={onClose} zIndex={150} className={style.modalBg} >
                <div className={style.modalContent} >
                    <div className={style.modalDivParent}>
                        <div className={style.modalDivChild}>
                            <div>
                                <p className={style.modalSubtitle}>{csData?.dictionaryLables?.ceo_name}</p>
                                <p className={style.modalHandler}>{ceoHandler}</p>
                                <div className={style.modalrightRail}>
                                    <p>{apiData ? apiData[3] ? apiData[3] : "" : ""}</p>
                                    <img className={style.linkedInIcon} src={csData?.dictionary_with_icon?.filter((item) => item.name ==
                                        "LinkedIn_icon_feed") ? csData.dictionary_with_icon.filter((item) => item.name ==
                                            "LinkedIn_icon_feed")[0]?.url : ""}></img>
                                </div>
                            </div>
                        </div>
                        <a className={style.modalRedirect} onClick={() => setIsConfirmPopupOpen(true)} >{modalRedirect}</a>
                    </div>
                    <div className={style.modalBody}>
                        <div className={style.imgLinkedIn}>
                            <img src={fallBackImage}></img>
                            <p dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(apiData ? apiData[0] ? apiData[0] : "" : "", { ADD_ATTR: ['target'] })
                            }} ></p>
                        </div>
                    </div>
                </div>
            </FullScreenModal>}
            {isConfirmPopupOpen && (
                <ConfirmationPopup
                    isConfirmPopupOpen={isConfirmPopupOpen}
                    onContinue={setIsContinue}
                    onClose={() => {
                        setIsConfirmPopupOpen(false);

                    }}
                />
            )}
        </>

    );

}
const portalElement = document.getElementById("portal");
const ViewLinkedInModal = (props) => {
    return (
        <>
            {createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
            {createPortal(
                <ViewLinkedInModalDialogue
                    onClose={props?.onClose}
                    csData={props?.csData}
                    apiData={props?.apiData}
                    fallBackImage={props?.fallBackImage}
                >
                    {props.children}
                </ViewLinkedInModalDialogue>,
                portalElement
            )}
        </>
    );
};

export default ViewLinkedInModal;